import "./portfolio.css";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FormControlLabel,
  FormLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Radio,
  RadioGroup,
  Popover,
  
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon

import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import { useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { ButtonGroup } from "@mui/material";
import clipboardCopy from 'clipboard-copy';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuList from "../../includes/menulist";
import MenuListHome from "../../includes/menuListHome";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import { getFetchdropdown } from "../../apihelper/cryptocurrencylist";
import { getPortfolioData } from "../../apihelper/portfolio";
import { addToPortfolio } from "../../apihelper/portfolio";
import { useNavigate } from "react-router-dom";
import Portfolioadd from "../addPage/portfolioadd";
import SidebarMenuList from "../../includes/sidebarMenuList";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useParams } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Footer from './footer'; // Import your Footer component
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

function Portfolio(props) {
  
  const [user_role, setuser_role] = useState("");
  const { portfolioId,portfolioName } = useParams();
  const portfolioTitle = portfolioName.replace(/-/g, ' ');
  const [selectedFilter, setSelectedFilter] = useState(null);

  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const [snackbaropen, setSnackbaropen] = React.useState(false);

  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("-");
  const [portfoliovalue, setPortfoliovalue] = useState("-");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [portfolioid, setportfolioid] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [PortfolioRoivalue,setPortfolioRoivalue]=useState('-');
  const [currentwallet,setcurrentwallet]=useState('-');
  const [nodatafoundmsgtype,setnodatafoundmsgtype]=useState('');
  const location = useLocation(); 
  const loc = location.pathname;
  const handleSymbolLink=(symbolName)=>{
    //console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }

  const userId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.user_id;
  const [isEditable, setIsEditable] = useState(false);
  const [thesisContent, setThesisContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [retrievalLoading, setRetrievalLoading] = useState(true); // State for loading during retrieval
  const [roidays, setRoiDays] = useState("-");

  // Function to fetch the portfolio description using `fetch`
  const fetchDescription = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getThesisDescription', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ portfolioTitle }),
      });
      const result = await response.json();
      console.log(result);
      if (result.code === '200') {
        const formattedDescription = result.description.replace(/\\n/g, "\n"); 
      setThesisContent(formattedDescription); // Assuming the API returns the description field
      }
    } catch (error) {
      console.error('Error fetching description:', error);

    } finally {
      setRetrievalLoading(false);
    }
  };

  // Function to handle edit/save button click
  const handleEditClick = async () => {
    const formattedContent = thesisContent.replace(/\n/g, "\\n"); 
    if (isEditable) {
      setLoading(true);
      try {
        const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/updateThesisDescription', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            portfolioTitle,
            description: formattedContent,
          }),
        });
        const result = await response.json();
        console.log(result);
        if (result.code === '200') {
          alert('Thesis updated successfully');
        } else {
          
        }
      } catch (error) {
        console.error('Error saving Thesis:', error);
        alert('Failed to update Thesis');
      } finally {
        setLoading(false);
        setIsEditable(false);
      }
    } else {
      setIsEditable(true);
    }
  };

  // Function to handle content change
  const handleContentChange = (e) => {
    setThesisContent(e);
  };

  // useEffect to retrieve description when component mounts
  useEffect(() => {
    fetchDescription();
  }, [portfolioTitle]);
  

  const handleOpen = (idx) => {
    // setopenindex(idx);const today = new Date();
    fetchDropdownOptions()
    setSlugpopupdata({
      slugname: sortedData[idx].slug,
      slug: sortedData[idx].name,
      units: sortedData[idx].total_unit,
      pricetoday: sortedData[idx].crypto_price,
      logo: sortedData[idx].logo,
      current_Wlt : sortedData[idx].current_wallet,
    });
    //console.log("Slug pop up Data: ",slugdata);
    setOpen(true);
  };
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );
  const mediaQueryVar2 = useMediaQuery(json2mq({ maxWidth: 410 }));
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/crypto-screener", { replace: true });
  };
  const formatToNonZero = (number) => {
    number = parseFloat(number);
    
    if (number >= 1) {
      return number.toFixed(2);
    } else if (number >= 0.1) {
      return number.toFixed(1);
    } else if (number >= 0.01) {
      return number.toFixed(4);
    } else if (number >= 0.001) {
      return number.toFixed(5);
    } else if (number >= 0.0001) {
      return number.toFixed(6);
    } else if (number >= 0.00001) {
      return number.toFixed(7);
    } else if (number >= 0.000001) {
      return number.toFixed(8);
    } else if (number >= 0.0000001) {
      return number.toFixed(9);
    } else if (number >= 0.00000001) {
      return number.toFixed(10);
    } else if (number >= 0.000000001) {
      return number.toFixed(11);
    }
    return number.toFixed(1);
  };

  useEffect(() => {
    
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getPortfolioData({
        slug: filter,
        user_id: userData.user_id,
        portfolio_type_id: portfolioId,
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          
          setLoader(true);
          setSlugdata(res.data);
          setRoivalue(res.total_profit);
        setRoiDays(res.roi_days);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setcurrentwallet(res.current_wallet);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setSlugdata(res.data);
      
        setuser_role(res.user_role);
        setLastUpdatedTiem(res.updated_on);
        setportfolioid(res.portfolio_id)
          setData(res.data);
          console.log("Set result Data",res.data, " and its length is ",res.data.length);
          setuser_role(res.user_role);
         
        } else {
          setMsgAfterLoading(res.message);
          //console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    
  }, [filter]);

 
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));


    getFetchdropdown({
      user_id: userData.user_id,
      
      Token:userData.token
    }).then((res) => {
      if (res.code === "200") {
       
          setuser_role(res.user_role);
       } else {
        console.log("Data fething error");
      }
    
    });
   
    
  }
  const styles = {
    container: {
      width: '100%', // Full width
      backgroundColor: '#000',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      padding: '10px 0',
      flexShrink: 0, // Prevent footer from shrinking,
      
    },
    footerBand: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
    },
    linksContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft:mediaQueryVar ?"20%":""
    },
    link: {
      textDecoration: 'none',
      color: '#fff',
      margin: '0 10px',
      borderRadius: '50px',
      fontSize: mediaQueryVar ? '16px' : mediaQueryVar2 ? '11px' : '14px',
      fontWeight: '700',
      padding: '5px 15px',
      transition: 'background-color 0.3s, transform 0.3s',
    },
    separator: {
      color: '#fff',
    },
  };
  const [rowsToShow, setRowsToShow] = useState(10);
  
  const loadMore = () => {
    console.log("BEfore ROws to SHow: ",rowsToShow," for sorteddata : ", sortedData.length);
    
    setRowsToShow(rowsToShow + 10);
    
  };

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleClick = (buySell) => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    addToPortfolio({
      portfolio_type_id: portfolioId,
      existing_portfolio: 'yes',
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        setLoader(false);
        getPortfolioData({
          slug: filter,
          portfolio_type_id: portfolioId,
          user_id: userData.user_id,
          start_index: `${index}`,
          limit: `${10}`,
          Token: userData.token,
        }).then((res) => {
          if (res.code === "200") {
            //console.log("data got successfully");
            setSlugdata(res.data);
            setuser_role(res.user_role);
            // console.log(res.data)
            if (res.data.length === 0) {
              setInitialPage(true);
            }
            if (res.data.length < 10) {
              setLoaderButton(false);
            } else {
              setLoaderButton(true);
            }
            setLoader(true);
            setAddMoreLoader(false);
          } else {
            //console.log("Data fething error");
          }
          // console.log(slugdata)
        });
        setUnit("");
        setbuySell("");
        setPrice("");
        handleDateChange("");
        //navigate("/portfolio");
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden', backgroundColor:'white'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));
  const formattedName = portfolioName.replace(/ /g, '-');
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleCopyText = async() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const textToCopy = `${process.env.REACT_APP_HOME_URL}portfolio/${portfolioName}`;
    await clipboardCopy(textToCopy)
   
      .then(() => {
       // console.log("Text copied:", textToCopy);

        // Set the tooltip to open for a short duration (e.g., 2 seconds)
        setTooltipOpen(true);
        setTimeout(() => {
          setTooltipOpen(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Error copying text:", err);
      });
  };

  const sortedSlugdata = slugdata.sort((a, b) => {
    const aValue = parseFloat(a.total_roi_value.replace('%', ''));
    const bValue = parseFloat(b.total_roi_value.replace('%', ''));
    return bValue - aValue;
  });

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const [sortOrder, setSortOrder] = useState({ // Track ascending/descending for each column
    roi : 'desc',
  });
  const [sortType, setSortType] = useState('roi_desc');

  const handleSort = (column) => {
    console.log("Arrow Clicked");
    const newSortOrder = sortOrder[column] === 'asc' ? 'desc' : 'asc'; // Toggle sort order
    setSortOrder({ ...sortOrder, [column]: newSortOrder });

    console.log("Sorted");
  
    // Set the sort type based on the column
    switch (column) {
      case 'roi':
        setSortType(newSortOrder === 'asc' ? 'roi_asc' : 'roi_desc');
        break;
      default:
        break;
    }
  };

  console.log("Data : ",data);
  const filteredData = data.filter(portfolio =>
    portfolio.total_roi_value
);

// Helper function to convert ROI value to a number
const convertRoiValue = (roi) => {
  const number = parseFloat(roi.replace('%', ''));
  return isNaN(number) ? 0 : number; // Handle invalid or empty values
};



const sortedData = [...filteredData].sort((a, b) => {
  switch (sortType) {
    case 'roi_asc':
      return convertRoiValue(a.total_roi_value) - convertRoiValue(b.total_roi_value);
    case 'roi_desc':
      return convertRoiValue(b.total_roi_value) - convertRoiValue(a.total_roi_value);
    
    default:
      return 0;
  }
});

console.log("Sorted Data: ", sortedData);



const [anchorEl, setAnchorEl] = useState(null);
const openfilter = Boolean(anchorEl);

const handlePopoverOpen = (event) => {
  setAnchorEl(event.currentTarget);
};
const handleCloseeoption = () => {
  setAnchorEl(null);
};

const handleClickROIHL = () => {
  setSortType('roi_desc');
};
const handleClickROILH = () => {
  setSortType('roi_asc');
};


const handleClearAll = () => {
  setSortType('roi_asc');
};


const modules = {
  toolbar: [
    [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'align': [] }],
    ['link', 'image'],                                // add links and images
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    ['clean']                                         // remove formatting button
  ]
};

const formats = [
  'header', 'font', 'list', 'bullet', 'bold', 'italic', 'underline', 'strike',
  'blockquote', 'code-block', 'align', 'link', 'image', 'color', 'background'
];

  return (
<>

{mediaQueryVar === true? (
  
    <Box
    sx={{
      flexDirection: mediaQueryVar === false ? "column" : "",
       display: 'flex',  height: '100vh' 
      
    }}
    className="maindiv"
  >
    
    <Helmet>
        <title>{portfolioTitle}</title>
    </Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="white"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path  +'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
       
            <div className="windowheader">
              <Toolbar />
            </div>
            <Box sx={{ flexGrow: 1, marginBottom: "15px" }} >
            <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"30px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
              <Grid
                container
                spacing={2}
                style={{ display: "flex", justifyContent: "space-between",  }}
              >
                <Grid item className="CryptocurrencyListHeader" >
                  <strong>
                    {" "}
                    <Typography
                      variant="h5"
                      noWrap
                      component="div"
                      style={{
                        fontFamily: "Satoshi, sans-serif",
                        fontSize: "25px",
                        fontStyle: "normal",
                        
                       //  marginTop:"10px"
                         marginTop: mediaQueryVar === false ? "10px" : "",
                      }}
                    >
                  
                   <span style={{
                    fontSize:"24px",fontFamily:"Satoshi,sans-serif",fontWeight:"700",marginLeft:"20px"
                    }}>  {formattedName.replace(/-/g, ' ')}</span>
                    <Button
      variant="outlined"
      style={{
        backgroundColor:"white",
        color: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "27px",
        textTransform: "none",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "700",
        padding: "5px 20px",
        width:"160px",
        marginLeft:"20px"
      }}
      onClick={() => navigate("/crypto-screener")}
      onMouseDown={(e) => {
        // Change button styles on click
        e.currentTarget.style.backgroundColor = "#1877F2";
        e.currentTarget.style.color = "white";
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.color = "#1877F2";
      }}
    >
      Add More
    </Button>
                    </Typography>
              
                  </strong>
        <Box sx={{marginTop:'0px',marginLeft:"20px"}}>
                  
                 
     {portfolioid && (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center', // Aligns the tooltip with the text
        }}
      >
        <span
          variant="h6"
          noWrap
          style={{
            marginLeft: '0px',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontStyle: 'normal',
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer',
          }}
          onClick={handleCopyText}
        >
        Share your Portfolio         </span>
        <Tooltip title="Copied" open={isTooltipOpen} onClose={() => setTooltipOpen(false)}>
          {/* Empty element for the tooltip */}
       
        </Tooltip>
      </span>
    )}
    
    </Box>

                </Grid>
            </Grid>
            </Box>  


            <div class="container" style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "15px",
              marginBottom: "40px",
              marginLeft:"8px",
             // width:"1160px",
              marginTop:"-10px",
              marginRight:"10px",
              width:"98%"
              
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height:"103px",
      minHeight:"100.748px",
      //width:"255px",
      
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{roivalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Total Profit</div>
      </div>

      <img style={{

        marginTop:"40px"
      }}
      
      src={`${window.constants.asset_path}/images/totalprofit.svg`}  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
     
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{portfoliovalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px",
          marginRight:"5px"
        }}>Active Portfolio Value</div>
      </div>
      <img style={{

marginTop:"50px"
}}src={`${window.constants.asset_path}/images/tpv.svg`}  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      
      justifyContent: "space-between",
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{PortfolioRoivalue}
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>ROI ({roidays} Days)</div>
      </div>
      <img style={{marginTop:"50px"}}src={`${window.constants.asset_path}/images/roi.svg`}  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      flex: "1 1 calc(20% - 20px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: " 0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>{currentwallet}</div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Wallet</div>
      </div>
      <img style={{marginTop:"50px"}}src={`${window.constants.asset_path}/images/wallet.svg`}  alt="wallet" class="icon"/>
    </div>
</div>  



            
  {initialPage &&   <Box
        component="main"
        sx={{
         
          flexGrow: 1,
          p: 3,
          
        }}
      >

        <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu">
            <ArrowBackIcon /> Go Back
          </Button>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
      
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "white",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #1877F2",
                  color: "#1877F2", fontSize: "12px"
                }}
                onClick={handleCreateNew}
              >
            Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}



        {!initialPage && (
          <>
           <div class="searchtabcontainer" style={{
               paddingTop: "30px",
               paddingBottom: "10px",
               paddingLeft: "15px",
                paddingRight: "0px",
                 background: "white",
                  borderRadius: "24px",
                  marginLeft:"22px",
                  marginRight:"22px"
 
              }}>

<div
  style={{
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    margin: "0 8px 0 8px",
    fontSize: "14px",
  }}
>
  {mediaQueryVar === true && (
     <div
     item
     className="cryptocurrencyListHeaderSearch"
     style={{
       padding: "2px",
       background: "#F7F7F7",
       borderRadius: "46px",
       marginBottom: "10px",
     }}
   >
     <TextField
       style={{
         width: "100%",
         borderRadius: "50px",
         marginTop: mediaQueryVar === true ? "" : "60px",
         fontFamily: "Satoshi, sans-serif",
         borderRadius:"46px",
         border:" 1px #ECECEC solid",
         backgroundColor:"#F7F7F7",
         paddingTop:"2px",
         paddingBottom:"2px",
         paddingLeft:"12px",
         paddingRight:"30px"
       }}
       className="cryptocurrencyListHeaderSearch"
       size="small"
       placeholder="Search Crypto"
       onInput={(e) => setFilter(e.target.value)}
       value={filter}
       InputProps={{
         startAdornment: (
           <InputAdornment position="start">
             <img src={window.constants.asset_path  + '/images/search.svg'}
alt=""  />
           </InputAdornment>
         ),
         style: {
           border: "none", // Remove the border from the input
         },
       }}
       sx={{
         '& .MuiOutlinedInput-root': {
           '& fieldset': {
             borderColor: 'transparent', // Remove the border of the fieldset
           },
           '&:hover fieldset': {
             borderColor: 'transparent', // Remove the border on hover
           },
           '&.Mui-focused fieldset': {
             borderColor: 'transparent', // Remove the border when focused
           },
         },
       }}
     />
   </div>
  )}

  <div style={{ display: "flex", gap: "10px",
    marginRight:"25px"
  }}>
    <Button
      variant="outlined"
      style={{
        backgroundColor:"white",
        color: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "27px",
        textTransform: "none",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "700",
        padding: "0px 20px",
        width:"auto",
        
      }}
      onClick={() => navigate("recommendations")}
      onMouseDown={(e) => {
        // Change button styles on click
        e.currentTarget.style.backgroundColor = "#1877F2";
        e.currentTarget.style.color = "white";
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.color = "#1877F2";
      }}
    >
          <img 
                alt="" 
                src={window.constants.asset_path + '/images/brain_blue_image.png'} 
                width={40} 
                height={40} 
                style={{ 
                  borderRadius: "19px", 
                  width: "36px", 
                  height: "36px", 
                  marginRight: "10px",
                }} 
                />
      Recommendations
    </Button>
    <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      maxHeight: "40px",
      whiteSpace: "nowrap",
      marginRight:"0px"
    }}
    onClick={() => navigate("detailed-portfolio-analysis")}
    onMouseDown={(e) => {
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    <img 
      alt="" 
      src={window.constants.asset_path + '/images/analysis.png'} 
      
      style={{ 
        borderRadius: "19px", 
        width: "34px", 
        height: "34px", 
        marginRight: "10px",
      }} 
    />
    Analysis
  </Button>

    <Button
      variant="outlined"
      style={{
        backgroundColor: "white",
        color: "#1877F2",
        borderColor: "#1877F2",
        borderWidth: "2px",
        borderStyle: "solid",
        borderRadius: "27px",
        textTransform: "none",
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "700",
        padding: "5px 20px",
        width:"160px"
      }}
      onClick={() => navigate("order-book")}
      onMouseDown={(e) => {
        // Change button styles on click
        e.currentTarget.style.backgroundColor = "#1877F2";
        e.currentTarget.style.color = "white";
      }}
      onMouseUp={(e) => {
        // Revert button styles when click is released
        e.currentTarget.style.backgroundColor = "white";
        e.currentTarget.style.color = "#1877F2";
      }}
    >
      Order Book
    </Button>
    

  </div>
</div>


  <div style={{width: "96%", height: "100%",border: "1px #EFEFEF solid",marginTop:"10px",marginBottom:"0px",marginLeft:"10px"}}></div>

<div style={{
               width:'100%',
               border: "1px solid white",  
               borderRadius: "8px", 
               padding: "25px 25px", 
               
               marginRight: "10px",
               marginLeft:"-10px", 
               boxShadow: "0 2px 4px rgba(0,0,0,0)", 
               backgroundColor: "white" ,
               marginTop:"0px"
 
            }}>

            
<TableContainer style={{ marginRight:"-30px",overflowX: "hidden", border: "1px solid #DADEDF", borderRadius: "5px", padding:0 }}>
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                    <TableRow>

                    <StyledTableCell className="cryptotableviewHeader" style={{ borderRadius: "8px 0px 0px 8px", border: 0, padding: 0,  width:"22%"}}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Symbol
  </button>
</StyledTableCell>


<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"22%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px", width:"100%",height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Current value
  </button>
</StyledTableCell>

  
<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0,  width:"16%" }}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px",width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Units
  </button>
</StyledTableCell>


<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"17%"}}>
  <button disabled style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px",width:"100%", height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    Purchase value
  </button>
</StyledTableCell>




<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"17%" }}>
  <button   onClick={() => handleSort('roi')} style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "16px" ,width:"100%",height: "45px", border: "none", background: "white", padding: "12px 12px", margin: "0px 0px 10px 0px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
   ROI {sortOrder.roi === 'asc' ? '↑' : '↓'}
  </button>
</StyledTableCell>


<StyledTableCell className="cryptotableviewHeader">

                      </StyledTableCell>

                    </TableRow>
                  </TableHead>
                  {loader === false && (
                    <TableBody >
                      {rows.map((idx) => (
                        <StyledTableRow key={idx} >
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                              sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell >
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack>
                              <Skeleton
                                 sx={{marginTop:'10px'}}
                                variant="rounded"
                                // width={mediaQueryVar === false ? "100%" : 200}
                                height={40}
                              />
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                  {loader && (
                    <TableBody>
                      {sortedData.slice(0, rowsToShow).map((obj, idx) => (
                      <StyledTableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? "white" : "#EEE" }}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              borderBottom: "none",
                              color: "#000",
                              fontFamily: "",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "24px",
                              paddingLeft: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRight: "1px solid #DADEDF",
                              
                              height:"50px",
                              minHeight:"50px"
                            }}
                          
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}
                            >
                              <div>
                              <img width={38.77} height={37} style={{borderRadius: "19px", border:"2px solid black"}} alt="" src={obj.logo} />
                            </div>
                              <div className="btcParent">
                              {obj["article_link"] && (
                                <a href={obj.article_link}>
                                  <div>{obj.slug}</div>
                                </a>
                              )}
                              {!obj["article_link"] && 
     <div className="article-link" 
     style={{
      display: "flex",
      justifyContent: "space-between",
      gap: "10px", // Gap between the items
      alignItems: "center"// Centers items vertically (optional)
     }}>
      <div>
    <a href={`../../crypto/${obj.crypto_symbol}`} style={{ color: "black" }}>
      {obj.slug}
    </a></div>
</div>
}

 
     </div>
     <div>
  {obj.category && ( // Check if obj.category is not null or empty
    <div style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
          borderRadius: "15px",
          padding: "2px 11px",
          fontSize: "12px",
          fontWeight: "500",
          color: "#000",
          marginBottom: "0px",
        }}
      >
        {obj.category}
      </Box>
    </div>
  )}
</div>
                          </div>
                          </StyledTableCell>
                          <StyledTableCell
                            className="one"
                            style={{
                              borderBottom: "none",
                              color: "#000",
                              fontFamily: "",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "24px",
                              paddingLeft: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRight: "1px solid #DADEDF",
                            }}
                          >
                            {/* <div className="table_mobile">PRICE</div> */}

                            {obj.price_today}
                          </StyledTableCell>
                          <StyledTableCell
                            className="one"
                            style={{
                              borderBottom: "none",
                              color: "#000",
                              fontFamily: "",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "24px",
                              paddingLeft: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRight: "1px solid #DADEDF",
                              
                            }}
  
                          >
                            {/* <div className="table_mobile">NUM OF UNITS</div> */}
                            {obj.total_unit}
                          </StyledTableCell>
                          <StyledTableCell
                            className="one"
                            style={{
                              borderBottom: "none",
                              color: "#000",
                              fontFamily: "",
                              fontSize: "16px",
                              fontStyle: "normal",
                              fontWeight: "500",
                              lineHeight: "24px",
                              paddingLeft: "16px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderRight: "1px solid #DADEDF",
                             
                            }}
  
                          >
                            {/* <div className="table_mobile">Total Value</div> */}
                            {obj.blended_cost}
                          </StyledTableCell>

                          <StyledTableCell
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            
                          }}
                        >
                         {obj.total_roi_value}

                        </StyledTableCell>


                          <StyledTableCell
                           style={{
                            borderBottom: "none",
                            fontSize: mediaQueryVar === false ? "11px" : "",
                            borderRadius: "8px 0px 0px 8px",
                            float:"left"
                          }}
                          >
                            <Button
      className="btn"
      onClick={() => handleOpen(idx)}
     
    >
      <img
        src={window.constants.asset_path + '/images/edit.svg'}
        alt="Edit Icon"
        style={{ width: "12px", height: "12px", marginRight:"5px" }}
      /> <span style={{color: "#1877F2", fontSize: "14px", fontFamily: "Satoshi,sans-serif", fontWeight: "500", wordWrap: "break-word",textTransform:"none"}}>Edit</span>
      <span style={{width:"45px", 
        marginTop:"20px", marginLeft:"-45px",
        border:"1px solid #1877F2"}}></span>
    </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </TableContainer>
              {rowsToShow < sortedData.length && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
              
            </div>
            </div>
            <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        margin: "20px 2% 2% 2%",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "24px",
        backgroundColor: "#fff",
        color: "#333",
        position: "relative",
        textTransform: "none"
      }}
    >
      <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
        Thesis
      </h3>

      {retrievalLoading ? (
        <p>Loading Thesis...</p>
      ) : (
        <ReactQuill
          theme="snow"
          value={thesisContent}
          onChange={handleContentChange}
          modules={modules}
          formats={formats}
          style={{
            width: "100%",
            height: "auto",
            marginTop: "15px",
            fontFamily: "inherit",
            whiteSpace: "pre-wrap",
          }}
          readOnly={!isEditable || loading} // Disable editing when not in edit mode or loading
        />
      )}

      {/* Edit/Save Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleEditClick}
        sx={{
          position: "absolute",
          right: "20px",
          top: "10px",
          textTransform: "none"
        }}
        disabled={loading || retrievalLoading} // Disable button while saving or loading data
      >
        {isEditable ? (loading ? 'Saving...' : 'Save') : 'Edit'}
      </Button>
    </Box>
            </>
           )}
             <Dialog
             PaperProps={{
              
              sx: {
               overflow:'scroll',
               
                margin: '0',
                maxWidth: '100%',
                 // Adjust max height if needed
                borderRadius:"24px",
                padding:"10px",
                alignItems:"center",
                '&::-webkit-scrollbar': {
                  display: 'none',
                  },
                   '-ms-overflow-style': 'none',  
                   'scrollbar-width': 'none'
                
              }
              
             
            }}
              open={open}
              onClose={handleClose}
            >
  
              <div style={{ padding: '20px', width: '400px', margin: '0' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft:"90px" }} gutterBottom>
                      Add/Reduce Units
                    </Typography>
      
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src={window.constants.asset_path+'/images/frame17.svg'}
                        style={{ background: "#F3F3F3", width: "25px", height: "25px" }}
                      />
                    </div>
                  </div>
                  <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '20px', marginBottom: '10px' }}>
                  Usable Wallet Value: {slugpopupdata.current_Wlt}
                  </Typography>
                  <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
                      <img
                        style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                        alt=""
                        src={slugpopupdata.logo}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#000" }}>
                          {slugpopupdata.slugname}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                          {slugpopupdata.slug}
                          
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
                          <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
                       
                  <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                    {slugpopupdata.pricetoday}
                  </Typography>
                
                </div>
                
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px'}}>
                    <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
                    <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                        {slugpopupdata.units}
                        </Typography>
                    </div>
                    
                  </div>
                 
                  <div>
                    <div style={{marginBottom:"10px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Units</p>
                      <input
                        style={{backgroundColor:"white",color:"black",marginTop:"-10px",border: "1px solid #1877F2", borderRadius:"10.384px", width:"100%", height:"45px" ,paddingLeft:"5px"}}
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
                    {user_role === "superuser" && (
                      <>
                      <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"200%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
                    <div style={{marginBottom:"25px"}}>
                      <p className="card__container__row3__r2__text" style={{fontSize:"14px"}}>
                      Buy/Sell Price($)
                      </p>
                      <input
                        style={{ background:"#fff",padding:"20px", border: "1px solid #1877F2", borderRadius:"10.384px", width:"100%", height:"45px" }}
                        className="card__container__row3__r2__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></input>
                    </div>
                    
                    </>
                    )}
                    <div className="card__container__row3__r4" style={{marginTop:"30px"}}>
                        <Button
                          className={
                            buySell === "buy"
                              ? "buttonToggleButActive"
                              : "buttonToggleBuy"
                          }
                          value="Buy"
                          style={{
                            lineHeight: "20px",
                            width: "159px",
                            borderRadius:"27px",
                            border:"1px solid #1877F2",
                            padding:"15px 85px",
                            marginLeft:"-10px",
                            backgroundColor: buySell === "buy" ? "" : "#1877F2",
                            color: buySell === "buy" ? "" : "white",
                            textTransform:"none",
                            fontSize:"18px",
                            fontWeight:"700"
                          }}
                          onClick={() => {
                            
                            handleClick("buy");
                            
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          className={
                            buySell === "sell"
                              ? "buttonToggleSellActive"
                              : "buttonToggleSell"
                          }
                          value="sell"
                          style={{
                            lineHeight: "20px",
                            width: "159px",
                            borderRadius:"27px",
                            border:"1px solid #1877F2",
                            marginLeft:"10px",
                            padding:"15px 85px",
                            backgroundColor: buySell === "sell" ? "" : "#1877F2",
                            color: buySell === "sell" ? "" : "white",
                            textTransform:"none",
                            fontSize:"18px",
                            fontWeight:"700"
                          }}
                          onClick={() => {
                            
                            handleClick("sell");
                            
                          }}
                        >
                          Sell
                        </Button>
                    </div>
                  </div>
              </div>
            </Dialog>

            {/* <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style} className={styles.editPortfolio}>
           <div className={styles.editPortfolioParent}>
             <div className={styles.editPortfolio1}>Add To Portfolio </div>
             <div className={styles.primaryButton}>
               <img className={styles.frameIcon} alt="" onClick={handleClose} src="/frame17.svg" />
 
             </div>
 
           </div>
           <TableContainer component={Paper} >
             <Table className={styles.responsiveTable} >
               <TableHead>
                 <TableRow>
                   <StyledTableCell>NAME OF CRYPTO</StyledTableCell>
                   <StyledTableCell >UNITS</StyledTableCell>
                   <StyledTableCell align="right">BUY/SELL ALL UNITS</StyledTableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
 
                 <StyledTableRow>
                   <StyledTableCell component="th" scope="row" className={styles.b57ac673f06a4b0338a596817eb0a5Parent}>
                     <img width={30}
                       className={styles.b57ac673f06a4b0338a596817eb0a5Icon}
                       alt=""
                       src="/b57ac673f06a4b0338a596817eb0a50ce16e2059f327dc117744449a47915cb2-1@2x.png"
                     />
                     <div className={styles.btcParent}>
                       <div className={styles.btc}>BTC</div>
                       <div className={styles.bitcoin}>Bitcoin</div>
                     </div>
                   </StyledTableCell>
                   <StyledTableCell >4</StyledTableCell>
 
                   <StyledTableCell align="right"><Button variant="outlined" onClick={dec}><HorizontalRuleOutlinedIcon /></Button>
 
 
                     <TextField
                       sx={{
                         width: 60,
                         "& fieldset": { border: 'none' },
                       }}
                       inputProps={{ min: 0, height: 36, style: { textAlign: 'center' } }}
 
                       hiddenLabel
                       id="filled-hidden-label-normal"
                       value={count}
                       size="small"
                       type="text"
                     />
                     <Button variant="outlined" onClick={inc}><AddOutlinedIcon /></Button></StyledTableCell>
 
                 </StyledTableRow>
 
 
 
               </TableBody>
             </Table>
           </TableContainer>
 
 
           <Button className={styles.saveWrapper} variant="contained" color="primary" size="large" onClick={handleClick}>Save</Button>
 
 
         </Box>
       </Modal> */}
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
        
      
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }


      </Box></Box>
):(
     
<Box
      sx={{
        display: "flex",
        height: '100vh' 
      }}
      className="maindiv"
    > 
    
     <Helmet>
        <title>{portfolioTitle}</title>
    </Helmet> 
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        
        component="main"
        sx={{
      flexGrow: 1,
      p: 2,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      overflow: "scroll" ,
      overflowY:"auto"
        }}
      >
     
           
  <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px"
}}>
  <Typography
    variant="h6"
    component="div"
    style={{
      fontFamily: "Satoshi, sans-serif",
      fontWeight: 700,
      fontSize: "20px",
    }}
  >{formattedName}  </Typography>

  
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src={window.constants.asset_path  + '/images/menubar.svg'}
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px" }} />
  </IconButton>
</div>

<Box sx={{marginTop:'-10px',marginBottom:"10px"}}>
                  
                  
     {portfolioid && (
      <span
        style={{
          display: 'inline-flex',
          alignItems: 'center', // Aligns the tooltip with the text
        }}
      >
        <span
          variant="h6"
          noWrap
          style={{
            marginLeft: '5px',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontStyle: 'normal',
            textDecoration: 'underline',
            color: 'blue',
            cursor: 'pointer',
          }}
          onClick={handleCopyText}
        >
Share your Portfolio         </span>
        <Tooltip title="Copied" open={isTooltipOpen} onClose={() => setTooltipOpen(false)}>
          {/* Empty element for the tooltip */}
       
        </Tooltip>
      </span>
    )}
   
    
    </Box>             
              
            
<div class="container" style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap:"15px",
              marginBottom: "25px",
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWwidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      marginLeft:"-10px",
      height:"70px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{roivalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>Total Profit</div>
      </div>

      <img width="23px" height="22px" style={{marginTop:"30px"}} src={`${window.constants.asset_path}/images/totalprofit.svg`}  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "80px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginRight:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{portfoliovalue}
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px",
          marginRight:"-5px"
        }}>Active Portfolio Value</div>
      </div>
      <img width="23px" height="22px" style={{

marginTop:"30px", 
}}src={`${window.constants.asset_path}/images/tpv.svg`}  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginLeft:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"       }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{PortfolioRoivalue}
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>ROI ({roidays} Days)</div>
      </div>
      <img width="23px" height="22px" style={{marginTop:"30px"}}src={`${window.constants.asset_path}/images/roi.svg`}  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      paddingTop:"0px",
      paddingBottom:"0px",
      paddingLeft:"8px",
      paddingRight:"8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      minWidth: "100px", /* Optional: to handle very small screens */
      color: "#000",
      height:"70px",
      marginRight:"-10px",
      boxShadow: "0px 2.7972731590270996px 13.986366271972656px -6.993183135986328px rgba(0, 0, 0, 0.10)",
      borderRadius: "16.78px"       }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>{currentwallet}</div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "13px",
          fontWeight: "400",
          color : "black",
          marginTop: "5px"
        }}>Wallet</div>
      </div>
      <img width="23px" height="22px" style={{marginTop:"30px"}}src={`${window.constants.asset_path}/images/wallet.svg`}  alt="wallet" class="icon"/>
    </div>
    
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0 0px',
        marginLeft:"-35px",
        marginBottom: '2px',
        fontSize: '14px',
        gap: '7px',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        width: '95%',
      }}
    >
      <Button
        variant="outlined"
        style={{
          backgroundColor: 'white',
          color: '#1877F2',
          borderColor: '#1877F2',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '27px',
          textTransform: 'none',
          fontFamily: 'Satoshi, sans-serif',
          fontSize: isSmallScreen ? '14px' : '16px',
          fontWeight: '700',
          padding: isSmallScreen ? '5px 10px' : '5px 20px',
          maxHeight: '40px',
          whiteSpace: 'nowrap',
          flexShrink: 1,
          minWidth: 0,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        onClick={() => navigate('detailed-portfolio-analysis')}
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = '#1877F2';
          e.currentTarget.style.color = 'white';
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.color = '#1877F2';
        }}
      >
        <img
          alt=""
          src={window.constants.asset_path + '/images/analysis.png'}
          width={isSmallScreen ? 24 : 36}
          height={isSmallScreen ? 24 : 36}
          style={{
            borderRadius: '19px',
            marginRight: isSmallScreen ? '5px' : '10px',
          }}
        />
        Analysis
      </Button>
      <Button
        variant="outlined"
        style={{
          backgroundColor: 'white',
          color: '#1877F2',
          borderColor: '#1877F2',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderRadius: '27px',
          textTransform: 'none',
          fontFamily: 'Satoshi, sans-serif',
          fontSize: isSmallScreen ? '14px' : '16px',
          fontWeight: '700',
          padding: isSmallScreen ? '5px 10px' : '5px 20px',
          maxHeight: '40px',
          whiteSpace: 'nowrap',
          flexShrink: 1,
          minWidth: 0,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        onClick={() => navigate('recommendations')}
        onMouseDown={(e) => {
          e.currentTarget.style.backgroundColor = '#1877F2';
          e.currentTarget.style.color = 'white';
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.backgroundColor = 'white';
          e.currentTarget.style.color = '#1877F2';
        }}
      >
        <img
          alt=""
          src={window.constants.asset_path + '/images/brain_blue_image.png'}
          width={isSmallScreen ? 24 : 36}
          height={isSmallScreen ? 24 : 36}
          style={{
            borderRadius: '19px',
            marginRight: isSmallScreen ? '5px' : '10px',
          }}
        />
        Recommendations
      </Button>
    </div>
  </div>
  
  <div>
        <Box
            sx={{
              borderRadius: "10px",
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "0px",
              color:"#1877F2",
            }}
          >
            <Button
              onClick={handlePopoverOpen}
            >
              
              <div style={{
              marginTop:"-190px",
              marginRight:"-27px",
              marginBottom:"-80px",
              padding:"6px 9px"
              }}>
              <img width="23px"
              src={window.constants.asset_path + '/images/filter2.svg'}/></div>
            </Button>

            <Popover
              open={openfilter}
              anchorEl={anchorEl}
              onClose={handleCloseeoption}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{
                marginTop:"-25px"
              }}
            >
             <MenuItem
                selected={selectedFilter === "roi_HL"}
                onClick={handleClickROIHL}
              >
                ROI High to Low
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "roi_LH"}
                onClick={handleClickROILH}
              >
                ROI Low to High
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "roi_LH"}
                onClick={handleClickROILH}
              >
                7 Days
              </MenuItem>
              <MenuItem
                selected={selectedFilter === "roi_LH"}
                onClick={handleClickROILH}
              >
                30 Days
              </MenuItem>
    
              
            

              <Box sx={{ display: "flex", padding: "8px" }}>
                <Button
                  size="small"
                  sx={{ textTransform: "capitalize", marginLeft: "10px" }}
                  onClick={handleClearAll}
                  startIcon={<ClearIcon />}
                >
                  Clear All
                </Button>
              </Box>
            </Popover>
          </Box>

  </div>
            
{initialPage &&   <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          overflowY:"auto"
        }}
      >

        <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu">
            <ArrowBackIcon /> Go Back
          </Button>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
      
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "white",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #1877F2",
                  color: "#1877F2", fontSize: "12px"
                }}
                onClick={handleCreateNew}
              >
            Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}
      

     

        {!initialPage && (
          <>      
{!initialPage && (
      <>
        {loader === false && (
          <Box 
          sx={{ 
            padding: "5px", 
            backgroundColor: "#FFF", 
            borderRadius: "8px", 
            margin: "8px 0" }}>
            {rows.map((idx) => (
              <Box
                key={idx}
                sx={{
                  padding: "16px",
                  backgroundColor: "#F9F9F9",
                  borderRadius: "8px",
                  marginBottom: "16px",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
                  <Skeleton
                    sx={{ marginRight: "8px", borderRadius: "8px" }}
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                  <Skeleton variant="text" width={200} height={30} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Skeleton variant="text" width="50%" height={30} />
                  <Skeleton variant="text" width="50%" height={30} />
                  <Skeleton variant="text" width="50%" height={30} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
       
        {loader && (
          <Box style={{
            overflowY:"auto", overflowX:"hidden",
            marginBottom:"130px",
            marginTop:"-30px"
            
            }}>
             {sortedData.slice(0, rowsToShow).map((obj, idx) => (
              <Box
                key={idx}
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  marginTop:"15px",
                  marginBotton: "10px",
                  border: "1px solid #DADEDF",
                  marginLeft:"3px",
                  marginRight:"5px"
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
  <img
    src={obj.logo}
    alt={obj.slug}
    width={38}
    height={38}
    style={{ borderRadius: "19px", marginRight: "16px", border: "2px solid #000", width: "40px", height: "40px", margin: "8px" }}
  />
  <Typography variant="h6" style={{ fontSize: "18px", fontWeight: "500" }}>
    <a href={`${process.env.REACT_APP_HOME_URL}crypto/${obj.crypto_symbol}`} style={{color:"black"}}>{obj.slug}</a></Typography>

    {obj.category && ( // Check if obj.category is not null or empty
  <div
    style={{ textDecoration: 'none', marginLeft: "auto", marginRight: "5px" }} // Ensures the link stays at the rightmost
  >
    <Box
      sx={{
        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
        borderRadius: "15px",
        padding: "2px 11px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#000",
        marginBottom: "0px",
      }}
    >
      {obj.category}
    </Box>
  </div>
)}


  <Box sx={{ marginLeft: "auto",marginRight:"-15px" }}>
    <Button
      className="btn"
      onClick={() => handleOpen(idx)}
      
    >
      <img
        src={window.constants.asset_path + '/images/edit.svg'}
        alt="Edit Icon"
        style={{ width: "16px", height: "16px" }}
      />
    </Button>
  </Box>
</Box>

<Box sx={{background:"#EBE9EB", borderRadius:"6px", padding: "10px"}}>

  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" ,borderBottom:"2px #DFDFDF solid"}}>
    <Box sx={{ flex: 1, marginRight: "8px" }}>
      <div style={{ display: "flex", justifyContent: "", margin: "4px",marginTop:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Current:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.price_today}</span>
      </div>
    </Box>
    <Box sx={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px",marginTop:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"20px" }}>Units:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.total_unit}</span>
      </div>
    </Box>
  </Box>

  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Box sx={{ flex: 1, marginRight: "8px" }}>
      <div style={{ display: "flex", justifyContent: "", margin: "4px",marginBottom:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"28px" }}>Cost:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.blended_cost}</span>
      </div>
    </Box>
    <Box sx={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px" ,marginBottom:"-5px"}}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"20px" }}>ROI:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.total_roi_value}</span>
      </div>
    </Box>
  </Box>

</Box>

              </Box>
            ))}
            <div
  style={{
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    border: "none",
    margin: "0 8px 0 8px",
    fontSize: "14px",
  }}
>
  
    

  
</div>

{rowsToShow < sortedData.length && (
                <div className="loadingButton">
                  <LoadingButton
                    loading={addMoreLoader}
                    loadingPosition="center"
                    variant="outlined"
                    style={{
                      maxWidth: "360px",
                      width: "80vw",
                      backgroundColor: "rgba(67, 97, 238, 0.15)",
                      borderRadius: "6px",
                      border: "none",
                      textTransform: "none",
                      borderStyle: "solid",
                      borderColor: "rgba(67, 97, 238, 0.15)",
                      borderWidth: "1px",
                      color: "#4361ee",
                      width: mediaQueryVar === false ? "80vw" : "180px",
                    }}
                    onClick={() => {
                      loadMore();
                    }}
                  >
                    <span>Load More</span>
                  </LoadingButton>
                </div>
              )}
             <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        margin: "20px 5px 10px 5px",
        padding: "15px",
        border: "1px solid #ddd",
        borderRadius: "24px",
        backgroundColor: "#fff",
        color: "#333",
        position: "relative",
        textTransform: "none",
        
      }}
    >
      <h3 style={{ fontWeight: "600", color: "black", fontSize: "20px" }}>
        Thesis
      </h3>

      {retrievalLoading ? (
        <p>Loading Thesis...</p>
      ) : (
        <ReactQuill
          theme="snow"
          value={thesisContent}
          onChange={handleContentChange}
          modules={modules}
          formats={formats}
          style={{
            width: "100%",
            height: "auto",
            marginTop: "15px",
            fontFamily: "inherit",
            whiteSpace: "nowrap",
            overflow:"scroll"
          }}
          readOnly={!isEditable || loading} // Disable editing when not in edit mode or loading
        />
      )}

      {/* Edit/Save Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleEditClick}
        sx={{
          position: "absolute",
          right: "20px",
          top: "10px",
          textTransform: "none"
        }}
        disabled={loading || retrievalLoading} // Disable button while saving or loading data
      >
        {isEditable ? (loading ? 'Saving...' : 'Save') : 'Edit'}
      </Button>
    </Box>
    
          </Box>
          
        )}
      
      </>
      
    )}
              
              

              
              
            
            </>
            
           )}
            
            <Dialog
             PaperProps={{
              
              sx: {
                position: mediaQueryVar ===false ? 'fixed' : 'static',
                bottom: mediaQueryVar === false? '0' : '',
                left: mediaQueryVar === false? '0' : '',
                right: mediaQueryVar === false? '0' : '',
                margin: '0',
                maxWidth: '100%',
                minHeight: '40%', // Adjust max height if needed
                borderRadius:mediaQueryVar === false? "24px 24px 0 0":"24px",
                padding:mediaQueryVar === false? '10px' : '10px',
                alignItems:"center",
                
                
              }
              
             
            }}
              open={open}
              onClose={handleClose}
            >
  <div style={{ display: 'flex', justifyContent: 'center' }}>
   <button style={{
    width: '48px',
    height: '4px',
    borderRadius: '100px',
    background: '#E2E2E2',
    border: 'none',
    margin: '10px',

  }}></button></div>
              <div style={{ padding: '20px', width:mediaQueryVar===false? '100%': '400px', margin: '0' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                    <Typography style={{ fontWeight: '500', fontSize: '20.767px',textAlign:"center", }} gutterBottom>
                      Add/Reduce Units
                    </Typography>
      
                    <div className="primaryButton">
                      <img
                        className="frameIcon"
                        alt=""
                        onClick={handleClose}
                        src={window.constants.asset_path+'/images/frame17.svg'}
                        style={{ background: "#F3F3F3", width: "25px", height: "25px",marginLeft:"100%" }}
                      />
                    </div>
                  </div>
                  <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '20px', marginBottom: '10px' }}>
                  Usable Wallet Value: {slugpopupdata.current_Wlt}
                  </Typography>
                  <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
                      <img
                        style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                        alt=""
                        src={slugpopupdata.logo}
                      />
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#000" }}>
                          {slugpopupdata.slugname}
                        </Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                          {slugpopupdata.slug}
                        </Typography>
                      </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
                          <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
                       
                  <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                    {slugpopupdata.pricetoday}
                  </Typography>
                
                </div>
                
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px'}}>
                    <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
                    <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                        {slugpopupdata.units}
                        </Typography>
                    </div>
                  </div>
                 
                  <div>
                    <div style={{marginBottom:"10px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Units</p>
                      <input
                        style={{backgroundColor:"white",color:"black",marginTop:"-10px",border: "1px solid #1877F2", borderRadius:"10.384px", width:"100%", height:"45px" ,paddingLeft:"5px"}}
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}
                      ></input>
                    </div>
                    <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
                    {user_role === "superuser" && (
                      <>

<div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"200%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>

                    <div style={{marginBottom:"25px"}}>
                      <p className="card__container__row3__r2__text" style={{fontSize:"14px"}}>
                      Buy/Sell Price($)
                      </p>
                      <input
                        style={{ background:"#fff",padding:"20px", border: "1px solid #1877F2", borderRadius:"10.384px", width:"100%", height:"45px" }}
                        className="card__container__row3__r2__input"
                        min="1"
                        max="10"
                        type="number"
                        placeholder=""
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      ></input>
                    </div>
                    </>
                    )}
                    <div className="card__container__row3__r4" style={{marginTop:"30px"}}>
                        <Button
                          className={
                            buySell === "buy"
                              ? "buttonToggleButActive"
                              : "buttonToggleBuy"
                          }
                          value="Buy"
                          style={{
                            lineHeight: "20px",
                            width: "45%",
                            borderRadius:"27px",
                            border:"1px solid #1877F2",
                            padding:"15px 8px",
                            marginLeft:"4px",
                            backgroundColor: buySell === "buy" ? "" : "#1877F2",
                            color: buySell === "buy" ? "" : "white",
                            textTransform:"none",
                            fontSize:"18px",
                            fontWeight:"700"
                          }}
                          onClick={() => {
                            
                            handleClick("buy");
                            
                          }}
                        >
                          Buy
                        </Button>
                        <Button
                          className={
                            buySell === "sell"
                              ? "buttonToggleSellActive"
                              : "buttonToggleSell"
                          }
                          value="sell"
                          style={{
                            lineHeight: "20px",
                            width: "45%",
                            borderRadius:"27px",
                            border:"1px solid #1877F2",
                            marginLeft:"10px",
                            marginRight:"10px",
                            padding:"15px 8px",
                            backgroundColor: buySell === "sell" ? "" : "#1877F2",
                            color: buySell === "sell" ? "" : "white",
                            textTransform:"none",
                            fontSize:"18px",
                            fontWeight:"700"
                          }}
                          onClick={() => {
                            
                            handleClick("sell");
                            
                          }}
                        >
                          Sell
                        </Button>
                    </div>
                  </div>
              </div>
            </Dialog>

           
            <Snackbar
              open={snackbaropen}
              autoHideDuration={5000}
              onClose={closesnackbar}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
            </Snackbar>
        
      
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }



      </Box>
     
      
    </Box>
    
  )}
   
   {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            zIndex: "100",
          }}
        >

<div style={{ 
  display: "flex", 
  justifyContent: "center", 
  gap: "10px", 
  marginTop: "20px" ,
  marginBottom:"20px"
}}>
  <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      width: "160px",
    }}
    onClick={() => navigate("order-book")}
    onMouseDown={(e) => {
      // Change button styles on click
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      // Revert button styles when click is released
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Order Book
  </Button>
  
  <Button
    variant="outlined"
    style={{
      backgroundColor: "white",
      color: "#1877F2",
      borderColor: "#1877F2",
      borderWidth: "2px",
      borderStyle: "solid",
      borderRadius: "27px",
      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "700",
      padding: "5px 20px",
      width: "160px",
    }}
    onClick={() => navigate("/crypto-screener")}
    onMouseDown={(e) => {
      // Change button styles on click
      e.currentTarget.style.backgroundColor = "#1877F2";
      e.currentTarget.style.color = "white";
    }}
    onMouseUp={(e) => {
      // Revert button styles when click is released
      e.currentTarget.style.backgroundColor = "white";
      e.currentTarget.style.color = "#1877F2";
    }}
  >
    Add More
  </Button>

</div>




<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
        </div>
      )}
</>


  );
 
}





export default Portfolio;