import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  lazy,
  Suspense,
} from "react";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import "./CryptoPage.css"; // Assuming you have a CSS file for styling
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import EmailPopUp from "./EmailPopUp.js";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { BottomNavigation, Button } from "@mui/material";
import SidebarMenuList from "../includes/sidebarMenuList.jsx";

import IconButton from "@mui/material/IconButton";
import { Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoginPopUp from "./loginpopup";
import { Tabs, Tab } from "@mui/material";
import { getCryporCurrencyListHome } from "../apihelper/homepage";

import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Card, TextField } from "@mui/material";

import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components

import { addToPortfolio, copyToPortfolio } from "../apihelper/portfolio";

import CloseIcon from "@mui/icons-material/Close";

import CreatableSelect from "react-select/creatable";
import {
  getTotalunit,
  getFetchdropdown,
} from "../apihelper/cryptocurrencylist";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const drawerWidth = 292;
const CryptoPage = (props) => {
  const { window1 } = props;
  const { crypto } = useParams();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortType, setSortType] = useState("fd_asc");
  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [slug, setSlug] = useState(false);
  const [tfhrs, settfhrs] = useState("");
  const [sevenDays, setSevenDays] = useState(false);
  const [thirtyDays, setThirtyDays] = useState(false);
  const [yearDays, setYearDays] = useState(false);
  const [buyPercent, setBuyPercent] = useState(false);
  const [holdings, setHoldings] = useState(false);
  const [description, setDescription] = useState("");
  const [symbolLogo, setSymbolLogo] = useState(false);
  const [currentPrice, setCurrentPrice] = useState(false);
  const [marketCap, setMarketCap] = useState(false);
  const [rowsToShow, setRowsToShow] = useState(4);
  const [selectedSort, setSelectedSort] = useState("");
  const [hashtags, setHashtags] = useState(false);
  const sortRef = useRef();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const loc = location.pathname;
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    // Track ascending/descending for each column
    forecaster: "asc",
    target: "asc",
    accuracy: "asc",
    percent: "asc",
    targetDate: "asc",
  });

  // Render row based on the action color
  const renderAction = (action) => {
    switch (action) {
      case "Buy":
      case "Bullish":
      case "Partially Bullish":
      case "Strong Buy":
        return <span style={{ color: "green", fontWeight: "" }}>{action}</span>;

      case "Sell":
      case "Bearish":
      case "Partially Bearish":
      case "Strong Sell":
        return <span style={{ color: "red", fontWeight: "" }}>{action}</span>;
      case "Neutral":
        return <span style={{ color: "orange", fontWeight: "" }}>Neutral</span>;
      default:
        return null;
    }
  };

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    }),
  );
  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");
    // Open the login modal
    setLoginModalOpen(true);
  };
  const handleModalClose = () => {
    // Close the modal and reset the URL to the welcome page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
  };

  const mediaQueryVar1 = useMediaQuery(
    json2mq({
      minWidth: 400,
    }),
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border

    "& td, & th": {
      border: 0,
    },
  }));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );

  const [predictionLoader, setPredictionLoader] = useState(false);
  const [oscillatorData, setOscillatorData] = useState(false);
  const [movingAveragesData, setMovingAveragesData] = useState(false);
  const [timestamp, setTimestamp] = useState("");
  const [pivotdata, setpivotdata] = useState("");
  const [evaluation_data, setevaluation_data] = useState("");

  const container =
    window1 !== undefined ? () => window1().document.body : undefined;

  useEffect(() => {
    const fetchData = async () => {
      console.log(crypto + "hello");
      try {
        const response = await fetch(
          "https://crowdwisdom.live/wp-json/api/v1/showForecasterFutureDataInvesting",
          {
            // Replace with your actual API endpoint
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              searchVal: crypto, // Adjust if necessary
              sortType: sortType,
            }),
          },
        );

        const result = await response.json();
        console.log(result);

        if (result.code === "200") {
          if (result.response.length === 0) {
            setPredictionLoader(true);
          }
          setLoader(true);
          setSlug(result.symbol);
          setCurrentPrice(result.current_price);
          setSymbolLogo(result.symbol_logo);
          settfhrs(result.tfhrs);
          setSevenDays(result.last_7_days);
          setThirtyDays(result.last_30_days);
          setYearDays(result.last_365_days);
          setBuyPercent(result.buy_percent);
          setHoldings(result.holdings);
          setDescription(result.crypto_desc);
          setMarketCap(result.market_cap);
          setHashtags(result.hashtags);
          setData(result.response);
          setOscillatorData(result.oscillator_data); // Store oscillator data
          setMovingAveragesData(result.moving_averages_data); // Store moving averages data
          setTimestamp(result.timestamp);
          setpivotdata(result.pivot_data);
          setevaluation_data(result.evaluation_data);
          console.log({ slug });
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [crypto, sortType]);
  const sanitizedDescription = description;
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 420,
    }),
  );

  // Function to extract indentation level and style accordingly
  const getIndentationStyle = (htmlString) => {
    const div = document.createElement("div");
    div.innerHTML = htmlString;

    const items = div.querySelectorAll("li");
    items.forEach((item) => {
      const match = item.className.match(/ql-indent-(\d+)/);
      if (match) {
        const level = parseInt(match[1], 10);
        // Set padding based on indent level for content, reducing by 10px
        item.style.paddingLeft = `${20 * level - 20}px`; // Adjusted padding
        // Adjust margin for bullet alignment
        item.style.marginLeft = `${40 * level}px`; // Bullet alignment
      }
    });

    return div.innerHTML; // Return modified HTML
  };

  const processedDescription = getIndentationStyle(sanitizedDescription);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        setSortDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (index) => {
    setDropdownVisible(dropdownVisible === index ? null : index);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortChange = (sortType) => {
    setSortType(sortType);
    setSortDropdownVisible(false);
    setSelectedSort(sortType);
  };

  const openModal = async (data) => {
    if (data.accuracy.toLowerCase() !== "pending") {
      try {
        const response = await fetch(
          "https://crowdwisdom.live/wp-json/api/v1/showForecasterPastData",
          {
            // Replace with your actual API endpoint
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              searchVal: crypto,
              forecasterName: data.forecaster_name,
            }),
          },
        );

        const result = await response.json();
        console.log(result);

        if (result.code === "200") {
          setModalData(result.response);
          setModalIsOpen(true);
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalData(null);
  };

  const filteredData = data.filter((forecaster) =>
    forecaster.forecaster_name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleLoadMore = () => {
    setRowsToShow(rowsToShow + 4);
  };

  const [isTableView, setIsTableView] = useState(true);
  const [selectedButton, setSelectedButton] = useState("CoinPrices");

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  let url = `/predict-now`;
  const parseDate = (dateStr) => {
    const [month, day, year] = dateStr.split(" ");
    const monthMap = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };
    const formattedDate = `${year}-${monthMap[month]}-${day.padStart(2, "0")}`;
    return new Date(formattedDate);
  };

  const parseForecastPrice = (price) => {
    return parseFloat(price.replace(/[^0-9.-]+/g, ""));
  };

  const parseAccuracy = (accuracy) => {
    if (typeof accuracy !== "string") return NaN; // Return NaN if input is not a string

    // Extract the number from the accuracy string
    const percentage = accuracy.split("%")[0].trim(); // Get the part before '%'
    const parsedValue = parseFloat(percentage.split(" ")[0]); // Convert to float

    return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 for NaN for safety
  };

  const compareValues = (val1, val2, order = "asc") => {
    // If either value is NaN, treat it as less than any valid number
    if (isNaN(val1) && isNaN(val2)) return 0; // Both are NaN
    if (isNaN(val1)) return 1; // Treat NaN as the largest value
    if (isNaN(val2)) return -1; // Treat NaN as the largest value

    // Perform comparison based on order
    if (order === "asc") return val1 - val2;
    return val2 - val1;
  };

  const handleSort = (column) => {
    const newSortOrder = sortOrder[column] === "asc" ? "desc" : "asc"; // Toggle sort order
    setSortOrder({ ...sortOrder, [column]: newSortOrder });

    // Set the sort type based on the column
    switch (column) {
      case "forecaster":
        setSortType(newSortOrder === "asc" ? "fc_asc" : "fc_desc");
        break;
      case "target":
        setSortType(newSortOrder === "asc" ? "tg_asc" : "tg_desc");
        break;
      case "accuracy":
        setSortType(newSortOrder === "asc" ? "ac_asc" : "ac_desc");
        break;
      case "percent":
        setSortType(newSortOrder === "asc" ? "pr_asc" : "pr_desc");
        break;
      case "targetDate":
        setSortType(newSortOrder === "asc" ? "fd_asc" : "fd_desc");
        break;
      default:
        break;
    }
  };

  const sortedData = [...filteredData].sort((a, b) => {
    switch (sortType) {
      case "fc_asc":
        return a.forecaster_name.localeCompare(b.forecaster_name);
      case "fc_desc":
        return b.forecaster_name.localeCompare(a.forecaster_name);
      case "tg_asc":
        return (
          parseForecastPrice(a.forecast_price) -
          parseForecastPrice(b.forecast_price)
        );
      case "tg_desc":
        return (
          parseForecastPrice(b.forecast_price) -
          parseForecastPrice(a.forecast_price)
        );
      case "ac_asc": {
        const accuracyA = parseAccuracy(a.accuracy);
        const accuracyB = parseAccuracy(b.accuracy);
        return compareValues(accuracyA, accuracyB, "asc");
      }
      case "ac_desc": {
        const accuracyA = parseAccuracy(a.accuracy);
        const accuracyB = parseAccuracy(b.accuracy);
        return compareValues(accuracyA, accuracyB, "desc");
      }
      case "pr_asc":
        return parseFloat(a.target_percent) - parseFloat(b.target_percent);
      case "pr_desc":
        return parseFloat(b.target_percent) - parseFloat(a.target_percent);
      case "fd_asc":
        return parseDate(a.target_date) - parseDate(b.target_date);
      case "fd_desc":
        return parseDate(b.target_date) - parseDate(a.target_date);
      default:
        return 0;
    }
  });

  const [isPredictionDataLoaded, setIsPredictionDataLoaded] = useState(false);
  useEffect(() => {
    if (sortedData && sortedData.length > 0) {
      setIsPredictionDataLoaded(true); // Set to true when data is available
    }
  }, [sortedData]);

  const countEvaluations = (data, evaluationKeys) => {
    let buys = 0,
      sells = 0,
      neutrals = 0;

    data.forEach((item) => {
      evaluationKeys.forEach((key) => {
        if (item[key] === "Buy") buys += 1;
        else if (item[key] === "Sell") sells += 1;
        else if (item[key] === "Neutral") neutrals += 1;
      });
    });

    return { buys, sells, neutrals };
  };

  const oscillatorEvaluationKeys = [
    "rsi_evaluation",
    "cci_evaluation",
    "ao_evaluation",
    "mom_evaluation",
    "macd_evaluation",
    "stochrsi_evaluation",
    "willr_evaluation",
    "ultosc_evaluation",
  ];

  const movingAverageEvaluationKeys = [
    "sma_10_evaluation",
    "sma_50_evaluation",
    "sma_100_evaluation",
    "sma_200_evaluation",
    "ema_10_evaluation",
    "ema_50_evaluation",
    "ema_100_evaluation",
    "ema_200_evaluation",
  ];

  const isDataLoaded = oscillatorData && movingAveragesData;

  const oscillatorCounts = isDataLoaded
    ? countEvaluations(oscillatorData, oscillatorEvaluationKeys)
    : { buys: 0, sells: 0, neutrals: 0 };

  const movingAverageCounts = isDataLoaded
    ? countEvaluations(movingAveragesData, movingAverageEvaluationKeys)
    : { buys: 0, sells: 0, neutrals: 0 };

  const {
    buys: oscillatorBuys,
    sells: oscillatorSells,
    neutrals: oscillatorNeutrals,
  } = oscillatorCounts;
  const {
    buys: movingAverageBuys,
    sells: movingAverageSells,
    neutrals: movingAverageNeutrals,
  } = movingAverageCounts;

  const getSummary = (buys, sells) => {
    if (buys > sells) return "Strong Buy";
    if (sells > buys) return "Strong Sell";
    return "Neutral";
  };

  const oscillatorSummary = isDataLoaded
    ? getSummary(oscillatorBuys, oscillatorSells)
    : "-";
  const movingAverageSummary = isDataLoaded
    ? getSummary(movingAverageBuys, movingAverageSells)
    : "-";

  const getOverallSummary = () => {
    if (
      oscillatorSummary === "Strong Buy" &&
      movingAverageSummary === "Strong Buy"
    )
      return "Bullish";
    if (
      oscillatorSummary === "Strong Sell" &&
      movingAverageSummary === "Strong Sell"
    )
      return "Bearish";
    if (
      oscillatorSummary === "Strong Buy" ||
      movingAverageSummary === "Strong Buy"
    )
      return "Partially Bullish";
    if (
      oscillatorSummary === "Strong Sell" ||
      movingAverageSummary === "Strong Sell"
    )
      return "Partially Bearish";
    if (!oscillatorSummary || !movingAverageSummary) return "Neutral";
    return "";
  };

  const overallSummary = isDataLoaded ? getOverallSummary() : "-";

  // Check if any data is available for oscillators or moving averages
  const hasOscillatorData =
    oscillatorBuys + oscillatorSells + oscillatorNeutrals > 0;
  const hasMovingAverageData =
    movingAverageBuys + movingAverageSells + movingAverageNeutrals > 0;

  // Define statements based on sentiment
  const bullish =
    `A breakout above its initial resistance is likely to draw in more buyers. ` +
    `The next level of resistance at ${pivotdata.pivot_r2} may be retested or surpassed if buying pressure intensifies. `;

  const bearish =
    `If ${slug} fails to hold above its first level of support, ${pivotdata.pivot_s2} ` +
    `will be the immediate support. `;

  const neutral =
    `${slug} is likely to trade in the range between ${pivotdata.pivot_s1} and ${pivotdata.pivot_r1} ` +
    `for now.`;

  // Main statement based on overall sentiment
  const sentimentStatement =
    overallSummary === "Bullish" || overallSummary === "Partially Bullish"
      ? bullish
      : overallSummary === "Bearish" || overallSummary === "Partially Bearish"
        ? bearish
        : neutral;

  const [data_replacements, setData_replacements] = useState([]);
  const [loading_replacements, setLoading_replacements] = useState(true);
  const [predictionLoader_replacements, setPredictionLoader_replacements] =
    useState(false);
  const [
    isPredictionDataLoaded_replacements,
    setIsPredictionDataLoaded_replacements,
  ] = useState(false);

  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        const response = await fetch(
          "https://crowdwisdom.live/wp-json/api/v1/showCryptoReplacements",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              crypto: crypto,
              sortType: sortType,
            }),
          },
        );

        const result = await response.json();
        console.log("replacements:", result);
        if (result.code === "200") {
          setData_replacements(result.response);
          if (result.response.length === 0) {
            setPredictionLoader_replacements(true);
          }
        } else {
          setError(result.message);
        }
      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading_replacements(false);
      }
    };

    fetchCryptoData();
  }, [sortType, crypto]);

  const [showModal, setShowModal] = useState(false); // Change the state name to showModal

  const filteredData1 = data_replacements;

  const sortedData1 = [...filteredData1].sort((a, b) => {
    switch (sortType) {
      case "ac_asc":
        return compareValues(a.accuracy, b.accuracy, "asc");
      case "ac_desc":
        return compareValues(a.accuracy, b.accuracy, "desc");
      default:
        return 0;
    }
  });

  const [ignoredRows, setIgnoredRows] = useState([]);

  const [open, setOpen] = useState(false);
  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

    getFetchdropdown({
      user_id: userData.user_id,

      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        const newOptions = res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(newOptions);
        setuser_role(res.user_role);
        //console.log(res.user_role);
      } else {
        console.log("Data fething error");
      }
    });
  };

  const handleIgnoreClick = (index) => {
    const updatedIgnoredRows = [...ignoredRows, index];
    setIgnoredRows(updatedIgnoredRows);

    // Check if all currently displayed rows are ignored
    const allIgnored = sortedData1
      .slice(0, rowsToShow)
      .every((_, idx) => updatedIgnoredRows.includes(idx));

    // If all rows are ignored, call handleLoadMore
    if (allIgnored) {
      handleLoadMore();
    }
  };

  const allRowsIgnored = sortedData1
    .slice(0, rowsToShow)
    .every((_, index) => ignoredRows.includes(index));

  useEffect(() => {
    if (sortedData1 && sortedData1.length > 0) {
      setIsPredictionDataLoaded_replacements(true); // Set to true when data is available
    }
  }, [sortedData1]);

  const [unit, setUnit] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [price, setPrice] = useState("");
  const [totalUnits, setTotalUnits] = useState(0);
  const [selectedDate, handleDateChange] = useState("");
  const [Usablewallet, setUsablewallet] = useState("");
  const [portfolioTypeId, setPortfolioTypeId] = useState("");
  const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    } else {
      const selectedValue = selectedOption.value;
      if (selectedValue.includes("-")) {
        setErrorMessage("Hyphens are not allowed in the option."); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
      setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: slug, // Assuming value contains the slug
        portfolio_type_id: selectedOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  };
  useEffect(() => {
    if (open || showModal) {
      fetchDropdownOptions();
    }
  }, [open, showModal]);
  const handleCreate = async (inputValue) => {
    setTotalUnits(0);
    setUsablewallet("");

    if (options.find((option) => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(
      (option) => option.value == inputValue,
    );
    // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formatToNonZero = (number) => {
    number = parseFloat(number);

    if (number >= 1) {
      return number.toFixed(2);
    } else if (number >= 0.1) {
      return number.toFixed(1);
    } else if (number >= 0.01) {
      return number.toFixed(4);
    } else if (number >= 0.001) {
      return number.toFixed(5);
    } else if (number >= 0.0001) {
      return number.toFixed(6);
    } else if (number >= 0.00001) {
      return number.toFixed(7);
    } else if (number >= 0.000001) {
      return number.toFixed(8);
    } else if (number >= 0.0000001) {
      return number.toFixed(9);
    } else if (number >= 0.00000001) {
      return number.toFixed(10);
    } else if (number >= 0.000000001) {
      return number.toFixed(11);
    }
    return number.toFixed(1);
  };
  const [buySell, setbuySell] = React.useState("");
  const handleClick = (buySell) => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    setIsFormSubmitted(false);
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const portfolioId =
      selectedOption && !isNaN(parseInt(selectedOption.value))
        ? parseInt(selectedOption.value)
        : 0;
    const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

    addToPortfolio({
      portfolio_type_id: portfolioId,
      portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

      existing_portfolio: existingPortfolio,
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        // setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        setPortfolioTypeId(res.portfolio_type_id);
        setIsFormSubmitted(true);
        // navigate(
        //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
        //     selectedOption.label
        //   )}`
        // );
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newOptionValue, setNewOptionValue] = useState("");
  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const [severity, setSeverity] = useState("info");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const handleClose = () => setOpen(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [dataslug, setdataslug] = useState("");
  const [options, setOptions] = useState([]);
  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes("-")) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };
  const [slugdata, setSlugdata] = useState([]);
  const [user_role, setuser_role] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });

  const handleAddToPortfolio = (idx) => {
    console.log("IDX value is : ", idx);

    getCryporCurrencyListHome({
      slug: idx,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully", res.data);

        // Update slugdata
        setSlugdata(res.data);
        setuser_role(res.user_role);

        // Use res.data directly here
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (userData) {
          console.log("User DAta is there");
          setSlugpopupdata({
            slugname: res.data[0].slug,
            slug: res.data[0].name,
            units: res.data[0].total_unit,
            pricetoday: res.data[0].price_today,
            logo: res.data[0].logo,
          });
          console.log("Slug Popup Data : ", res.data);
          setOpen(true);
        } else {
          window.history.pushState({}, "", "/login");
          setLoginModalOpen(true);
        }
      } else {
        console.log("Data fetching error");
      }
    });
  };

  return (
    <div className="crypto-page">
      <Helmet>
        <title>
          {crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Analysis
        </title>
        <meta
          name="description"
          content={`Accurate ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Influencer Price Predictions & In-depth ${crypto.charAt(0).toUpperCase() + crypto.slice(1)} Price Analysis.`}
        />
      </Helmet>

      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        overlayClassName={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
            backdropFilter: "blur(5px)", // Optional: Add a blur effect to the overlay
          },
        }}
        className={{
          content: {
            position: "relative",
            backgroundColor: "white", // Set background for inner popup
            padding: "20px", // Add padding for content
            borderRadius: "8px", // Rounded corners
            maxWidth: mediaQueryVar ? "100%" : "100%", // Set a max width for popup
            margin: "0", // Center the popup horizontally
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)", // Optional: Popup shadow
          },
        }}
      >
        <div className="CardOpener" style={{ overflow: "hidden" }}>
          {isFormSubmitted ? (
            <div
              style={{
                position: "fixed", // Fix the popup to the top of the viewport
                top: 0, // Align it to the top
                left: 0,
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: darken the background for emphasis
                zIndex: 1000, // High z-index to overlay other elements
              }}
            >
              <Card className="card__container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
                  </div>
                  <Typography
                    severity="success"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Portfolio added successfully!
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setIsFormSubmitted(false);
                        setOpen(false);
                        setUnit("");
                        setSelectedOption("");
                        setPrice("");
                        setTotalUnits(0);
                        handleDateChange("");
                        setUsablewallet("");
                      }}
                      color="primary"
                      style={{
                        backgroundColor: "rgba(67, 97, 238, 0.15)",
                        borderRadius: "6px",
                        border: "none",
                        textTransform: "none",
                        borderStyle: "solid",
                        borderColor: "rgba(67, 97, 238, 0.15)",
                        borderWidth: "1px",
                        color: "#4361ee",
                        whiteSpace: "nowrap",
                        float: "inline-end",
                        width: mediaQueryVar === true ? "150px" : "100px",
                        height: mediaQueryVar === false ? "30px" : "",
                        fontSize: mediaQueryVar === false ? "10px" : "11px",
                      }}
                    >
                      Add More Cryptos
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                            selectedOption.label.replace(/\s+/g, "-"),
                          )}`,
                        )
                      }
                      variant="outlined"
                      style={{
                        backgroundColor: "rgba(67, 97, 238, 0.15)",
                        borderRadius: "6px",
                        border: "none",
                        textTransform: "none",
                        borderStyle: "solid",
                        borderColor: "rgba(67, 97, 238, 0.15)",
                        borderWidth: "1px",
                        color: "#4361ee",
                        whiteSpace: "nowrap",
                        float: "inline-end",
                        width: mediaQueryVar === true ? "100px" : "100px",
                        height: mediaQueryVar === false ? "30px" : "",
                        fontSize: mediaQueryVar === false ? "10px" : "11px",
                      }}
                      color="primary"
                    >
                      View Portfolio
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          ) : (
            <Dialog
              PaperProps={{
                sx: {
                  position: mediaQueryVar ? "static" : "fixed",
                  bottom: mediaQueryVar ? "" : 0,
                  left: mediaQueryVar ? "" : 0,
                  right: mediaQueryVar ? "" : 0,
                  marginLeft: mediaQueryVar ? "" : "0px",
                  marginRight: mediaQueryVar ? "" : "0px",
                  marginTop: "1%",
                  maxWidth: "100%",
                  minHeight: mediaQueryVar ? "60%" : "", // Adjust max height if needed
                  borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
                  marginBottom: mediaQueryVar ? "" : "0px",
                  padding: mediaQueryVar ? "10px" : "1px",
                  alignItems: "center",
                  overflow: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "-ms-overflow-style": "none",
                  "scrollbar-width": "none", // Ensure no scrollbar
                },
              }}
              open={open}
              onClose={handleClose}
            >
              <div
                style={{
                  padding: "20px",
                  width: mediaQueryVar ? "400px" : "100%",
                  margin: "0",
                  marginTop: "-5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                    borderBottom: "1px #E3E3E3 solid",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "500",
                      fontSize: "20.767px",
                      paddingLeft: "100px",
                    }}
                    gutterBottom
                  >
                    Add To Portfolio
                  </Typography>

                  <div className="primaryButton">
                    <img
                      className="frameIcon"
                      alt=""
                      onClick={handleClose}
                      src={window.constants.asset_path + "/images/frame17.svg"}
                      style={{
                        background: "#F3F3F3",
                        width: "25px",
                        height: "25px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "5px",
                    marginLeft: mediaQueryVar === false ? "10px" : "",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "13.845px",
                      fontWeight: "500",
                      marginBottom: "1px",
                      color: "black",
                      marginTop: "-8px",
                    }}
                  >
                    Select Portfolio
                  </Typography>
                  <div style={{ marginTop: "5px" }}>
                    <CreatableSelect
                      isClearable
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "12px",
                          border: "1px solid #1877F2",
                          boxShadow: "none",
                          minHeight: "40px",
                          "&:hover": {
                            borderRadius: "12px",
                            border: "2px solid #1877F2",
                          },
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "8px",
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: "",
                        }),
                        dropdownIndicator: (provided) => ({
                          ...provided,
                          color: "#1877F2",
                          border: "1px solid #1877F2",
                          padding: "5px",
                          margin: "8px",
                          borderRadius: "3.786px",
                          "&:hover": {
                            color: "#1877F2",
                          },
                        }),
                        indicatorSeparator: (provided) => ({
                          display: "none",
                        }),
                      }}
                      placeholder=""
                      onChange={(options) =>
                        handleChange(options, slugpopupdata.slugname)
                      }
                      onCreateOption={handleCreate}
                      options={[
                        {
                          value: "",
                          label: "Create new portfolio",
                          _isNew_: true,
                        }, // Dynamic "create new option" item
                        ...options, // Existing options
                      ]}
                      value={selectedOption}
                    />
                  </div>
                  {showAlert && (
                    <Alert severity="error" sx={{ marginBottom: "10px" }}>
                      {errorMessage}
                    </Alert>
                  )}
                </div>
                <Typography
                  style={{
                    fontSize: "13.845px",
                    fontWeight: "500",
                    color: "#000",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  Usable Wallet Value: {Usablewallet}
                </Typography>
                <div
                  style={{
                    padding: mediaQueryVar ? "8px" : "5px",
                    background: "#F3F3F3",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "5px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flex: 0,
                      minWidth: 0,
                    }}
                  >
                    <img
                      style={{
                        marginRight: "10px",
                        border: "2px solid #000",
                        borderRadius: "19px",
                        height: "27.69px",
                        width: "27.69px",
                      }}
                      alt=""
                      src={slugpopupdata.logo}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        sx={{
                          fontWeight: "500",
                          marginBottom: "0px",
                          fontSize: "14px",
                          color: "#7F7F80",
                        }}
                      >
                        {slugpopupdata.slugname}
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "20%",
                      flex: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        marginBottom: "4px",
                        fontSize: "13px",
                        color: "#7F7F80",
                      }}
                    >
                      {" "}
                      Price
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12.114px",
                        fontWeight: "500",
                        color: "black",
                        margin: "0",
                        marginLeft: "5%",
                      }}
                    >
                      {slugpopupdata.pricetoday}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        marginBottom: "4px",
                        fontSize: "13px",
                        color: "#7F7F80",
                      }}
                    >
                      {" "}
                      Units
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12.114px",
                        fontWeight: "500",
                        color: "black",
                        margin: "0",
                      }}
                    >
                      {totalUnits}
                    </Typography>
                  </div>
                </div>
                <div className="card__container__row3">
                  <div style={{ marginLeft: "-5px" }}>
                    <p style={{ fontSize: "14px", fontWeight: "500" }}>
                      Enter number of Units
                    </p>
                    <input
                      style={{
                        backgroundColor: "white",
                        color: "black",
                        padding: "20px",
                        border: "1px solid #1877F2",
                        borderRadius: "10.384px",
                        width: mediaQueryVar ? "350px" : "100%",
                        height: "45px",
                        margin: 0,
                      }}
                      min="1"
                      max="10"
                      type="number"
                      placeholder=""
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    ></input>
                  </div>
                  <div>
                    $
                    {formatToNonZero(
                      unit *
                        parseFloat(
                          (slugpopupdata.pricetoday || "").replace(/^\$/, ""),
                        ),
                    )}
                  </div>
                  {user_role === "superuser" && (
                    <>
                      <div className="card__container__row3__r2">
                        <div style={{ marginLeft: "-5px" }}>
                          <p style={{ fontSize: "14px", fontWeight: "500" }}>
                            Buy/Sell Price($)
                          </p>
                          <input
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              padding: "20px",
                              border: "1px solid #1877F2",
                              borderRadius: "10.384px",
                              width: "345px",
                              height: "45px",
                            }}
                            className="card__container__row3__r2__input"
                            min="1"
                            max="10"
                            type="number"
                            placeholder="Enter Price"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          ></input>
                        </div>
                      </div>
                      <div style={{ marginBottom: "10px", marginLeft: "-5px" }}>
                        <p style={{ fontSize: "14px", fontWeight: "500" }}>
                          Date
                        </p>
                        <TextField
                          type="date"
                          value={selectedDate}
                          onChange={(e) => handleDateChange(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10.384px",
                              border: "1px solid #1877F2",
                              boxShadow: "none",
                              width: "190%",
                              height: "45px",
                            },
                          }}
                        />
                      </div>
                    </>
                  )}
                  <div
                    className="card__container__row3__r4"
                    style={{ marginTop: "10px" }}
                  >
                    <Button
                      className={
                        buySell === "buy"
                          ? "buttonToggleButActive"
                          : "buttonToggleBuy"
                      }
                      value="Buy"
                      style={{
                        lineHeight: "20px",
                        width: mediaQueryVar ? "159px" : "130px",
                        borderRadius: "27px",
                        border: "1px solid #1877F2",
                        padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                        marginLeft: "-6px",
                        backgroundColor: buySell === "buy" ? "" : "#1877F2",
                        color: buySell === "buy" ? "" : "white",
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleClick("buy")}
                    >
                      Buy
                    </Button>
                    <Button
                      className={
                        buySell === "sell"
                          ? "buttonToggleSellActive"
                          : "buttonToggleSell"
                      }
                      value="sell"
                      style={{
                        lineHeight: "20px",
                        width: mediaQueryVar ? "159px" : "130px",
                        borderRadius: "27px",
                        border: "1px solid #1877F2",
                        marginLeft: "10px",
                        padding: mediaQueryVar ? "15px 85px" : "13px 75px",
                        backgroundColor: buySell === "sell" ? "" : "#1877F2",
                        color: buySell === "sell" ? "" : "white",
                        textTransform: "none",
                        fontSize: "18px",
                        fontWeight: "700",
                      }}
                      onClick={() => handleClick("sell")}
                    >
                      Sell
                    </Button>
                  </div>
                </div>
              </div>
            </Dialog>
          )}
        </div>
      </Modal>

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Create New Portfolio
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsDialogOpen(false);
              setNewOptionValue("");
            }}
            sx={{ position: "absolute", right: 13, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            <TextField
              autoFocus
              label="Enter Name"
              value={newOptionValue}
              onChange={handleChange2}
              fullWidth
              margin="dense"
            />
            {showAlert && (
              <Alert severity="error" sx={{ marginTop: "8px" }}>
                Hyphens are not allowed in the name.
              </Alert>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ marginRight: "14px" }}>
          <Button
            onClick={() => {
              setIsDialogOpen(false);
              setNewOptionValue("");
            }}
            sx={{ fontWeight: "bold" }}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDialogSubmit}
            variant="contained"
            color="primary"
            sx={{ fontWeight: "bold" }}
            disabled={!newOptionValue.trim()}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbaropen}
        autoHideDuration={5000}
        onClose={closesnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
      </Snackbar>

      <Grid item xs={12} align="left">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // Changed from space-between to flex-end
            marginTop: "0px",
            marginBottom: "10px",
            marginLeft: "5px",
          }}
        >
          <EmailPopUp />
          {mediaQueryVar === false ? (
            <img
              onClick={() => {
                navigate("/");
              }}
              src={window.constants.asset_path + "/images/cwbeta.webp"}
              alt="Logo"
              className="button-logo"
            />
          ) : null}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <img
              src="/images/menubar.svg"
              alt="Menu Icon"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "10px",
                marginTop: "10px",
              }}
            />
          </IconButton>
        </div>
      </Grid>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "400",
            backgroundColor: "white",
            color: "#727376",
          },
        }}
      >
        {drawer}
      </Drawer>
      {mediaQueryVar ? null : (
        <Box
          sx={{
            backgroundColor: "#1877F2", // Background color for the container
            padding: "3.105px", // Padding inside the container
            borderRadius: "38.042px", // Border radius for the container
            display: "flex", // Ensure that the box is flexible
            alignItems: "center", // Center the content vertically
            display: "inline-block",
            minWidth: isPredictionDataLoaded
              ? mediaQueryVar2
                ? "320px"
                : "352px"
              : mediaQueryVar2
                ? "280px"
                : "307px",

            height: "37px",
            border: "none",
            marginLeft: "0px",
            marginTop: "2%",
            marginBottom: "5%",
          }}
        >
          <Tabs
            value={selectedButton}
            onChange={(event, newValue) => handleButtonClick(newValue)}
            aria-label="basic tabs example"
            sx={{
              flexGrow: 1, // Make the tabs take up remaining space
              "& .MuiTabs-indicator": {
                display: "none",
              },
              display: "flex", // Ensure that the tabs are displayed in a row
              overflow: "hidden", // Prevent the tabs from spilling out of the border radius
            }}
          >
            <Tab
              label={
                isPredictionDataLoaded
                  ? `${slug} Price Analysis`
                  : "Price Analysis"
              }
              value="CoinPrices"
              sx={{
                whiteSpace: "nowrap",
                borderRadius: "20.962px",
                minHeight: "31px",
                height: "31px",
                marginLeft: "1.5px",
                marginTop: "0.2px",
                border: "1px solid black",
                fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
                marginRight: "0px",
                fontSize: mediaQueryVar2 ? "13px" : "15px",
                paddingRight: "-8px",
                textTransform: "none",
                backgroundColor:
                  selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
                color:
                  selectedButton === "CoinPrices"
                    ? "#000000 !important"
                    : "#FFFFFF", // Black text for selected tab
                border: "none", // Remove border for tabs
              }}
            />
            <Tab
              label="Predict and Win"
              value="PortfolioGallery"
              component={Link}
              to={url}
              sx={{
                whiteSpace: "nowrap",
                borderRadius: "20.962px",
                minHeight: "31.5px",
                height: "31.5px",
                fontWeight:
                  selectedButton === "PortfolioGallery" ? "700" : "500",
                marginLeft: "0.5px",
                paddingLeft: "-15px",
                marginTop: "0.5px",
                border: "1px solid black",
                marginBottom: "-5px",
                fontSize: mediaQueryVar2 ? "13px" : "15px",
                marginRight: "-10px",
                backgroundColor:
                  selectedButton === "PortfolioGallery"
                    ? "#FFFFFF"
                    : "transparent", // White background for selected tab
                color:
                  selectedButton === "PortfolioGallery"
                    ? "#000000 !important"
                    : "#FFFFFF", // Black text for selected tab
                textTransform: "none",
                border: "none",
              }}
            />
          </Tabs>
        </Box>
      )}
      {mediaQueryVar ? null : !loader ? (
        <Box
          sx={{
            padding: "16px",
            backgroundColor: "#FFF",
            borderRadius: "8px",
            marginTop: "16px 0",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              backgroundColor: "#F9F9F9",
              borderRadius: "8px",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Skeleton
                sx={{ marginRight: "16px", borderRadius: "8px" }}
                variant="rectangular"
                width={40}
                height={40}
              />
              <Skeleton variant="text" width={200} height={30} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Skeleton variant="text" width="30%" height={30} />
              <Skeleton variant="text" width="30%" height={30} />
              <Skeleton variant="text" width="30%" height={30} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            marginBottom: "20px",
            overflowY: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Box
            sx={{
              padding: "0",
              backgroundColor: "white",
              borderRadius: "6px",
              marginTop: "15px",
              marginBottom: "10px",
              border: "1px solid #DADEDF",
              marginLeft: "3px",
              marginRight: "5px",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}
            >
              <img
                alt=""
                src={symbolLogo}
                width={40}
                height={40}
                style={{
                  borderRadius: "19px",
                  marginRight: "16px",
                  border: "2px solid #000",
                  width: "40px",
                  height: "40px",
                  margin: "8px",
                }}
              />
              <Typography
                variant="h6"
                style={{ fontSize: "18px", fontWeight: "500" }}
              >
                {slug}
                
              </Typography>
              <Typography
                variant="h6"
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  float: "right",
                  marginLeft: "auto",
                  marginRight: "10px",
                }}
              >
                ${currentPrice}
              </Typography>

              <span
                style={{
                  marginRight: "10px",
                  color: parseFloat(tfhrs) > 0 ? "green" : "red",
                }}
              >
                {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
              </span>
            </Box>
            <Box
              sx={{
                background: "#EBE9EB",
                borderRadius: "6px",
                padding: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "0px",
                  borderBottom: "2px #DFDFDF solid",
                }}
              >
                <Box sx={{ flex: 1, marginRight: "8px" }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "4px",
                      marginTop: "-5px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      7 Days:
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <span
                        style={{
                          color: parseFloat(sevenDays) > 0 ? "green" : "red",
                        }}
                      >
                        {parseFloat(sevenDays) > 0
                          ? `+${sevenDays}`
                          : sevenDays}
                      </span>
                    </span>
                  </div>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "4px",
                      marginTop: "-5px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginLeft: "30px",
                      }}
                    >
                      30 Days:
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      <span
                        style={{
                          color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                        }}
                      >
                        {parseFloat(thirtyDays) > 0
                          ? `+${thirtyDays}`
                          : thirtyDays}
                      </span>
                    </span>
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  marginBottom: "0px",
                  borderBottom: "2px #DFDFDF solid",
                }}
              >
                <Box sx={{ flex: 1, marginRight: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "4px",
                      marginBottom: "-5px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      12 Months:
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                        marginRight: "-15px",
                      }}
                    >
                      <span
                        style={{
                          color: parseFloat(yearDays) > 0 ? "green" : "red",
                        }}
                      >
                        {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                      </span>
                    </span>
                  </div>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "4px",
                      marginBottom: "-5px",
                    }}
                  ></div>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #DFDFDF",
                }}
              >
                <Box sx={{ flex: 1, marginRight: "8px" }}>
                  <div style={{ display: "flex", margin: "4px" }}>
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Buy Intent:
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      {buyPercent}
                    </span>
                  </div>
                </Box>
                <Box sx={{ flex: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "4px",
                      marginBottom: "-5px",
                      paddingBottom: "10px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginLeft: "30px",
                      }}
                    >
                      Best Portfolios:
                    </span>

                    <span
                      className="clickable"
                      onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                        marginLeft: mediaQueryVar1 ? "" : "-4.9vw",
                        marginRight: mediaQueryVar1 ? "" : "-3vw",
                        textDecoration: "underline",
                        textDecorationColor: "#1877F2", 
                        color: "black",
                      }}
                    >
                      {holdings}
                    </span>
                  </div>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ flex: 1, marginRight: "8px" }}>
                  <div
                    style={{
                      display: "flex",
                      margin: "4px",
                      marginBottom: "-5px",
                    }}
                  >
                    <span
                      style={{
                        textAlign: "left",
                        fontSize: "13px",
                        color: "#76787A",
                        fontWeight: "500",
                        marginRight: "10px",
                      }}
                    >
                      Market Cap:
                    </span>
                    <span
                      style={{
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                      }}
                    >
                      {marketCap}
                    </span>
                  </div>
                  
                </Box>
                <Button onClick={() => handleAddToPortfolio(slug)} style={{ marginLeft: "-10px",marginRight:"-20px" }}>
    <div
      style={{
        display: "inline-flex",
        border: "1px solid #1877F2",
        borderRadius: "50%",
        padding: "3px 4px",
        marginLeft:"-5px"
      }}
    >
      <img
        width="12px"
        src={window.constants.asset_path + '/images/plus.svg'}
        alt="Add"
      />
    </div>
  </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {mediaQueryVar
        ? null
        :
      evaluation_data && (
        
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  height: "15px",
                  width: "96%",
                  margin: "-10px 0% 30px 1%",
                }}
              ></div>
      )}
      {mediaQueryVar
        ? null
        :
      evaluation_data && (
        <Box
        sx={{
          marginBottom: "30px",
          overflowY: "auto",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          marginTop:"-25px"
        }}
      >
        <Box
          sx={{
            padding: "0",

            backgroundColor: "white",
            borderRadius: "6px",
            marginTop: "15px",
            marginBottom: "10px",
            border: "1px solid #DADEDF",
            marginLeft: "3px",
            marginRight: "5px",
          }}
        >
          <Box
            sx={{
              background: "#EBE9EB",
              borderRadius: "6px",
              padding: "6px",
              paddingLeft: "15px",
              paddingRight: "20px",
            }}
          >
            {/* S3 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                borderBottom: "2px #DFDFDF solid",
                
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                  marginRight: "10px",
                }}
              >
                RSI:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "20%",
                }}
              >
                {renderAction(evaluation_data.rsi_evaluation)||'-'}
              </span>
            </Box>

            {/* S2 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                }}
              >
                MACD:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {renderAction(evaluation_data.macd_evaluation)||'-'}
              </span>
            </Box>

            {/* S1 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                  marginRight: "10px",
                }}
              >
                Heads and Shoulders:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "20%",
                }}
              >
                {renderAction(evaluation_data.head_shoulders)||'-'}
              </span>
            </Box>

            {/* P */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                }}
              >
                Bollinger Bands:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                {renderAction(evaluation_data.bbands_evaluation)||'-'}
              </span>
            </Box>

            {/* R1 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                  marginRight: "10px",
                }}
              >
                OBV:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: "20%",
                }}
              >
                {renderAction(evaluation_data.obv_evaluation)||'-'}
              </span>
            </Box>

            {/* R2 */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                }}
              >
                Fibonacci retracement:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: mediaQueryVar1 ? "" : "-4.9vw",
                  marginRight: mediaQueryVar1 ? "" : "0vw",
                }}
              >
                {renderAction(evaluation_data.fibonacci_evaluation)||'-'}
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "6px",
                paddingTop:"3px",
                borderBottom: "2px #DFDFDF solid",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                }}
              >
                Triangles:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: mediaQueryVar1 ? "" : "-4.9vw",
                  marginRight: mediaQueryVar1 ? "" : "0vw",
                }}
              >
                {renderAction(evaluation_data.triangles)||'-'}
              </span>
            </Box>

            {/* R3 */}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", paddingBottom: "2px",
                paddingTop:"3px", }}
            >
              <span
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#76787A",
                  fontWeight: "500",
                  marginRight: "10px",
                }}
              >
                Influencers:
              </span>
              <span
                style={{
                  textAlign: "right",
                  fontWeight: "500",
                  fontSize: "14px",
                  marginLeft: mediaQueryVar1 ? "19.5%" : "9.5%",
                }}
              >
                {renderAction(evaluation_data.hashtags)||'-'}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
      )
}
      {mediaQueryVar
        ? null
        : (hasOscillatorData || hasMovingAverageData || pivotdata) && (
            <div
              style={{
                backgroundColor: "#f2f2f2",
                height: "15px",
                width: "96%",
                margin: "-20px 0% -10px 1%",
              }}
            ></div>
          )}
      {mediaQueryVar
        ? null
        : (hasOscillatorData || hasMovingAverageData) && (
            <h5
              style={{
                margin: "4% 0 1% 0%",
                fontWeight: "bold",
                marginTop: "8%",
              }}
            >
              Technical Summary
            </h5>
          )}
      {mediaQueryVar
        ? null
        : pivotdata &&
          overallSummary && (
            <p style={{ margin: "4% 0 1% 1%" }}>
              In the next week, the overall sentiment for {slug} crypto price is{" "}
              {overallSummary}. The first level of support for {slug} is at{" "}
              {pivotdata.pivot_s1}&nbsp; while the first level of resistance for{" "}
              {slug} price is at {pivotdata.pivot_r1}.&nbsp;
              {sentimentStatement}
            </p>
          )}
      {mediaQueryVar ? null : (
        <div style={{ fontFamily: "Satoshi, sans-serif" }}>
          {(hasOscillatorData || hasMovingAverageData || pivotdata) && (
            <div
              style={{
                border: "1px solid #E2E2E4",
                margin: "20px 0% 20px 0%",
                padding: "10px 2px",
                borderRadius: "6px 6px 6px 6px",
              }}
            >
              {(hasOscillatorData || hasMovingAverageData) && (
                <div
                  style={{
                    width: "98%",
                    border: "1px #DFDFDF solid",
                    borderRadius: "6px",
                    margin: "-6px 0px 20px 3px",
                    overflow: "hidden",
                    backgroundColor: "#EBE9EB",
                  }}
                >
                  <table
                    style={{
                      fontSize: "16px",
                      width: "100%",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                      gap: "1px",
                    }}
                  >
                    <thead style={{ display: "contents" }}>
                      <tr style={{ display: "contents" }}>
                        <th
                          colSpan="3"
                          style={{
                            fontWeight: "500",
                            textAlign: "left",
                            backgroundColor: "#fff",
                            padding: "8px",
                            fontSize: "18px",
                            gridColumn: "span 3",
                            borderBottom: "2px #DFDFDF solid",
                          }}
                        >
                          Summary:
                        </th>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "8px",
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            gridColumn: "span 2",
                            textAlign: "left",
                            borderBottom: "2px #DFDFDF solid",
                            marginRight: "0px",
                            marginLeft: "-30vw",
                            backgroundColor: "#fff",
                          }}
                        >
                          {renderAction(overallSummary)}
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "14px", display: "contents" }}>
                      {/* Technical Indicators Row */}
                      <tr style={{ display: "contents" }}>
                        <td
                          style={{
                            color: "#76787A",
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                          }}
                        >
                          Technical Indicators:
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            fontWeight: "500",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          {renderAction(oscillatorSummary)}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          Buy<br></br>({oscillatorBuys || "0"})
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          Sell<br></br>({oscillatorSells || "0"})
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            borderBottom: "2px #DFDFDF solid",
                          }}
                        >
                          Neutral<br></br>({oscillatorNeutrals || "0"})
                        </td>
                      </tr>

                      {/* Moving Averages Row */}
                      <tr style={{ display: "contents" }}>
                        <td
                          style={{
                            color: "#76787A",
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                          }}
                        >
                          Moving Averages:
                        </td>
                        <td
                          style={{
                            fontWeight: "500",
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          {renderAction(movingAverageSummary)}
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          Buy<br></br>({movingAverageBuys || "0"})
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            borderRight: "2px #DFDFDF solid",
                            borderBottom: "2px #DFDFDF solid",
                            textAlign: "center",
                          }}
                        >
                          Sell<br></br>({movingAverageSells || "0"})
                        </td>
                        <td
                          style={{
                            padding: "8px",
                            textAlign: "center",
                            borderBottom: "2px #DFDFDF solid",
                          }}
                        >
                          Neutral<br></br>({movingAverageNeutrals || "0"})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}

              <h5
                style={{
                  textAlign: "right",
                  fontStyle: "italic",
                  margin: "1% 1% 3% 1%",
                  fontSize: "12px",
                }}
              >
                {timestamp}&nbsp;UTC
              </h5>

              {oscillatorData.length > 0 && (
                <>
                  {/* Check if either RSI or MACD is present */}
                  {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                    <>
                      {oscillatorData[0].RSI && (
                        <p style={{ fontSize: "16px", margin: "0% 2% 0% 0.5%" }}>
                          The RSI value is at {oscillatorData[0].RSI}, which
                          indicates that the {slug} market is in a{" "}
                          {oscillatorData[0].rsi_evaluation} position.
                        </p>
                      )}

                      {oscillatorData[4].MACD && (
  <p
    style={{
      fontSize: "16px",
      margin: "2% 1% 0% 0.5%",
    }}
  >
    The MACD value is {oscillatorData[4].MACD}.{" "}
    {oscillatorData[4].macd_evaluation === "Buy" ? (
      <>
        The MACD line is above the signal line, and the green histogram bars
        have started to increase on the positive axis. This indicates that{" "}
        {slug} is in a buy position.
      </>
    ) : (
      <>
        The MACD line is below the signal line, and the red histogram bars have
        started to increase on the negative axis. This indicates that {slug} is
        in a sell position.
      </>
    )}
    <br />
    <br />
  </p>
)}

                    </>
                  )}
                </>
              )}

              {movingAveragesData.length > 0 && (
                <>
                  {(movingAveragesData[0]["SMA (10)"] ||
                    movingAveragesData[4]["EMA (10)"] ||
                    movingAveragesData[1]["SMA (50)"] ||
                    movingAveragesData[5]["EMA (50)"] ||
                    movingAveragesData[2]["SMA (100)"] ||
                    movingAveragesData[3]["SMA (200)"] ||
                    movingAveragesData[6]["EMA (100)"] ||
                    movingAveragesData[7]["EMA (200)"]) && (
                    <>
                      <p
                        style={{
                          fontSize: "16px",
                          margin: "2% 1% 0% 0.5%",
                          marginBottom: "10px",
                        }}
                      >
                        {movingAveragesData[0]["SMA (10)"] && (
                          <>
                            For {slug}, the 10-day SMA is{" "}
                            {movingAveragesData[0]["SMA (10)"]}. As the price of{" "}
                            {slug} is{" "}
                            {currentPrice < movingAveragesData[0]["SMA (10)"]
                              ? "below"
                              : "above"}{" "}
                            the 10-day SMA, it is{" "}
                            {currentPrice < movingAveragesData[0]["SMA (10)"]
                              ? "bearish"
                              : "bullish"}
                            . The short-term outlook is{" "}
                            {currentPrice < movingAveragesData[0]["SMA (10)"]
                              ? "negative"
                              : "positive"}
                            .
                          </>
                        )}

                        {movingAveragesData[1]["SMA (50)"] && (
                          <>
                            &nbsp;Additionally, the 50-day SMA is{" "}
                            {movingAveragesData[1]["SMA (50)"]}. {slug} is{" "}
                            {currentPrice < movingAveragesData[1]["SMA (50)"]
                              ? "below"
                              : "above"}{" "}
                            its 50-day SMA, it is{" "}
                            {currentPrice < movingAveragesData[1]["SMA (50)"]
                              ? "bearish"
                              : "bullish"}
                            .
                          </>
                        )}

                        {(movingAveragesData[2]["SMA (100)"] ||
                          movingAveragesData[3]["SMA (200)"]) && (
                          <>
                            &nbsp;The 100-day and 200-day SMAs representing the
                            long-term outlook of {slug} are&nbsp;
                            {movingAveragesData[2]["SMA (100)"] || "-"} and{" "}
                            {movingAveragesData[3]["SMA (200)"] || "-"}{" "}
                            respectively. Currently, the price of {slug} is{" "}
                            {currentPrice <
                              movingAveragesData[2]["SMA (100)"] &&
                            currentPrice < movingAveragesData[3]["SMA (200)"]
                              ? "below"
                              : "above"}{" "}
                            both the 100-day and 200-day Moving Averages. It is{" "}
                            {currentPrice <
                              movingAveragesData[2]["SMA (100)"] &&
                            currentPrice < movingAveragesData[3]["SMA (200)"]
                              ? "bearish"
                              : "bullish"}
                            .
                          </>
                        )}
                      </p>
                    </>
                  )}
                </>
              )}

              {(hasOscillatorData || hasMovingAverageData) && pivotdata && (
                <div
                  style={{
                    backgroundColor: "#f2f2f2",
                    height: "15px",
                    width: "99.8%",
                    margin: "15px 0.2% 0 0%",
                  }}
                ></div>
              )}
              {pivotdata && (
                <h5 style={{ margin: "7% 0 2% 2%", fontWeight: "bold" }}>
                  Pivot Points
                </h5>
              )}
              <Box
                sx={{
                  marginBottom: "0px",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": { display: "none" },
                  scrollbarWidth: "none",
                }}
              >
                <Box
                  sx={{
                    padding: "0",

                    backgroundColor: "white",
                    borderRadius: "6px",
                    marginTop: "15px",
                    marginBottom: "10px",
                    border: "1px solid #DADEDF",
                    marginLeft: "3px",
                    marginRight: "5px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#EBE9EB",
                      borderRadius: "6px",
                      padding: "10px",
                      paddingLeft: "15px",
                      paddingRight: "20px",
                    }}
                  >
                    {/* S3 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                          marginRight: "10px",
                        }}
                      >
                        S3:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                          marginLeft: "20%",
                        }}
                      >
                        {pivotdata.pivot_s3}
                      </span>
                    </Box>

                    {/* S2 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                        }}
                      >
                        S2:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {pivotdata.pivot_s2}
                      </span>
                    </Box>

                    {/* S1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                          marginRight: "10px",
                        }}
                      >
                        S1:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                          marginLeft: "20%",
                        }}
                      >
                        {pivotdata.pivot_s1}
                      </span>
                    </Box>

                    {/* P */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                          fontWeight: "bold",
                        }}
                      >
                        P:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        {pivotdata.pivot_p}
                      </span>
                    </Box>

                    {/* R1 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                          marginRight: "10px",
                        }}
                      >
                        R1:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                          marginLeft: "20%",
                        }}
                      >
                        {pivotdata.pivot_r1}
                      </span>
                    </Box>

                    {/* R2 */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "2px #DFDFDF solid",
                      }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                        }}
                      >
                        R2:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                          marginLeft: mediaQueryVar1 ? "" : "-4.9vw",
                          marginRight: mediaQueryVar1 ? "" : "0vw",
                        }}
                      >
                        {pivotdata.pivot_r2}
                      </span>
                    </Box>

                    {/* R3 */}
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <span
                        style={{
                          textAlign: "left",
                          fontSize: "13px",
                          color: "#76787A",
                          fontWeight: "500",
                          marginRight: "10px",
                        }}
                      >
                        R3:
                      </span>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "14px",
                          marginLeft: mediaQueryVar1 ? "19.5%" : "9.5%",
                        }}
                      >
                        {pivotdata.pivot_r3}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </div>
          )}
         

          <div
            style={{
              backgroundColor: "#f2f2f2",
              height: "15px",
              width: "99.8%",
              margin: "15px 0.2% 0 0%",
            }}
          ></div>
        </div>
      )}
      
      {mediaQueryVar ? null : (
        <div
          className="description"
          style={{
            fontFamily: "Satoshi, sans-serif!important",
            padding: "1%",
            borderRadius: "4px",
            marginBottom: "10%",
            marginTop: "0px",
            marginLeft: "0%",
            marginRight: "2%",
          }}
        >
          {processedDescription ? (
            <div dangerouslySetInnerHTML={{ __html: processedDescription }} />
          ) : (
            <p></p> // or any other placeholder text
          )}
        </div>
      )}

      {mediaQueryVar ? (
        <div className="layout">
          <div className>
            <Box
              component="nav"
              sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
              aria-label="mailbox folders"
            >
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    backgroundColor: "white",
                    color: "#727376",
                  },
                }}
              >
                {drawer}
              </Drawer>
              <Drawer
                variant="permanent"
                sx={{
                  display: { xs: "none", sm: "block" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    backgroundColor: "white",
                    color: "#727376",
                  },
                }}
                open
              >
                {drawer}
              </Drawer>
            </Box>
          </div>

          <div
            className="content"
            style={{
              backgroundColor: "white",
              margin: "0%",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#1877F2", // Background color for the container
                paddingTop: { xs: "10px", sm: "4px" }, // Padding inside the container
                paddingLeft: { xs: "0px", sm: "0px" },
                paddingRight: { xs: "0px", sm: "0px" },
                paddingBottom: { xs: "0px", sm: "4px" },
                borderRadius: "50px", // Border radius for the container
                display: "flex", // Make sure the box is flexible
                justifyContent: "flex-start", // Center the content
                alignItems: "center", // Center the content
                display: "inline-block",
                minWidth: isPredictionDataLoaded ? "410px" : "350px",
                height: { xs: "44px", sm: "48px" },
                border: "none",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            >
              <Tabs
                value={selectedButton}
                onChange={(event, newValue) => handleButtonClick(newValue)}
                aria-label="basic tabs example"
                sx={{
                  alignSelf: mediaQueryVar === true ? "flex-start" : "",
                  marginLeft: mediaQueryVar === true ? "2px" : "2px",
                  borderRadius: "50px", // Adjust border radius for more rounded corners
                  border: "none",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                  display: "flex", // Make sure the tabs are displayed in a row
                  overflow: "hidden", // Prevent the tabs from spilling out of the border radius
                }}
              >
                <Tab
                  label={
                    isPredictionDataLoaded
                      ? `${slug} Price Analysis`
                      : "Price Analysis"
                  }
                  value="CoinPrices"
                  sx={{
                    whiteSpace: "nowrap",
                    bottom: "0px",
                    marginTop: "0px",
                    marginBottom: "0px",
                    marginLeft: "3px",
                    marginRight: "3px",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    minHeight: "20px",
                    lineHeight: { xs: "36px", sm: "36px" },
                    borderRadius:
                      selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
                    textTransform: "none",
                    fontSize: "17px",
                    fontStyle: "normal",
                    fontWeight: selectedButton === "CoinPrices" ? "700" : "500",

                    backgroundColor:
                      selectedButton === "CoinPrices"
                        ? "#FFFFFF"
                        : "transparent", // White background for selected tab
                    color:
                      selectedButton === "CoinPrices"
                        ? "#000000 !important"
                        : "#FFFFFF", // Black text for selected tab
                    border: "none", // Remove border for tabs
                    background:
                      selectedButton === "CoinPrices"
                        ? "#FFFFFF"
                        : "transparent", // Blue background for unselected tab
                  }}
                />
                <Tab
                  label="Predict and Win"
                  value="PortfolioGallery"
                  component={Link}
                  to={url}
                  sx={{
                    whiteSpace: "nowrap",
                    bottom: "0px",
                    marginTop: "1.1px",
                    marginBottom: "0px",
                    marginLeft: "3px",
                    marginRight: "3px",
                    paddingBottom: "2px",
                    paddingTop: "0px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    minHeight: "20px",
                    lineHeight: { xs: "28px", sm: "36px" },
                    borderRadius:
                      selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
                    textTransform: "none",
                    fontSize: { xs: "13px", sm: "17px" },
                    fontStyle: "normal",
                    fontWeight:
                      selectedButton === "PortfolioGallery" ? "700" : "500",

                    backgroundColor:
                      selectedButton === "PortfolioGallery"
                        ? "#FFFFFF"
                        : "transparent", // White background for selected tab
                    color:
                      selectedButton === "PortfolioGallery"
                        ? "#000000 !important"
                        : "#FFFFFF", // Black text for selected tab
                    border: "none", // Remove border for tabs
                    background:
                      selectedButton === "PortfolioGallery"
                        ? "#FFFFFF"
                        : "transparent", // Blue background for unselected tab
                  }}
                />
              </Tabs>
            </Box>

            <TableContainer
              style={{
                width: "96%",
                overflowX: "hidden",
                border: "1px solid #DADEDF",
                borderRadius: "5px",
                padding: 0,
                marginTop: "2%",
                marginBottom: "2%",
                marginLeft: "2%",
              }}
            >
              <Table
                className="non_responsiveTable"
                aria-label="customized table"
                style={{ borderCollapse: "collapse" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{
                        borderRadius: "8px 0 0 8px",
                        border: 0,
                        padding: 0,
                        width: "30%",
                      }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "left",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 2px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        Crypto
                      </button>
                    </StyledTableCell>

                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        7 Days
                      </button>
                    </StyledTableCell>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        30 Days
                      </button>
                    </StyledTableCell>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        12 Months
                      </button>
                    </StyledTableCell>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        Buy Intent
                      </button>
                    </StyledTableCell>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 8px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        Best Portfolios
                      </button>
                    </StyledTableCell>
                    <StyledTableCell
                      className="cryptotableviewHeader"
                      style={{ padding: 0, width: "auto" }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        Market Cap
                      </button>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader === false ? (
                    <StyledTableRow>
                      {[...Array(7)].map((_, index) => (
                        <StyledTableCell key={index}>
                          <Stack>
                            <Skeleton
                              sx={{ marginTop: "10px" }}
                              variant="rounded"
                              height={40}
                            />
                          </Stack>
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ) : (
                    <StyledTableRow style={{ backgroundColor: "white" }}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          paddingLeft: "16px",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "auto",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {/* Left section with logo and name in a single line */}
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              width={35}
                              height={35}
                              style={{
                                borderRadius: "19px",
                                border: "2px solid black",
                                marginLeft: "-8px",
                              }}
                              alt=""
                              src={symbolLogo}
                            />
                            <span style={{ marginLeft: "8px" }}>{slug}</span>

                            <Button onClick={() => handleAddToPortfolio(slug)} style={{ marginLeft: "-10px",marginRight:"-20px" }}>
    <div
      style={{
        display: "inline-flex",
        border: "1px solid #1877F2",
        borderRadius: "50%",
        padding: "3px 4px",
        marginLeft:"-5px"
      }}
    >
      <img
        width="12px"
        src={window.constants.asset_path + '/images/plus.svg'}
        alt="Add"
      />
    </div>
  </Button>
                          </div>

                          {/* Right section with price and percentage in a single line */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "17px",
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontWeight: "500" }}>
                              ${currentPrice}
                            </span>
                            <span
                              style={{
                                color: parseFloat(tfhrs) > 0 ? "green" : "red",
                              }}
                            >
                              {parseFloat(tfhrs) > 0 ? `+${tfhrs}` : tfhrs}
                            </span>
                          </div>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        className="one"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "80px",
                        }}
                      >
                        <span
                          style={{
                            color: parseFloat(sevenDays) > 0 ? "green" : "red",
                          }}
                        >
                          {parseFloat(sevenDays) > 0
                            ? `+${sevenDays}`
                            : sevenDays}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        className="one"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "80px",
                        }}
                      >
                        <span
                          style={{
                            color: parseFloat(thirtyDays) > 0 ? "green" : "red",
                          }}
                        >
                          {parseFloat(thirtyDays) > 0
                            ? `+${thirtyDays}`
                            : thirtyDays}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        className="one"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "90px",
                        }}
                      >
                        <span
                          style={{
                            color: parseFloat(yearDays) > 0 ? "green" : "red",
                          }}
                        >
                          {parseFloat(yearDays) > 0 ? `+${yearDays}` : yearDays}
                        </span>
                      </StyledTableCell>
                      <StyledTableCell
                        className="one"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "80px",
                        }}
                      >
                        {buyPercent}
                      </StyledTableCell>
                      <StyledTableCell
                        className="clickable"
                        style={{
                          borderBottom: "none",
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "100px",
                        }}
                        onClick={() => navigate(`/crypto/${crypto}/portfolios`)}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            textDecorationColor: "#1877F2",
                            color: "black",
                          }}
                        >
                          {holdings}
                        </span>
                      </StyledTableCell>

                      <StyledTableCell
                        className="one"
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "8px",
                          paddingBottom: "8px",
                          borderRight: "1px solid #DADEDF",
                          width: "90px",
                        }}
                      >
                        {marketCap}
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {evaluation_data && (

             <div
            style={{
              backgroundColor: "#f2f2f2",
              height: "30px",
              width: "96%",
              margin: "0px 2% 0 2%",
            }}
            ></div>
            )}
{evaluation_data && (
  
            <TableContainer
            style={{
              width: "96%",
              overflowX: "hidden",
              border: "1px solid #DADEDF",
              borderRadius: "5px",
              padding: 0,
              margin: "3% 0 3% 2%",
            }}
          >
            <Table className="non_responsiveTable" aria-label="customized table" style={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  {['RSI', 'MACD', 'Heads and Shoulders', 'Bollinger Bands', 'OBV', 'Fibonacci retracement', 'Triangles','Influencers'].map((label, index) => (
                    <StyledTableCell
                      key={index}
                      className="cryptotableviewHeader"
                      style={{
                        padding: 0,
                        whiteSpace: "nowrap", // Prevent text wrapping
                        border: 0,
                      }}
                    >
                      <button
                        disabled
                        style={{
                          textAlign: "center",
                          color: "#727376",
                          fontSize: "16px",
                          width: "100%",
                          height: "100%",
                          border: "none",
                          background: "white",
                          padding: "12px 12px",
                          margin: "0px 0px 10px 2px",
                          boxShadow:
                            "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                        }}
                      >
                        {label}
                      </button>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {loader === false ? (
                  <StyledTableRow>
                    {[...Array(7)].map((_, index) => (
                      <StyledTableCell key={index}>
                        <Stack>
                          <Skeleton sx={{ marginTop: '8px' }} variant="rounded" height={40} />
                        </Stack>
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ) : (
                  <StyledTableRow style={{ backgroundColor: "white" }}>
                    {[
                      evaluation_data.rsi_evaluation,
                      evaluation_data.macd_evaluation,
                      evaluation_data.head_shoulders,
                      evaluation_data.bbands_evaluation,
                      evaluation_data.obv_evaluation,
                      evaluation_data.fibonacci_evaluation,
                      evaluation_data.triangles,
                      evaluation_data.hashtags,
                    ].map((data, index) => (
                      <StyledTableCell
                        key={index}
                        style={{
                          borderBottom: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          textAlign: "center",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          borderRight: "1px solid #DADEDF",
                        }}
                      >
                        {renderAction(data) || '-'}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          
)}
            {(hasOscillatorData || hasMovingAverageData || pivotdata) && (
              <div
                style={{
                  backgroundColor: "#f2f2f2",
                  height: "30px",
                  width: "96%",
                  margin: "0px 2% 0 2%",
                }}
              ></div>
            )}
            {(hasOscillatorData || hasMovingAverageData) && (
              <h5 style={{ margin: "2% 0 1% 2%", fontWeight: "bold" }}>
                Technical Summary
              </h5>
            )}
            {pivotdata && overallSummary && (
              <p style={{ margin: "2% 0 1% 2%" }}>
                In the next week, the overall sentiment for {slug} crypto price
                is {overallSummary}. The first level of support for {slug} is at{" "}
                {pivotdata.pivot_s1}&nbsp; while the first level of resistance
                for {slug} price is at {pivotdata.pivot_r1}.&nbsp;
                {sentimentStatement}
              </p>
            )}
            {(hasOscillatorData || hasMovingAverageData || pivotdata) && (
              <div
                style={{
                  paddingBottom: "10px",
                  fontFamily: "Satoshi, sans-serif",
                  border: "1px solid #E2E2E4",
                  margin: "20px 2% 40px 2%",
                  padding: "px 0px",
                  borderRadius: "6px 6px 6px 6px",
                }}
              >
                {(hasOscillatorData || hasMovingAverageData) && (
                  <table
                    style={{
                      fontSize: "16px",
                      width: "99%",
                      borderCollapse: "collapse",
                      margin: "5px 0% 20px 0.5%",
                      borderRadius: "5px",
                      border: "1px solid #E2E2E4",
                    }}
                  >
                    <thead>
                      <tr style={{ borderBottom: "1px solid #E2E2E4" }}>
                        <th
                          style={{
                            color: "#727376",
                            textAlign: "left",
                            backgroundColor: "#fff",
                            padding: "8px",
                            fontSize: "16px",
                            paddingLeft: "13px",
                            borderRight: "0px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          Summary
                        </th>
                        <td
                          style={{
                            padding: "8px",
                            fontSize: "16px",
                            paddingLeft: "14px",
                            fontWeight: "500",
                          }}
                        >
                          {renderAction(overallSummary)}
                        </td>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "16px" }}>
                      <tr
                        style={{
                          borderBottom: "1px solid #E2E2E4",
                          backgroundColor: "#F0F0F0",
                        }}
                      >
                        <td
                          style={{
                            width: "24.7%",
                            padding: "12px",
                            color: "#727376",
                            fontSize: "16px",
                            borderRight: "0px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          Technical Indicators
                        </td>
                        <td
                          style={{
                            width: "13vw",
                            padding: "12px",
                            borderRight: "2px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          {renderAction(oscillatorSummary)}
                        </td>
                        <td
                          style={{
                            width: "13vw",
                            padding: "12px",
                            borderRight: "2px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          Buy: ({oscillatorBuys || "0"})
                        </td>
                        <td
                          style={{
                            width: "13vw",
                            padding: "12px",
                            fontWeight: "500",
                            borderRight: "2px solid #E2E2E4",
                          }}
                        >
                          Sell: ({oscillatorSells || "0"})
                        </td>
                        <td
                          style={{
                            width: "13vw",
                            padding: "12px",
                            fontWeight: "500",
                          }}
                        >
                          Neutral: ({oscillatorNeutrals || "0"})
                        </td>
                      </tr>

                      <tr>
                        <td
                          style={{
                            width: "24.5%",
                            padding: "12px",
                            color: "#727376",
                            fontSize: "16px",
                            borderRight: "0px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          Moving Averages
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            borderRight: "2px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          {renderAction(movingAverageSummary)}
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            borderRight: "2px solid #E2E2E4",
                            fontWeight: "500",
                          }}
                        >
                          Buy: ({movingAverageBuys || "0"})
                        </td>
                        <td
                          style={{
                            padding: "12px",
                            fontWeight: "500",
                            borderRight: "2px solid #E2E2E4",
                          }}
                        >
                          Sell: ({movingAverageSells || "0"})
                        </td>
                        <td style={{ padding: "12px", fontWeight: "500" }}>
                          Neutral: ({movingAverageNeutrals || "0"})
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}

                <p
                  style={{
                    textAlign: "right",
                    fontStyle: "italic",
                    margin: "0 1%",
                    fontSize: "14px",
                    color: "#000",
                  }}
                >
                  {timestamp}&nbsp;UTC
                </p>
                {oscillatorData.length > 0 && (
                  <>
                    {/* Check if either RSI or MACD is present */}
                    {(oscillatorData[0].RSI || oscillatorData[4].MACD) && (
                      <>
                        {oscillatorData[0].RSI && (
                          <p
                            style={{
                              fontSize: "16px",
                              margin: "2% 1% 0% 0.5%",
                            }}
                          >
                            The RSI value is at {oscillatorData[0].RSI}, which
                            indicates that the {slug} market is in a{" "}
                            {oscillatorData[0].rsi_evaluation} position.
                          </p>
                        )}

{oscillatorData[4].MACD && (
  <p
    style={{
      fontSize: "16px",
      margin: "2% 1% 0% 0.5%",
    }}
  >
    The MACD value is {oscillatorData[4].MACD}.{" "}
    {oscillatorData[4].macd_evaluation === "Buy" ? (
      <>
        The MACD line is above the signal line, and the green histogram bars
        have started to increase on the positive axis. This indicates that{" "}
        {slug} is in a buy position.
      </>
    ) : (
      <>
        The MACD line is below the signal line, and the red histogram bars have
        started to increase on the negative axis. This indicates that {slug} is
        in a sell position.
      </>
    )}
    <br />
    <br />
  </p>
)}

                      </>
                    )}
                  </>
                )}

                {movingAveragesData.length > 0 && (
                  <>
                    {(movingAveragesData[0]["SMA (10)"] ||
                      movingAveragesData[4]["EMA (10)"] ||
                      movingAveragesData[1]["SMA (50)"] ||
                      movingAveragesData[5]["EMA (50)"] ||
                      movingAveragesData[2]["SMA (100)"] ||
                      movingAveragesData[3]["SMA (200)"] ||
                      movingAveragesData[6]["EMA (100)"] ||
                      movingAveragesData[7]["EMA (200)"]) && (
                      <>
                        <p
                          style={{
                            fontSize: "16px",
                            margin: "0px 1% 0px 0.5%",
                            marginBottom: "10px",
                          }}
                        >
                          {movingAveragesData[0]["SMA (10)"] && (
                            <>
                              For {slug}, the 10-day SMA is{" "}
                              {movingAveragesData[0]["SMA (10)"]}. As the price
                              of {slug} is{" "}
                              {currentPrice < movingAveragesData[0]["SMA (10)"]
                                ? "below"
                                : "above"}{" "}
                              the 10-day SMA, it is{" "}
                              {currentPrice < movingAveragesData[0]["SMA (10)"]
                                ? "bearish"
                                : "bullish"}
                              . The short-term outlook is{" "}
                              {currentPrice < movingAveragesData[0]["SMA (10)"]
                                ? "negative"
                                : "positive"}
                              .
                            </>
                          )}

                          {movingAveragesData[1]["SMA (50)"] && (
                            <>
                              &nbsp;Additionally, the 50-day SMA is{" "}
                              {movingAveragesData[1]["SMA (50)"]}. {slug} is{" "}
                              {currentPrice < movingAveragesData[1]["SMA (50)"]
                                ? "below"
                                : "above"}{" "}
                              its 50-day SMA, it is{" "}
                              {currentPrice < movingAveragesData[1]["SMA (50)"]
                                ? "bearish"
                                : "bullish"}
                              .
                            </>
                          )}

                          {(movingAveragesData[2]["SMA (100)"] ||
                            movingAveragesData[3]["SMA (200)"]) && (
                            <>
                              &nbsp;The 100-day and 200-day SMAs representing
                              the long-term outlook of {slug} are&nbsp;
                              {movingAveragesData[2]["SMA (100)"] ||
                                "-"} and{" "}
                              {movingAveragesData[3]["SMA (200)"] || "-"}{" "}
                              respectively. Currently, the price of {slug} is{" "}
                              {currentPrice <
                                movingAveragesData[2]["SMA (100)"] &&
                              currentPrice < movingAveragesData[3]["SMA (200)"]
                                ? "below"
                                : "above"}{" "}
                              both the 100-day and 200-day Moving Averages. It
                              is{" "}
                              {currentPrice <
                                movingAveragesData[2]["SMA (100)"] &&
                              currentPrice < movingAveragesData[3]["SMA (200)"]
                                ? "bearish"
                                : "bullish"}
                              .
                            </>
                          )}
                        </p>
                      </>
                    )}
                  </>
                )}
                {(hasOscillatorData || hasMovingAverageData) && pivotdata && (
                  <div
                    style={{
                      backgroundColor: "#f2f2f2",
                      height: "30px",
                      width: "99.8%",
                      margin: "15px 0.2% 0 0%",
                    }}
                  ></div>
                )}
                {pivotdata && (
                  <h5 style={{ margin: "2% 0 1% 0.4%", fontWeight: "bold" }}>
                    Pivot Points
                  </h5>
                )}
                {pivotdata && (
                  <TableContainer
                    style={{
                      width: "99%",
                      overflowX: "hidden",
                      border: "1px solid #DADEDF",
                      borderRadius: "5px",
                      padding: 0,

                      margin: "2.5% 0 2% 0.5%",
                    }}
                  >
                    <Table
                      className="non_responsiveTable"
                      aria-label="customized table"
                      style={{ borderCollapse: "collapse" }}
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{
                              borderRadius: "8px 0 0 8px",
                              border: 0,
                              padding: 0,
                              width: "12.5%", // Adjusted for equal width
                            }}
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 2px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              S3
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              S2
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              S1
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              P
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              R1
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              R2
                            </button>
                          </StyledTableCell>
                          <StyledTableCell
                            className="cryptotableviewHeader"
                            style={{ padding: 0, width: "12.5%" }} // Adjusted for equal width
                          >
                            <button
                              disabled
                              style={{
                                textAlign: "center",
                                color: "#727376",
                                fontSize: "16px",
                                width: "100%",
                                height: "100%",
                                border: "none",
                                background: "white",
                                padding: "12px 12px",
                                margin: "0px 0px 10px 2px",
                                boxShadow:
                                  "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)",
                              }}
                            >
                              R3
                            </button>
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loader === false ? (
                          <StyledTableRow>
                            {[...Array(7)].map((_, index) => (
                              <StyledTableCell key={index}>
                                <Stack>
                                  <Skeleton
                                    sx={{ marginTop: "10px" }}
                                    variant="rounded"
                                    height={40}
                                  />
                                </Stack>
                              </StyledTableCell>
                            ))}
                          </StyledTableRow>
                        ) : (
                          <StyledTableRow style={{ backgroundColor: "white" }}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                paddingLeft: "16px",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                                textAlign: "center",
                              }}
                            >
                              {pivotdata.pivot_s3}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_s2}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_s1}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_p}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_r1}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_r2}
                            </StyledTableCell>
                            <StyledTableCell
                              className="one"
                              style={{
                                borderBottom: "none",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                textAlign: "center",
                                paddingTop: "8px",
                                paddingBottom: "8px",
                                borderRight: "1px solid #DADEDF",
                                width: "auto",
                              }}
                            >
                              {pivotdata.pivot_r3}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>
            )}

          
            
            <div
              style={{
                backgroundColor: "#f2f2f2",
                height: "30px",
                width: "96%",
                margin: "10px 2% 10px 2%",
              }}
            ></div>
            <div
              className="description"
              style={{
                fontFamily: "Satoshi, sans-serif!important",
                padding: "1%",
                borderRadius: "4px",
                marginBottom: "4%",
                marginTop: "0px",
                marginLeft: "1%",
                marginRight: "1%",
              }}
            >
              {processedDescription ? (
                <div
                  dangerouslySetInnerHTML={{ __html: processedDescription }}
                />
              ) : (
                <p></p> // or any other placeholder text
              )}
            </div>

            {isPredictionDataLoaded === false && predictionLoader ? (
              <div
                style={{
                  border: "1px solid #B6B6B8",
                  margin: "0px 2%",
                  marginTop: "-35px",
                  textAlign: "center",
                  color: "black",
                  fontSize: "18px",
                  backgroundColor: "white",
                  padding: "2%",
                  borderRadius: "8px",
                  marginBottom: "30%",
                }}
              >
                No Predictions available
              </div>
            ) : (
              <div>
                {/* Search Row */}
                <h5 style={{ margin: "-50px 0 5% 2%", fontWeight: "bold" }}>
                  Price Forecasts
                </h5>
                <div className="search-sort-row">
                  <input
                    type="text"
                    placeholder="Search Forecaster"
                    className="search-input"
                    style={{
                      padding: "10px",
                      margin: "15px",
                      background: "#fff",
                      color: "black",
                    }}
                    onChange={handleSearchChange}
                  />
                </div>

                {/* Prediction Table */}
                <div>
                  <table className="predictions-table">
                    <thead>
                      <tr>
                        <th
                          onClick={() => handleSort("forecaster")}
                          style={{
                            cursor: "pointer",
                            textAlign: "left",
                            width: "15%",
                            fontWeight: "500",
                            color: "#727376",
                            fontSize: "16px",
                          }}
                        >
                          Forecaster{" "}
                          {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                        </th>
                        <th
                          onClick={() => handleSort("target")}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: "500",
                            color: "#727376",
                            fontSize: "16px",
                          }}
                        >
                          Target {sortOrder.target === "asc" ? "↑" : "↓"}
                        </th>
                        <th
                          onClick={() => handleSort("accuracy")}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: "500",
                            color: "#727376",
                            fontSize: "16px",
                          }}
                        >
                          Past Accuracy{" "}
                          {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                        </th>
                        <th
                          onClick={() => handleSort("percent")}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: "500",
                            color: "#727376",
                            fontSize: "16px",
                          }}
                        >
                          %Target Achieved{" "}
                          {sortOrder.percent === "asc" ? "↑" : "↓"}
                        </th>
                        <th
                          onClick={() => handleSort("targetDate")}
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: "500",
                            color: "#727376",
                            fontSize: "16px",
                          }}
                        >
                          Forecast For{" "}
                          {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    {isPredictionDataLoaded === false ? (
                      [...Array(10)].map((_, index) => (
                        <tr key={index}>
                          {[...Array(6)].map((_, cellIndex) => (
                            <td key={cellIndex}>
                              <Skeleton variant="rounded" height={40} />
                            </td>
                          ))}
                        </tr>
                      ))
                    ) : (
                      <tbody>
                        {sortedData.slice(0, rowsToShow).map((data, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                width: "23%",
                                background:
                                  "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                                borderBottom: "6px solid #fff",
                                padding: "5px 7px",
                                color: "#000",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginLeft: "5px",
                              }}
                            >
                              {data.forecaster_name}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontWeight: "500",
                                fontSize: "14px",
                                paddingLeft: "10px",
                              }}
                            >
                              {data.forecast_price}
                            </td>
                            <td
                              style={{ textAlign: "center" }}
                              onClick={() => openModal(data)}
                            >
                              <span
                                className={
                                  data.accuracy.toLowerCase() !== "pending"
                                    ? "clickable"
                                    : "pending"
                                }
                              >
                                {data.accuracy}
                              </span>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontWeight: "500",
                                fontSize: "14px",
                                paddingLeft: "10px",
                              }}
                            >
                              {data.target_percent}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontWeight: "500",
                                fontSize: "14px",
                                paddingLeft: "10px",
                              }}
                            >
                              {data.target_date}
                            </td>
                            <td>
                              <div className="dropdown">
                                <button
                                  style={{ width: "20px" }}
                                  onClick={() => toggleDropdown(index)}
                                  className="dropdown-button"
                                >
                                  <img
                                    src={
                                      window.constants.asset_path +
                                      "/images/more.png"
                                    }
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      marginRight: "5px",
                                    }}
                                    alt="More"
                                  />
                                </button>
                                {dropdownVisible === index && (
                                  <div className="dropdown-content">
                                    <a
                                      href={data.source_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <img
                                        src={
                                          window.constants.asset_path +
                                          "/images/external-link-symbol.png"
                                        }
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          marginRight: "5px",
                                        }}
                                        alt="Copy"
                                      />
                                      Source link
                                    </a>
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        ))}
                        {rowsToShow < sortedData.length && (
                          <StyledTableRow>
                            <StyledTableCell colSpan={7} align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <button
                                  onClick={handleLoadMore}
                                  className="load-more"
                                  style={{
                                    maxWidth: "360px",
                                    width: "80vw",
                                    backgroundColor: "rgba(67, 97, 238, 0.15)",
                                    borderRadius: "6px",
                                    border: "none",
                                    textTransform: "none",
                                    borderStyle: "solid",
                                    borderColor: "rgba(67, 97, 238, 0.15)",
                                    borderWidth: "1px",
                                    color: "#4361ee",
                                    height: "35px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Explore More
                                </button>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : isPredictionDataLoaded === false && predictionLoader ? (
        <div
          style={{
            border: "1px solid #B6B6B8",
            margin: "0px 2%",

            textAlign: "center",
            color: "black",
            fontSize: "18px",
            backgroundColor: "white",
            padding: "2%",
            borderRadius: "8px",
            marginBottom: "50%",
          }}
        >
          No Predictions available
        </div>
      ) : (
        <div>
          <h5 style={{ margin: "-20px 0 15% 1%", fontWeight: "bold" }}>
            Price Forecasts
          </h5>

          {/* Search Row */}
          <div className="search-sort-row">
            <input
              type="text"
              placeholder="Search Forecaster"
              className="search-input"
              style={{
                padding: "10px",
                margin: "15px",
                background: "#fff",
                color: "black",
              }}
              onChange={handleSearchChange}
            />
          </div>

          {/* Prediction Table */}
          <div>
            <table
              className="predictions-table"
              style={{ paddingBottom: "30%" }}
            >
              <thead>
                <tr>
                  <th
                    onClick={() => handleSort("forecaster")}
                    style={{
                      minWidth: mediaQueryVar1 === false ? "" : "150px",
                      textAlign: "left",
                      fontWeight: "500",
                      color: "#727376",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ marginRight: "2px" }}>Forecaster</span>
                    {sortOrder.forecaster === "asc" ? "↑" : "↓"}
                  </th>

                  <th
                    onClick={() => handleSort("target")}
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#727376",
                      fontSize: "16px",
                    }}
                  >
                    Target {sortOrder.target === "asc" ? "↑" : "↓"}
                  </th>

                  <th
                    onClick={() => handleSort("accuracy")}
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#727376",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ marginRight: "2px" }}>Past Accuracy</span>
                    {sortOrder.accuracy === "asc" ? "↑" : "↓"}
                  </th>

                  <th
                    onClick={() => handleSort("percent")}
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#727376",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ marginRight: "2px" }}>%Target Achieved</span>
                    {sortOrder.percent === "asc" ? "↑" : "↓"}
                  </th>

                  <th
                    onClick={() => handleSort("targetDate")}
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "#727376",
                      fontSize: "16px",
                    }}
                  >
                    <span style={{ marginRight: "2px" }}>Forecast For</span>
                    {sortOrder.targetDate === "asc" ? "↑" : "↓"}
                  </th>

                  <th></th>
                </tr>
              </thead>
              {isPredictionDataLoaded === false ? (
                [...Array(10)].map((_, index) => (
                  <tr key={index}>
                    {[...Array(6)].map((_, cellIndex) => (
                      <td key={cellIndex}>
                        <Skeleton variant="rounded" height={40} />
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tbody>
                  {sortedData.slice(0, rowsToShow).map((data, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          background:
                            "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
                          borderBottom: "6px solid #fff",
                          padding: "5px 7px",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginLeft: "5px",
                        }}
                      >
                        {data.forecaster_name}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "14px",
                          paddingLeft: "10px",
                        }}
                      >
                        {data.forecast_price}
                      </td>
                      <td
                        style={{ textAlign: "center" }}
                        onClick={() => openModal(data)}
                      >
                        <span
                          className={
                            data.accuracy.toLowerCase() !== "pending"
                              ? "clickable"
                              : "pending"
                          }
                        >
                          {data.accuracy}
                        </span>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "14px",
                          paddingLeft: "10px",
                        }}
                      >
                        {data.target_percent}
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          color: "black",
                          fontWeight: "500",
                          fontSize: "14px",
                          paddingLeft: "10px",
                        }}
                      >
                        {data.target_date}
                      </td>
                      <td>
                        <div className="dropdown">
                          <button
                            style={{ width: "20px" }}
                            onClick={() => toggleDropdown(index)}
                            className="dropdown-button"
                          >
                            <img
                              src={
                                window.constants.asset_path + "/images/more.png"
                              }
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                              alt="More"
                            />
                          </button>
                          {dropdownVisible === index && (
                            <div className="dropdown-content">
                              <a href={data.source_link} target="_blank" rel="noreferrer">
                                <img
                                  src={
                                    window.constants.asset_path +
                                    "/images/external-link-symbol.png"
                                  }
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "5px",
                                  }}
                                  alt="Copy"
                                />
                                Source link
                              </a>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {rowsToShow < sortedData.length && (
                    <StyledTableRow>
                      <StyledTableCell colSpan={7} align="center">
                        <div
                          style={{
                            position: "absolute",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          <button
                            onClick={handleLoadMore}
                            className="load-more"
                            style={{
                              backgroundColor: "rgba(67, 97, 238, 0.15)",
                              borderRadius: "6px",
                              border: "none",
                              textTransform: "none",
                              borderStyle: "solid",
                              borderColor: "rgba(67, 97, 238, 0.15)",
                              borderWidth: "1px",
                              color: "#4361ee",
                              height: "35px",
                              cursor: "pointer",
                            }}
                          >
                            Explore More
                          </button>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}
      {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginBottom: "0px",
            marginLeft: "-30px",
          }}
        >
          <BottomNavigation showLabels>
            <BottomNavigationAction
              label="Gallery"
              onClick={() => navigate("/portfolio-gallery")}
              icon={
                <img
                  src={
                    loc === "/portfolio-gallery"
                      ? "/images/dashboardS.svg"
                      : "/images/dashboardU.svg"
                  }
                  width={"20px"}
                  height={"20px"}
                />
              }
            />
            <BottomNavigationAction
              label="Crypto Screener"
              onClick={() => navigate("/crypto-screener")}
              icon={
                <img
                  src={
                    loc === "/crypto-screener"
                      ? "/images/portfolioS.svg"
                      : "/images/portfolioU.svg"
                  }
                  width={"20px"}
                  height={"20px"}
                />
              }
            />
            <BottomNavigationAction
              label={userData ? "Profile" : "Login/Signup"}
              onClick={() => {
                if (userData) {
                  navigate("/account");
                } else {
                  const originalUrl =
                    window.location.pathname + window.location.search;
                  sessionStorage.setItem("originalUrl", originalUrl);
                  console.log("original url: " + originalUrl);
                  handleLoginClick();
                }
              }}
              icon={
                <img
                  src={
                    userData
                      ? loc === "/account"
                        ? "/images/dollarS.svg"
                        : "/images/dollarU.svg"
                      : "/images/right-arrow.png"
                  }
                  width={24}
                  height={24}
                  alt="Icon"
                  style={{ marginBottom: "-3px" }}
                />
              }
            />
          </BottomNavigation>
        </div>
      )}
      {isLoginModalOpen && (
        <LoginPopUp
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      <Modal
        style={{
          overlay: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
            marginLeft: mediaQueryVar === true ? "20%" : "",
          },
          content: {
            align: "center",
            width: "80%",
            marginBottom: "20%",
            height: "auto",
            borderRadius: "8px",
            position: "center",
          },
        }}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Accuracy Details"
      >
        <img
          className="frameIcon"
          alt=""
          onClick={closeModal}
          src="/images/frame17.svg"
          style={{
            background: "#F3F3F3",
            width: "25px",
            height: "25px",
            marginTop: "1.5%",
            marginBottom: "1%",
          }}
        />
        <h4>Past Accuracy</h4>
        {modalData ? (
          <table className="modal-table">
            <thead>
              <tr>
                <th>Published On</th>
                <th>Forecast Date</th>
                <th style={{ textAlign: "center" }}>Forecast Price</th>
                <th style={{ textAlign: "center" }}>Accuracy</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((detail, index) => (
                <tr key={index}>
                  <td>{detail.publish_date}</td>
                  <td>{detail.target_date} 11:59 PM</td>
                  <td style={{ textAlign: "center" }}>
                    {detail.forecast_price}
                  </td>
                  <td style={{ textAlign: "center" }}>{detail.accuracy}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </Modal>
    </div>
  );
};

export default CryptoPage;
