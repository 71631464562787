import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Snackbar, Alert, Autocomplete } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { completeGoogleLogin } from "../apihelper/login";
import Box from "@mui/material/Box";
import Drawer from '@mui/material/Drawer';
import SidebarMenuList from "../includes/sidebarMenuList.jsx";
import './CryptoPage.css'; // Assuming you have a CSS file for styling
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import { addDays, format } from 'date-fns'
import IconButton from "@mui/material/IconButton";
import { Helmet } from 'react-helmet';



const drawerWidth = 292;

const CryptoForecastForm = (props) => {
  const { window1 } = props;
  const [name, setName] = useState("");
  const [targetPrice, setTargetPrice] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sourceUrl, setSourceUrl] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const container = window1 !== undefined ? () => window1().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [cryptoOptions, setCryptoOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  // Calculate the minimum date that can be selected (two days after today)
  const minDate = format(addDays(new Date(), 2), 'yyyy-MM-dd');
  const [mediaQueryVar1, setMediaQueryVar] = useState(window.innerWidth < 900);
  const loc = location.pathname;

  
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  
const drawer = (
  <div className='d-flex flex-column h-100' style={{overflowY:'hidden'}}>
<SidebarMenuList/>
  </div>
);

const handleDrawerToggle = () => {
  setMobileOpen(!mobileOpen);
};

useEffect(() => {
  const fetchCryptoOptions = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getCryptoSlug'); // Replace with your API endpoint
      const data = await response.json();
      if (data.symbols) {
        setCryptoOptions(data.symbols.map((item) => item.symbol));
      }
    } catch (error) {
      console.error("Error fetching crypto symbols", error);
    }
  };

  fetchCryptoOptions();
}, []);

const handleGoBack = () => {
  navigate(-1); // Go back to the previous page
};


const handleLoginRedirect = () => {
  const userId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.user_id;
  if (!userId) {
    const originalUrl = window.location.pathname + window.location.search;
    sessionStorage.setItem('originalUrl', originalUrl);
    console.log("original url"+originalUrl);
    navigate("/predict-now/login");
    return;
  }
};



const handleSubmit = async (e) => {
  e.preventDefault();

  if (!name || !targetPrice || !endDate) {
      setError("All fields are required.");
      return;
  }

  const userId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.user_id;
  const emailId = JSON.parse(localStorage.getItem("cw_portfolio_user"))?.email_id;
  if (!userId) {
      const originalUrl = window.location.pathname + window.location.search;
      sessionStorage.setItem('originalUrl', originalUrl);
      console.log("original url" + originalUrl);
      navigate("/predict-now/login");
      return;
  }

  try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/enterFormData', {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ name, targetPrice, endDate, emailId }),
      });
      const data = await response.json();
      if (data.code === '200') {
          setSuccessMessage("Prediction Added");
          setName("");
          setTargetPrice("");
          setEndDate("");
          setSourceUrl("");
          // Use setTimeout to delay the navigation until after the state update
          setTimeout(() => {
              navigate("/account");
          }, 2000);
      } else {
          setError("Failed to add prediction.");
      }
  } catch (err) {
      setError("An error occurred. Please try again.");
  }
};


  const handleInputChange = (event, newValue) => {
    setName(newValue);
    if (newValue) {
      setFilteredOptions(cryptoOptions.filter(option => option.toLowerCase().includes(newValue.toLowerCase())));
    } else {
      setFilteredOptions([]);
    }
  };

  useEffect(() => {
    const handleResize = () => setMediaQueryVar(window.innerWidth < 900);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    let timer;
    if (error || successMessage) {
      timer = setTimeout(() => {
        setError('');
        setSuccessMessage('');
      }, 6000); // 6000 milliseconds = 6 seconds

      const handleInteraction = () => {
        setError('');
        setSuccessMessage('');
      };

      window.addEventListener('click', handleInteraction);
      window.addEventListener('touchstart', handleInteraction);

      return () => {
        clearTimeout(timer);
        window.removeEventListener('click', handleInteraction);
        window.removeEventListener('touchstart', handleInteraction);
      };
    }
  }, [error, successMessage]);


  return (
    <div className="layout" 
    style=
    {{ 
      backgroundColor: '#EDF1F6', 
      minHeight: '100vh', 
      padding: '20px'
       }}>

<Helmet>
        <title>Predict Now</title>
</Helmet>
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'white', color: '#727376' },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>


    <div className="content">
    <div style={{display:"flex", justifyContent:"space-between"}}>
    <Button size="small" color="primary"onClick={handleGoBack} edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"4%",
              marginBottom:"10px",
              marginTop:"20px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
      </Button>
      <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
        >
            <img 
                src='/images/menubar.svg'
                alt="Menu Icon" 
                style={{ 
                    width: "24px", 
                    height: "24px",
                    marginRight: "10px",
                    marginTop: "10px" 
                }} 
            />
      </IconButton>
      </div>
      <div style={{ height: mediaQueryVar===true ? "" : "80%", marginTop:"5%",backgroundColor: 'white', borderRadius: '8px', padding: '32px', margin: mediaQueryVar===true ? "23px 40px 10px 40px" : "10px" , boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
  <Button disabled variant="contained" color="primary" style={{ color: "white",
    background: '#1877F2', textTransform: "none", width: 'auto', height: "10%", marginBottom: '20px', fontWeight: '500', fontSize: '15.575px', borderRadius: "25px" 
    ,marginTop: mediaQueryVar===true ? "" : "3%", marginBottom: mediaQueryVar===true ? "5%" : "",lineHeight:"1.3"
    }}>
    Predict and Win $wisd worth $5
  </Button>
  <form onSubmit={handleSubmit} style={{ marginTop: mediaQueryVar===true ? "" : "15%" }}>
  <Autocomplete
    freeSolo
    options={filteredOptions}
    value={name}
    onInputChange={handleInputChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label="Select Crypto"
        variant="outlined"
        onClick={handleLoginRedirect}
        required
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px', // Increase the border radius as needed
            '& fieldset': {
              borderColor: '#1877F2',
            },
            '&:hover fieldset': {
              borderColor: '#1877F2',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1877F2',
            },
          },
        }}
      />
    )}
  />
  <TextField
    label="Target Price in $"
    variant="outlined"
    fullWidth
    margin="normal"
    type="number"
    step="0.01"
    value={targetPrice}
    onClick={handleLoginRedirect}
    onChange={(e) => setTargetPrice(e.target.value)}
    required
    sx={{
      marginTop: mediaQueryVar===true ? "4%" : "14%",
      marginBottom: mediaQueryVar===true ? "4%" : "8%",
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px', // Increase the border radius as needed
        '& fieldset': {
          borderColor: '#1877F2',
        },
        '&:hover fieldset': {
          borderColor: '#1877F2',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1877F2',
        },
      },
    }}
  />
  <TextField
    label="End Date"
    variant="outlined"
    fullWidth
    margin="normal"
    type="date"
    InputLabelProps={{ shrink: true }}
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    onClick={handleLoginRedirect}
    required
    inputProps={{ min: minDate }} // Set the min date
    sx={{
      marginBottom: mediaQueryVar===true ? "2%" : "10%",
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        '& fieldset': {
          borderColor: '#1877F2',
        },
        '&:hover fieldset': {
          borderColor: '#1877F2',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#1877F2',
        },
      },
    }}
  />

  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Button
      type="submit"
      variant="contained"
      color="primary"
      style={{
        color: "white",
        background: '#1877F2',
        padding: "10px",
        textTransform: "none",
        width: mediaQueryVar == false ? '50%' : '14%',
        minHeight: mediaQueryVar == true ? "" : "50px",
        height: mediaQueryVar == true ? "" : "50px",
        marginBottom: mediaQueryVar == true ? '2%' : '',
        marginTop: mediaQueryVar == true ? '20px' : '5%',
        fontWeight: '700',
        fontSize: '18px',
        borderRadius: "23.363px"
      }}
    >
      Submit
    </Button>
  </div>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {error && (
          <Alert severity="error" sx={{
            border: '2px solid red',
            color: 'red',
            backgroundColor: '#ffe6e6',
            width: 'auto',
            textAlign: 'center',
            marginTop: mediaQueryVar == true ? '' : '15%',
          }}>
            {error}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{
            border: '2px solid green',
            color: 'green',
            backgroundColor: '#e6ffe6',
            width: 'auto',
            textAlign: 'center',
            marginTop: mediaQueryVar == true ? '' : '15%',
          }}>
            {successMessage}
          </Alert>
        )}
      </div>
</form>

      </div>
      
    </div>

    {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            marginLeft:"-6%",
            width: "105%",
            zIndex: "1000",
            marginLeft:"-20px"
          }}
        >
          <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Gallery"
        onClick={() =>{
          navigate("/portfolio-gallery");
        }
         
        }
        icon={<img src={loc === '/portfolio-gallery' ? `${window.constants.asset_path}/images/dashboardS.svg` : `${window.constants.asset_path}/images/dashboardU.svg`} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? `${window.constants.asset_path}/images/portfolioS.svg` : `${window.constants.asset_path}/images/portfolioU.svg`}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? `${window.constants.asset_path}/images/dollarS.svg`:`${window.constants.asset_path}/images/dollarU.svg`} width={22} height={22} alt="Icon" style={{marginBottom:"-3px"}}/>}
/>

      </BottomNavigation>
        </div>
      )}
  </div>
  );
};

export default CryptoForecastForm;
