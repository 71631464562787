import "./account.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import { EditText } from "react-edit-text";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Helmet } from 'react-helmet';
import { useState } from "react";
import { Button, Grid,Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { useNavigate } from "react-router-dom";
import { updateAccount } from "../../apihelper/profile";
import SidebarMenuList from "../../includes/sidebarMenuList";
import useMediaQuery from "@mui/material/useMediaQuery";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import json2mq from "json2mq";
import { Link } from 'react-router-dom';
import { getNormalUserData } from "../../apihelper/portfolio";
import { useEffect } from "react";
import { useLocation } from 'react-router-dom';



const drawerWidth = 292;

function Account(props) {
  const location = useLocation(); 
  console.log("current URL  : ",location.pathname);
  const loc = location.pathname;
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [redirectpage, setRedirectpage] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [severity, setSeverity] = React.useState("info");
  const [snackbarmessage, setSnackbarmessage] = React.useState("Loading...");
  const [fullName, setFullName] = React.useState("");
  const [profilePhoto, setProfilePhoto] = React.useState();
  const [userLocalData, setUserLocalData] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [gallarydata, setGallarydata] = useState([]);
  const [walletAddress, setWalletAddress] = useState(''); // State to store the input value
  const [portfolio_name, setPortfolio_Name] = useState('');
  const [portfolio_Id, setPortfolio_Id] = useState('');
  const [coin_data, setCoin_data] = useState('');

  const user = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  const userId = user.user_id;

  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbaropen(false);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
        if (!userData) {
          throw new Error("No user data found in local storage");
        }

        const user_idValue = userData.user_id;
        const user_token = userData.token;
        console.log("Minfluencer Name : ",user_idValue);
        // Fetch user data
        const userRes = await getNormalUserData({
          user_id: user_idValue,
          Token: user_token,
        });

        if (userRes.code === "200") {
        console.log("User data fetched successfully: ", userRes.data);
          const userData = userRes.data[0];
          setPortfolio_Name(userRes.data[0].portfolio_name);
          setPortfolio_Id(userRes.data[0].portfolio_id);
          setCoin_data(userRes.data[0].coins_count);
          setGallarydata(userRes.data);

        } else {
          console.log(userRes.message);
        }

      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);



console.log("Portfolio Name Default : ",portfolio_name);




  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleButtonClick = () => {
    setIsDialogOpen(true);
  };
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setProfilePhoto(file);
    console.log("photo: ", file);
  };
  console.log("profile Photo: ",profilePhoto);

  const handleButtonSaveClick = () => {
    setSnackbaropen(true);
    setSeverity("info")
    setSnackbarmessage("Loading...")
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(fullName,profilePhoto)
    const user_id=userData.user_id
    const token=userData.token

    updateAccount({user_id,fullName,profilePhoto,token}).then(res=>{
      if(res.code==="200"){
        setSnackbarmessage(res.message);
        userData.full_name = fullName; // Update the full name
        userData.photo = profilePhoto; // Update the profile image data
        userData.portfolio_name = res.portfolio_name;
        // Update other properties as needed

        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));
      }
      else{
        setSeverity("error")
        setSnackbarmessage(res.message);
      }
    })
;

    handleDialogClose();
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );


  const [youtubeLink, setYoutubeLink] = useState('');
  const [twitterLink, setTwitterLink] = useState('');
  const [telegramLink, setTelegramLink] = useState('');
  const [instagramLink, setInstagramLink] = useState('');

  const [isEditingYoutube, setIsEditingYoutube] = useState(false);
  const [isEditingTwitter, setIsEditingTwitter] = useState(false);
  const [isEditingTelegram, setIsEditingTelegram] = useState(false);
  const [isEditingInstagram, setIsEditingInstagram] = useState(false);


//youtube
  const handleYouTEditClick = (e) => {
    e.preventDefault();
    setIsEditingYoutube(true);
  };

  const [isSaved, setIsSaved] = useState(false);

  const handleInputChange = (e) => {
    setWalletAddress(e.target.value);
    setIsSaved(false); // Reset saved state on input change
  };

  const handleSave = () => {
    if (walletAddress) {
      // Logic to save the wallet address goes here.
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
        userData.full_name = fullName;
        userData.wallet_address = walletAddress;
       
        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

      }
      else{
        alert(res.message);
      }
    })
    setIsSaved(true); // Set saved state to true after saving

    if(coin_data>0){
      navigate(`/portfolio-gallery`);
    }
    else{
    const formattedPortfolioName = portfolio_name.replace(/\s+/g, '-');
    navigate(`/my-portfolio/${portfolio_Id}/${formattedPortfolioName}`);
    }
    
      
    }
  };

  
  const handleYouTSaveClick = (e) => {
    e.preventDefault();
    // Update the database with the new description (about)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
        //console.log("Data updated successfully: res ",res.data);
        //console.log("Data updated successfully: userdata",userData);

        userData.full_name = fullName;
        userData.youtube = youtubeLink;
        setYoutubeLink(youtubeLink);
        setIsEditingYoutube(false);
        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

      }
      else{
        alert(res.message);
      }
    })
   
  };
  
  const handleYouTChange = (e) => {
    setYoutubeLink(e.target.value);
  };
  


//twitter
const handleTwitterEditClick = (e) => {
  e.preventDefault();
  setIsEditingTwitter(true);
};

const handleTwitterSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    about: description,
    profilePhoto,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
    token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      //console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      userData.full_name = fullName;
      userData.twitter = twitterLink;
      setTwitterLink(twitterLink);
      setIsEditingTwitter(false);
      localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleTwitterChange = (e) => {
  setTwitterLink(e.target.value);
};


//instagram
const handleInstaEditClick = (e) => {
  e.preventDefault();
  setIsEditingInstagram(true);
};

const handleInstaSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    profilePhoto,
    about: description,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      userData.full_name = fullName;
      userData.instagram = instagramLink;
      setInstagramLink(instagramLink);
      setIsEditingInstagram(false);
      localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleInstaChange = (e) => {
  setInstagramLink(e.target.value);
};



//telegram
const handleTeleEditClick = (e) => {
  e.preventDefault();
  setIsEditingTelegram(true);
};

const handleTeleSaveClick = (e) => {
  e.preventDefault();
  // Update the database with the new description (about)
  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
  //console.log("User data: ",userData);
  const user_id=userData.user_id;
  const token=userData.token;

  updateAccount({
    user_id: user_id,
    fullName,
    profilePhoto,
    about: description,
    youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
    token : token,
  })
  .then(res=>{
    if(res.code==="200"){
      //console.log("Data updated successfully: res ",res.data);
      //console.log("Data updated successfully: userdata",userData);

      userData.full_name = fullName;
      userData.telegram = telegramLink;
      setTelegramLink(telegramLink);
      setIsEditingTelegram(false);
      localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

    }
    else{
      alert(res.message);
    }
  })
 
}; 


const handleTeleChange = (e) => {
  setTelegramLink(e.target.value);
};



  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    // Update the database with the new description (about)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log("User data: ",userData);
    const user_id=userData.user_id;
    const token=userData.token;

    updateAccount({
      user_id: user_id,
      fullName,
      profilePhoto,
      about: description,
      youtube: youtubeLink,
      twitter: twitterLink,
      telegram: telegramLink,
      instagram: instagramLink,
      wallet_address: walletAddress,
      token : token,
    })
    .then(res=>{
      if(res.code==="200"){
       // console.log("Data updated successfully: res ",res.data);
        //console.log("Data updated successfully: userdata",userData);

        userData.full_name = fullName;
        userData.about = description;
        setDescription(description);
        setIsEditing(false);
        localStorage.setItem('cw_portfolio_user', JSON.stringify(userData));

      }
      else{
        alert(res.message);
      }
    })
   
  }; 


  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };



  const container =
    window !== undefined ? () => window().document.body : undefined;

  React.useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    console.log("User data: ",userData);
    setUserLocalData(userData);
    if (userData == null) {
      setRedirectpage(true);
    } else {
      setFullName(userData.full_name);
      setDescription(userData.about);
      setYoutubeLink(userData.youtube);
      setTwitterLink(userData.twitter);
      setTelegramLink(userData.telegram);
      setInstagramLink(userData.instagram);
      setProfilePhoto(userData.photo);
      setWalletAddress(userData.wallet_address);
     // console.log("photo: ",profilePhoto);
    }
  }, []);

  if (redirectpage) {
    return navigate("/");
  }

  const generateReferralLink = () => {
    const formattedFullName = fullName.replace(/\s+/g, '-'); // Replace spaces with hyphens
    return `https://investing.crowdwisdom.live/signUp?ref=${formattedFullName}`;
  };
  

  return (
    <>
    {mediaQueryVar === true? (
    <Box sx={{ display: "flex", flexWrap: "wrap", backgroundColor:"#EDF1F6" }} className="main_account">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <div
          style={{
            backgroundColor: "#EDF1F6",
            padding: "5px",
            borderRadius: "10px",
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }} >
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily:"Satoshi, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    My Account
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
           
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"18px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%"
}}>

<div style={{
  gap: "20px",
  display: "inline-flex",
  padding:"5px 2px",
  borderRadius:"38px",
  paddingRight:"20px",
  marginBottom:"20px",
  background: "linear-gradient(90deg, #EEEEEE 0%, #F6F6F6 100%)"
  }}>

{profilePhoto ?(
  <img src={profilePhoto} alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
):(
  <img src="/images/avtar.jpeg" alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
)}

<div style={{
  justifyContent: "center", 
  alignItems: "center", 
  gap: "5px",
   display: "flex"
   }}>
<div style={{
  color: "black",
   fontSize: "20px",
   fontWeight: "600",
   marginLeft:"-5px",
   fontFamily:"Satoshu,sans-serif",
    wordWrap: "wordWrap"
    }}>{fullName}</div>
</div>
</div>
<Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '14px',
            fontWeight: '600',
            padding: '7px 13px',
            textDecoration: '',
            marginLeft:mediaQueryVar===false?"8px":"15px",
            marginTop:"-35px",
            height: mediaQueryVar===false ?"35px":"",

          }}
          onClick={handleButtonClick}
          onMouseDown={(e) => {
            // Change button styles on click
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            // Revert button styles when click is released
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}

        >
          <img src="/images/edit.svg" style={{
            marginRight:"5px"
          }}></img>
          Edit Profile
        </Button>

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={handleNameChange}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handlePhotoChange}
            style={{ display: 'none' }}
            id="profile-photo-upload"
          />
          <label htmlFor="profile-photo-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Photo
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleButtonSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={severity}
      />

        <div style={{width:"100%",border:"1px solid #EEEEEE",height:"1px",marginBottom:"20px"}}></div>

              <div class="container" style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "20px",
              marginBottom: "40px",
              marginLeft:"-10px",
             
              marginTop:"5px",
              marginRight:"0px",
              width:"102%"
              
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height:"103px",
      minHeight:"100.748px",
      //width:"255px",
      
      flex: "1 1 calc(20% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}> 0
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Total Wisecoins</div>
      </div>

      <img style={{

        marginTop:"40px"
      }}
      
      src="/images/wise.png"  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
     
      flex: "1 1 calc(20% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>0
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px",
          marginRight:"5px"
        }}>Earned Wisecoins</div>
      </div>
      <img style={{

marginTop:"50px"
}}src="/images/wise.png"  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      
      justifyContent: "space-between",
      flex: "1 1 calc(20% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}>0
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Spent Wisecoins</div>
      </div>
      <img style={{marginTop:"50px"}}src="/images/wise.png"  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      padding: "20px",
      borderRadius: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"103px",
      minHeight:"100.748px",
      flex: "1 1 calc(20% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: " 0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "23px",
          fontWeight: "700",
          color : "black"
        }}> 0 </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Current Balance</div>
      </div>
      <img style={{marginTop:"50px"}}src="/images/wise.png"  alt="wallet" class="icon"/>
    </div>
  </div>

  <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      maxWidth: '400px', 
      margin: '0 0px',
      marginBottom: '10px' 
    }}>
      <label 
        htmlFor="userInput" 
        style={{ 
          marginBottom: '8px', 
          fontSize: '16px', 
          color: '#333',
        }}
      >
        <span style={{
          fontSize: '18px',
          fontWeight: '600',
          fontFamily: 'Satoshi, sans-serif',
        }}>
          Solana Wallet Address
        </span>
        <Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 13px',
            marginLeft: '10px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={handleSave}
        >
          Save
        </Button>
       
      </label>

      

      <input
        type="text"
        id="userInput"
        placeholder="Enter your Wallet Address Here"
        value={walletAddress}
        onChange={handleInputChange} 
        style={{
          background:'white',
          color:'black',
          padding: '10px',
          border: '2px solid #1877F2',
          borderRadius: '15px',
          fontSize: '16px',
          width: '100%',
          marginBottom:"1%",
          boxSizing: 'border-box',
        }}
      />
      
     

      {isSaved && (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginTop: '10px', 
          color: 'green',
          fontFamily: 'Satoshi, sans-serif',
        }}>
          <span style={{ marginRight: '8px' }}>✔</span>
          <span>Your wallet address has been saved!</span>
        </div>
      )}
    </div>  
    <Button
          variant="outlined"
          style={{
            backgroundColor: '#1877F2',
            color: 'white',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 16px',
            marginLeft: '5px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={() => navigate('/airdrop-form')} 
        >
         Read about CW360 Airdrop
        </Button>


        <div style={{
    backgroundColor: '#F8F9FA',
    padding: '25px',
    borderRadius: '12px',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '20px',
    border: '1px solid #E0E0E0',
    position: 'relative',
    overflow: 'hidden'
  }}>
  <h3 style={{
    fontSize: '20px',
    fontFamily: 'Satoshi, sans-serif',
    fontWeight: '700',
    color: '#444',
    marginBottom: '20px'
  }}>
    Share Your Referral Link
  </h3>
  <input
    type="text"
    value={generateReferralLink()}
    readOnly
    style={{
      width: '75%',
      padding: '12px',
      border: 'none',
      borderRadius: '25px',
      fontSize: '16px',
      color: '#333',
      marginBottom: '20px',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      transition: 'box-shadow 0.3s ease',
      marginRight:'20px'
    }}
    onFocus={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'}
    onBlur={(e) => e.target.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.1)'}
  />
  <Button
    variant="contained"
    color="primary"
    style={{
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      borderRadius: '50px',
      padding: '12px 24px',
      fontWeight: '700',
      textTransform: 'none',
      fontSize: '15px',
      fontFamily: 'Satoshi, sans-serif',
      marginRight: '15px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
    }}
    onClick={() => navigator.clipboard.writeText(generateReferralLink())}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#0056b3';
      e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.2)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = '#007BFF';
      e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.15)';
    }}
  >
    Copy Link
  </Button>
  <img
    src="/images/referral-decorative.svg"
    alt="Decorative"
    style={{
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '150px',
      height: '150px',
      opacity: '0.1',
      pointerEvents: 'none'
    }}
  />
</div>

 </Box>
 <Box style ={{
 backgroundColor:"white",
 marginTop:"25px",
 marginLeft:"18px",
 marginRight:"18px",
 padding:"32px",
 borderRadius:"10px",
 width:"100%"
}}>
  
  <div style={{ marginBottom: '15px' }}>
      <div>
        <Typography
          style={{
            display: 'inline-flex',
            fontSize: '18px',
            fontWeight: '600',
            fontFamily: 'Satoshi, sans-serif',
          }}
        >
          Description
        </Typography>

        <Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 13px',
            marginLeft: '10px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={isEditing ? handleSaveClick : handleEditClick}
        >
          <img
            src="/images/edit.svg"
            style={{
              marginRight: '5px',
            }}
            alt="Edit"
          />
          {isEditing ? 'Save' : 'Edit'}
        </Button>

        {isEditing ? (
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            style={{
              marginTop: '10px',
              fontFamily: 'Satoshi, sans-serif',
              fontSize: '16px',
              fontWeight: '500',
            }}
          />
        ) : (
          <Typography
            style={{
              color: '#7F7F80',
              fontSize: '16px',
              fontWeight: '500',
              fontFamily: 'Satoshi, sans-serif',
              wordWrap: 'break-word',
              marginTop: '10px',
            }}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
              



      <div style={{ marginBottom: "10px" }}>
      <div>
        <Typography
          style={{
            fontSize: "17px",
            color: "black",
            fontWeight: "600",
            fontFamily: "Satoshi, sans-serif",
          }}
        >
          Tags
        </Typography>
        <div>
          <div
            className="wrapper1"
            style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              gallarydata.length > 0 &&
              gallarydata[0].slugs.map((slug, index) => (
                <div
                  key={index}
                  className={`tag${index + 1}`}
                  style={{
                    gap: "20px",
                    display: "flex",
                    padding: "5px 2px",
                    borderRadius: "38px",
                    paddingRight: "5px",
                    marginBottom: "10px",
                    background: "#F7F7F7",
                    marginRight: "10px",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                      marginTop: "0px",
                    }}
                  >
                    {slug.category}
                  </div>
                  <div
                    style={{
                      padding: "4px 12px",
                      borderRadius: "48px",
                      backgroundColor: "#1877F2" ,
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <img
                      src="/images/tag.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        marginBottom: "2px",
                      }}
                      alt="Remove"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
   
                <div>
                  <div>
                    <Typography style={{
                      fontSize:"17px",
                      color:"black",
                      fontWeight:"500",
                      fontFamily:"Satoshi,sans-serif"
                    }}>Social Profile</Typography>
                    <div>

                    
      <div className="wrapper1" style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
      {/* YouTube Block */}
      <div>
        <img src="/images/youtube.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div
  className="socialBtn1"
  style={{
    width: "200px", gap: "0px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
    marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
  }}>
  <div
    style={{
      color: "black",
      textOverflow: "ellipsis",overflow:"hidden",
       fontSize: "16px", fontWeight: "500", marginLeft: "0px", fontFamily: "Satoshi, sans-serif",
      whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "0px",overflow: "hidden",
      textOverflow: "ellipsis",
      flex: "1",
    }}>
    {isEditingYoutube ? (
      <input
        type="text"
        value={youtubeLink}
        onChange={handleYouTChange}
        placeholder="Add Link"
        style={{
          width: "140px", 
          marginTop:"1px",
          color:"black", 
          backgroundColor:"#F7F7F7", 
          borderColor:"#F7F7F7", 
          border:"1px solid #F7F7F7",
          marginLeft:"7px",
          borderRadius:"30px",
         
        }}
      />
    ) : (
      <span style={{marginLeft:"10px",paddingTop:"15px"}}> { youtubeLink ? youtubeLink : 'Add Link' } </span>
    )}
  </div>
  <div
    style={{
      padding: "6px 14px",
      borderRadius: "48px",
      backgroundColor: "#1877F2",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    }}
  >
    <button
      style={{
        background: "none",
        border: "none",
        display: "flex",
        alignItems: "center",
      }}
      onClick={
        isEditingYoutube
          ? (e) => handleYouTSaveClick(e, 'youtube')
          : (e) => handleYouTEditClick(e, 'youtube')
      }
    >
      <img
        src={isEditingYoutube ? "/images/save_link.svg"  : "/images/wedit.svg" }
        style={{
          width: "12px",
          height: "12px",
          marginBottom: "2px",
          marginRight: "5px",
        }}
        alt="Edit Icon"
      />
    </button>
  </div>
</div>

    

      {/* Twitter Block */}
      <div>
        <img src="/images/twitter.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn2" style={{
        width: "200px", gap: "0px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
      }}>
        <div style={{
          color: "black",
          textOverflow: "ellipsis",overflow:"hidden",
           fontSize: "16px", fontWeight: "500", marginLeft: "0px", fontFamily: "Satoshi, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "0px",overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "1",
        }}>
          {isEditingTwitter ? (
            <input
            type="text"
            value={twitterLink}
            onChange={handleTwitterChange}
            placeholder="Add Link"
            style={{ 
              width: "140px", 
          marginTop:"1px",
          color:"black", 
          backgroundColor:"#F7F7F7", 
          borderColor:"#F7F7F7", 
          border:"none",
          marginLeft:"7px",
          borderRadius:"30px",
             
            }}
          />
          ) : (
            <span style={{ marginLeft: "10px", paddingTop: "15px" }}>
            {twitterLink ? twitterLink : 'Add Link'}
          </span>
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingTwitter ? (e) => handleTwitterSaveClick(e, 'twitter') : (e) => handleTwitterEditClick(e, 'twitter')}>
            <img src={isEditingTwitter ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "0px", marginRight: "2px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>

      {/* Telegram Block */}
      <div>
        <img src="/images/telegram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn3" style={{
        width: "200px", gap: "0px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
      }}>
        <div style={{
          color: "black",
          textOverflow: "ellipsis",overflow:"hidden",
           fontSize: "16px", fontWeight: "500", marginLeft: "0px", fontFamily: "Satoshi, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "0px",overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "1",
        }}>
          {isEditingTelegram ? (
           <input
           type="text"
           value={telegramLink}
           onChange={handleTeleChange}
           placeholder="Add Link"
           style={{ 
            width: "140px", 
          marginTop:"1px",
          color:"black", 
          backgroundColor:"#F7F7F7", 
          borderColor:"#F7F7F7", 
          border:"none",
          marginLeft:"7px",
          borderRadius:"30px",
             
            }}
         />
          ) : (
            <span style={{marginLeft:"10px",paddingTop:"15px"}}> {telegramLink ? telegramLink : 'Add Link'} </span>
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingTelegram ? (e) => handleTeleSaveClick(e, 'telegram') : (e) => handleTeleEditClick(e, 'telegram')}>
            <img src={isEditingTelegram ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>

      {/* Instagram Block */}
      <div>
        <img src="/images/instagram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn4" style={{
        width: "200px", gap: "0px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
      }}>
        <div style={{
          color: "black",
          textOverflow: "ellipsis",overflow:"hidden",
           fontSize: "16px", fontWeight: "500", marginLeft: "0px", fontFamily: "Satoshi, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "0px",overflow: "hidden",
          textOverflow: "ellipsis",
          flex: "1",
        }}>
          {isEditingInstagram ? (
            <input
            type="text"
            value={instagramLink}
            onChange={handleInstaChange}
            placeholder="Add Link"
            style={{ 
              width: "140px", 
              marginTop:"1px",
              color:"black", 
              backgroundColor:"#F7F7F7", 
              borderColor:"#F7F7F7", 
              border:"none",
              marginLeft:"7px",
              borderRadius:"30px",
             
              }}
          />
          ) : (
            <span style={{marginLeft:"10px",paddingTop:"15px"}}> {instagramLink ? instagramLink : 'Add Link'} </span>
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingInstagram ? (e) => handleInstaSaveClick(e, 'instagram') : (e) => handleInstaEditClick(e,'instagram')}>
            <img src={isEditingInstagram ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>
             
              </Box>

              <div style={{
  display: "flex",
  flexWrap: "wrap",
  gap: "15px",
  justifyContent: "space-between",
  marginTop: "30px",
  marginLeft: "20px",
  marginRight: "20px",
  width: "calc(100% - 40px)", // Adjust width to account for margins
}}>
  <div style={{
    flex: "1 1 calc(20% - 10px)", // Adjusts to take one-third of the available width minus the gap
    minWidth: "250px", // Ensures a minimum width
    minHeight: "90px",
    height: "90px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/roadmap.svg" alt="Roadmap" style={{ marginRight: "30px", marginLeft: "10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap",
      }}>
        View Roadmap
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
  </div>

  <div style={{
    flex: "1 1 calc(20% - 10px)", // Adjusts to take one-third of the available width minus the gap
    minWidth: "250px", // Ensures a minimum width
    minHeight: "90px",
    height: "90px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/report.svg" alt="Report" style={{ marginRight: "30px", marginLeft: "10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Access My Report
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
  </div>

  <Link to="/my-watchlist" style={{ textDecoration: 'none', flex: "1 1 calc(33.33% - 20px)", maxWidth: "calc(33.33% - 20px)", minWidth: "300px" }}>
    <div style={{
      minHeight: "90px",
      height: "90px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "5px 14px",
      borderRadius: "10px",
      backgroundColor: "white",
      boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
      alignItems: "center",
      border: "1px solid #E4E4E4",
    }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src="/images/watchlist.svg" alt="Watchlist" style={{ marginRight: "30px", marginLeft: "10px" }} />
        <Typography style={{
          color: "black",
          fontSize: "18px",
          whiteSpace: "nowrap"
        }}>
          Go To Watchlist
        </Typography>
      </div>
      <img src="/images/go.svg" alt="Go" style={{ marginRight: "15px" }} />
    </div>
  </Link>
</div>



              <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"24px",
                  fontWeight:"600"
                }}>Milestone</Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>

    <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone1.svg" alt="Milestone1" style={{
          
          width: "76px",  // Adjust width as necessary
          height: "76px", // Adjust height as necessary
          clipPath: "circle(33% at 50% 50%)",
        }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div>
              

              <Grid item xs={12} style={{marginLeft:"5px"}}>
      <Typography style={{ fontSize: "24px", fontWeight: "600", marginBottom:"10px" }}>Transaction History</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid>

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
    </Box>
    ):(
      <Box
sx={{
display: "flex",
flexDirection: "column",
minHeight: "100vh", // Ensures it takes at least the full viewport height
flexGrow: 1, // Allows the Box to grow and fill the available space
overflowY: "auto", 
padding:1

}}
>
<Helmet>
        <title>Account</title>
</Helmet>
<CssBaseline />

<Box
component="nav"
sx={{
  width: "100%", // Take full width on mobile
}}
aria-label="mailbox folders"
>
<Drawer
  container={container}
  variant="temporary"
  open={mobileOpen}
  onClose={handleDrawerToggle}
  ModalProps={{
    keepMounted: true,
  }}
  sx={{
    "& .MuiDrawer-paper": {
      width: "80%", // Adjust width for mobile
      backgroundColor: "white",
      color: "#727376",
    },
  }}
>
  {drawer}
</Drawer>
</Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingTop: 1,
          paddingBottom: 3,
          paddingLeft: 1,
          paddingRight: 0,
          backgroundColor: "white",
          paddingBottom: "2vw",
          marginRight: "6px",
          overflow: "auto", // Allow scrolling
        }}
      >
        <div
          style={{
            backgroundColor: "#FFF",
           
          }}
        >
          <Box sx={{ flexGrow: 1, padding: "2px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} align="left">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "0px",
                    marginBottom: "10px",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    style={{
                      fontFamily:"Satoshi, sans-serif",
                      marginTop:"-5px",
                      marginLeft:"-5px",
                      fontSize: "24px",
                      fontStyle: "normal",
                      fontWeight: "700",
                    }}
                  >
                    My Account
                  </Typography>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { sm: "none" } }}
                  >
                    <img
                      src="/images/menubar.svg"
                      alt="Menu Icon"
                      style={{
                        width: "24px",
                        height: "24px",
                        marginRight: "10px",
                        marginTop: "-20px",
                      }}
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Box>

          <Box
            
            sx={{
              "& .MuiTextField-root": { m: 1 },
            }}
            noValidate
            autoComplete="off"
           
          >
            
            <Grid container spacing={2}>
             

<Box style ={{
 backgroundColor:"white",
 marginTop:"0px",
 marginLeft:"8px",
 marginRight:"-0.5px",
 marginBottom:"18px",
 padding:"10px",
 marginTop:"10px",
 overflow:"hidden",
 border:"1px solid #b6b6b8",
 borderRadius:"18px",
 boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
 width:"100%"
}}>

<div style={{
  gap: "20px",
  display: "inline-flex",
  padding:"5px 2px",
  borderRadius:"38px",
  paddingRight:"20px",
  marginBottom:"20px",
  background: "linear-gradient(90deg, #EEEEEE 0%, #F6F6F6 100%)"
  }}>

{profilePhoto ?(
  <img src={profilePhoto} alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
):(
  <img src="/images/avtar.jpeg" alt ="profile_pic" style={{
    width: "42px", 
    height: "42px", 
    position: "relative", 
    borderRadius: "33px",
    border: "2px black solid"}}
     ></img>
)}

<div style={{
  justifyContent: "center", 
  alignItems: "center", 
  gap: "5px",
   display: "flex"
   }}>
<div style={{
  color: "black",
   fontSize: "16px",
   fontWeight: "600",
   marginLeft:"-5px",
   fontFamily:"Satoshi,sans-serif",
    wordWrap: "wordWrap"
    }}>{fullName}</div>
</div>
</div>
<Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '14px',
            fontWeight: '600',
            padding: '7px 10px',
            textDecoration: '',
            marginLeft:mediaQueryVar===false?"8px":"15px",
            marginTop:"-35px",
            height: mediaQueryVar===false ?"35px":"",

          }}
          onClick={handleButtonClick}
          onMouseDown={(e) => {
            // Change button styles on click
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            // Revert button styles when click is released
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}

        >
          <img src="/images/edit.svg" style={{
            marginRight:"0px"
          }}></img>
          
        </Button>

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={handleNameChange}
          />
          <input
            accept="image/*"
            type="file"
            onChange={handlePhotoChange}
            style={{ display: 'none' }}
            id="profile-photo-upload"
          />
          <label htmlFor="profile-photo-upload">
            <Button variant="contained" color="primary" component="span">
              Upload Photo
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleButtonSaveClick} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={severity}
      />

        <div style={{width:"100%",border:"1px solid #EEEEEE",height:"1px",marginBottom:"20px"}}></div>

              <div class="container" style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "15px",
              marginBottom: "40px",
              marginLeft:"-10px",
              marginTop:"5px",
              marginRight:"0px",
              width:"107%"
              
            }}>
    <div class="box" style={{
      backgroundColor: "#FFB45C",
      padding: "5px",
      paddingLeft:"10px",
      borderRadius: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height:"70px",
      minHeight:"70px",
      //width:"255px",
      
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}> 0
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Total Wisecoins</div>
      </div>

      <img style={{

        marginTop:"25px"
      }}
      
      src="/images/wise.png"  alt="profit" class="icon"/>
    </div>
    <div class="box0" style={{
      backgroundColor: "#D2FD8B",
      padding: "5px",
      paddingLeft:"10px",
      borderRadius: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"70px",
      minHeight:"70px",
     
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>0
        </div>

        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px",
          marginRight:"0px"
        }}>Earned Wisecoins</div>
      </div>
      <img style={{

marginTop:"30px"
}}src="/images/wise.png"  alt="tpv" class="icon"/>
    </div>

    <div class="box1" style={{
      backgroundColor: "#FFE144",
      padding: "5px",
      paddingLeft:"10px",
      borderRadius: "16px",
      display: "flex",
      alignItems: "center",
      //width:"255px",
      height:"70px",
      minHeight:"70px",
      
      justifyContent: "space-between",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}>0
        </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}
        >
        Spent Wisecoins</div>
      </div>
      <img style={{marginTop:"30px"}}src="/images/wise.png"  alt="roi" class="icon"/>
    </div>
    <div class="box2"  
    style={{
      backgroundColor: "#48CEFF",
      padding: "5px",
      paddingLeft:"10px",
      borderRadius: "16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      //width:"255px",
      height:"70px",
      minHeight:"70px",
      flex: "1 1 calc(50% - 10px)", /* Adjust width and margin */
      //minWidth: "200px", /* Optional: to handle very small screens */
      color: "#000",
      boxShadow: " 0px 4px 20px -10px rgba(0, 0, 0, 0.10)"
      }}>
      <div>
        <div class="value" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "17px",
          fontWeight: "700",
          color : "black"
        }}> 0 </div>
        <div class="label" style={{
          fontFmaily : "Satoshi, sans-serif",
          fontSize: "12px",
          fontWeight: "400",
          color : "black",
          marginTop: "10px"
        }}>Current Balance</div>
      </div>
      <img style={{marginTop:"30px"}}src="/images/wise.png"  alt="wallet" class="icon"/>
    </div>
  </div>
  <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '100%', 
      maxWidth: '400px', 
      margin: '0 5px',
      marginBottom: '10px' 
    }}>
      <label 
        htmlFor="userInput" 
        style={{ 
          marginBottom: '8px', 
          fontSize: '16px', 
          color: '#333',
        }}
      >
        <span style={{
        fontSize: "17px",
        color: "black",
        fontWeight: "600",
        fontFamily: "Satoshi, sans-serif",
        }}>Solana Wallet Address</span>

<Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 13px',
            marginLeft: '10px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={handleSave}
        >
          Save
        </Button>
      </label>
      <input
        type="text"
        id="userInput"
        placeholder="Enter your Wallet Address Here"
        value={walletAddress}
        onChange={handleInputChange} 
        style={{
          background:'white',
          color:'black',
          padding: '10px',
          border: '2px solid #1877F2',
          borderRadius: '15px',
          fontSize: '16px',
          width: '100%',
          marginBottom:"1%",
          boxSizing: 'border-box',
        }}
      />

{isSaved && (
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginTop: '10px', 
          color: 'green',
          fontFamily: 'Satoshi, sans-serif',
        }}>
          <span style={{ marginRight: '8px' }}>✔</span>
          <span>Your wallet address has been saved!</span>
        </div>
      )}
    </div>
    <Button
          variant="outlined"
          style={{
            backgroundColor: '#1877F2',
            color: 'white',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 13px',
            marginLeft: '10px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={() => navigate('/airdrop-form')} 
        >
        Read about CW360 Airdrop
        </Button>

        <div style={{
    backgroundColor: '#F8F9FA',
    padding: '25px',
    borderRadius: '12px',
    boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
    marginTop: '20px',
    textAlign: 'center',
    marginBottom: '20px',
    border: '1px solid #E0E0E0',
    position: 'relative',
    overflow: 'hidden'
  }}>
  <h3 style={{
    fontSize: '20px',
    fontFamily: 'Satoshi, sans-serif',
    fontWeight: '700',
    color: '#444',
    marginBottom: '20px'
  }}>
    Share Your Referral Link
  </h3>
  <input
    type="text"
    value={generateReferralLink()}
    readOnly
    style={{
      width: '75%',
      padding: '12px',
      border: 'none',
      borderRadius: '25px',
      fontSize: '16px',
      fontFamily: 'Satoshi, sans-serif',
      color: '#333',
      marginBottom: '20px',
      background: '#FFFFFF',
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
      transition: 'box-shadow 0.3s ease',
      marginRight:'20px'
    }}
    onFocus={(e) => e.target.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.2)'}
    onBlur={(e) => e.target.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.1)'}
  />
  <Button
    variant="contained"
    color="primary"
    style={{
      backgroundColor: '#007BFF',
      color: '#FFFFFF',
      borderRadius: '50px',
      padding: '12px 24px',
      fontWeight: '700',
      textTransform: 'none',
      fontSize: '15px',
      fontFamily: 'Satoshi, sans-serif',
      marginRight: '15px',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
      transition: 'background-color 0.3s ease, box-shadow 0.3s ease'
    }}
    onClick={() => navigator.clipboard.writeText(generateReferralLink())}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = '#0056b3';
      e.currentTarget.style.boxShadow = '0px 6px 12px rgba(0, 0, 0, 0.2)';
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = '#007BFF';
      e.currentTarget.style.boxShadow = '0px 4px 10px rgba(0, 0, 0, 0.15)';
    }}
  >
    Copy Link
  </Button>
  <img
    src="/images/referral-decorative.svg"
    alt="Decorative"
    style={{
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '150px',
      height: '150px',
      opacity: '0.1',
      pointerEvents: 'none'
    }}
  />
</div>
    </Box>

    


  <Box style ={{
 backgroundColor:"white",
 marginTop:"0px",
 marginLeft:"8px",
 marginRight:"-0.5px",
 marginBottom:"4px",
 padding:"10px",
 marginTop:"10px",
 overflow:"hidden",
 border:"1px solid #b6b6b8",
 borderRadius:"18px",
 boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
 width:"100%"
}}>
  
              
  <div style={{ marginBottom: '15px' }}>
      <div>
        <Typography
          style={{
            display: 'inline-flex',
            fontSize: '20px',
            fontWeight: '600',
            marginLeft:'5px',
            fontFamily: 'Satoshi, sans-serif',
          }}
        >
          <span style={{fontSize:"18px"}}>Description</span>
        </Typography>

        <Button
          variant="outlined"
          style={{
            backgroundColor: 'white',
            color: '#1877F2',
            borderColor: '#1877F2',
            borderWidth: '2px',
            borderStyle: 'solid',
            borderRadius: '48px',
            textTransform: 'none',
            fontFamily: 'Satoshi, sans-serif',
            fontSize: '13px',
            fontWeight: '600',
            padding: '7px 13px',
            marginLeft: '10px',
            marginTop: '0px',
            height: '25px',
          }}
          onMouseDown={(e) => {
            e.currentTarget.style.backgroundColor = '#1877F2';
            e.currentTarget.style.color = 'white';
          }}
          onMouseUp={(e) => {
            e.currentTarget.style.backgroundColor = 'white';
            e.currentTarget.style.color = '#1877F2';
          }}
          onClick={isEditing ? handleSaveClick : handleEditClick}
        >
          <img
            src="/images/edit.svg"
            style={{
              marginRight: '5px',
            }}
            alt="Edit"
          />
          {isEditing ? 'Save' : 'Edit'}
        </Button>

        {isEditing ? (
          <TextField
            value={description}
            onChange={handleDescriptionChange}
            multiline
            fullWidth
            rows={4}
            variant="outlined"
            style={{
              marginTop: '10px',
              fontFamily: 'Satoshi, sans-serif',
              fontSize: '16px',
              fontWeight: '500',
            }}
          />
        ) : (
          <Typography
            style={{
              color: '#7F7F80',
              fontSize: '17px',
              fontWeight: '500',
              fontFamily: 'Satoshi, sans-serif',
              wordWrap: 'break-word',
              marginTop: '10px',
              marginLeft:'6px'
            }}
          >
            {description}
          </Typography>
        )}
      </div>
    </div>
              
<div style={{ marginBottom: "10px" }}>
      <div>
        <Typography
          style={{
            fontSize: "17px",
            color: "black",
            fontWeight: "600",
            fontFamily: "Satoshi, sans-serif",
          }}
        >
          <span style={{fontSize:"18px"}}>Tags</span>
        </Typography>
        <div>
          <div
            className="wrapper1"
            style={{ marginTop: "10px", display: "flex", flexDirection:"column",flexWrap: "wrap" }}
          >
            {loading ? (
              <div>Loading...</div>
            ) : (
              gallarydata.length > 0 &&
              gallarydata[0].slugs.map((slug, index) => (
                <div
                  key={index}
                  className={`tag${index + 1}`}
                  style={{
                    gap: "20px",
                    display: "flex",
                    minWidth:"30px",
                    padding: "5px 2px",
                    borderRadius: "38px",
                    paddingRight: "5px",
                    marginBottom: "10px",
                    background: "#F7F7F7",
                    marginRight: "10px",
                    justifyContent: "space-between",
                    flexWrap: "nowrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginLeft: "20px",
                      whiteSpace: "nowrap",
                      marginTop: "0px",
                    }}
                  >
                    {slug.category}
                  </div>
                  <div
                    style={{
                      padding: "4px 12px",
                      borderRadius: "48px",
                      backgroundColor: "#1877F2" ,
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    <img
                      src="/images/tag.svg"
                      style={{
                        width: "22px",
                        height: "22px",
                        marginBottom: "2px",
                      }}
                      alt="Remove"
                    />
                  </div>
                </div>
              ))
            )}
          </div>
          
        </div>
        
      </div>
    </div>
             

             
                <div>
                  <div>
                    <Typography style={{
                      fontSize:"18px",
                      color:"black",
                      fontWeight:"600",
                      marginLeft:"5px",
                      fontFamily:"Satoshi,sans-serif"
                    }}>
                      <span style={{fontSize:"18px"}}>Social Profile</span>
                      </Typography>
                    <div>

                    
    <div className="wrapper1" style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}>
      {/* YouTube Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/youtube.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn1" style={{
         width: "80%", gap: "30px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
         marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
       }}>
        <div style={{
          color: "black", fontSize: "16px", fontWeight: "500", marginLeft: "20px", fontFamily: "Satoshi, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "6px",overflow:"hidden",textOverflow:"ellipsis"
        }}>
          {isEditingYoutube ? (
            <input
              type="text"
              value={youtubeLink}
          
            onChange={handleYouTChange}
              placeholder="Add Link"
              style={{ width: "100%", color:"black", backgroundColor:"#F7F7F7", borderColor:"#F7F7F7", border:"none" }}
            />
          ) : (
            youtubeLink || 'Add Link'
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingYoutube ? (e) => handleYouTSaveClick(e, 'youtube') : (e) => handleYouTEditClick(e, 'youtube')}>
            <img src={isEditingYoutube ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>
   

      {/* Twitter Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/twitter.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn2" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
      }}>
        <div style={{
          color: "black", fontSize: "16px", fontWeight: "500", marginLeft: "20px", fontFamily: "Satoshi, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "6px",overflow:"hidden",textOverflow:"ellipsis"
        }}>
          {isEditingTwitter ? (
            <input
            type="text"
            value={twitterLink}
            onChange={handleTwitterChange}
            placeholder="Add Link"
            style={{ width: "100%", color:"black", backgroundColor:"#F7F7F7", borderColor:"#F7F7F7", border:"none" }}
          />
          ) : (
            twitterLink || 'Add Link'
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingTwitter ? (e) => handleTwitterSaveClick(e, 'twitter') : (e) => handleTwitterEditClick(e, 'twitter')}>
            <img src={isEditingTwitter ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>

      {/* Telegram Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/telegram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn3" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", marginRight: "10px", justifyContent: "space-between"
      }}>
        <div style={{
          color: "black", fontSize: "16px", fontWeight: "500", marginLeft: "20px", fontFamily: "Satoshu, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "6px",overflow:"hidden",textOverflow:"ellipsis"
        }}>
          {isEditingTelegram ? (
           <input
           type="text"
           value={telegramLink}
           onChange={handleTeleChange}
           placeholder="Add Link"
           style={{ width: "100%", color:"black", backgroundColor:"#F7F7F7", borderColor:"#F7F7F7", border:"none" }}
         />
          ) : (
            telegramLink || 'Add Link'
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingTelegram ? (e) => handleTeleSaveClick(e, 'telegram') : (e) => handleTeleEditClick(e, 'telegram')}>
            <img src={isEditingTelegram ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>

      {/* Instagram Block */}
      <div style={{marginLeft:"8px"}}>
        <img src="/images/instagram.svg" width="32px" height="32px"
          style={{ border: "1.29px solid black", borderRadius: "50%", padding: "5px", marginRight: "5px", marginTop: "5px" }} />
      </div>
      <div className="socialBtn4" style={{
        width: "80%", gap: "30px", display: "inline-flex", padding: "5px 2px", borderRadius: "38px", paddingRight: "5px",
        marginBottom: "10px", background: "#F7F7F7", justifyContent: "space-between", marginRight: "-31px"
      }}>
        <div style={{
          color: "black", fontSize: "16px", fontWeight: "500", marginLeft: "20px", fontFamily: "Satoshu, sans-serif",
          whiteSpace: "nowrap", color: "#9D9DA5", marginTop: "6px",overflow:"hidden",textOverflow:"ellipsis"
        }}>
          {isEditingInstagram ? (
            <input
            type="text"
            value={instagramLink}
            onChange={handleInstaChange}
            placeholder="Add Link"
            style={{ width: "100%", color:"black", backgroundColor:"#F7F7F7", borderColor:"#F7F7F7", border:"none" }}
          />
          ) : (
            instagramLink || 'Add Link'
          )}
        </div>
        <div style={{
          padding: "6px 14px", borderRadius: "48px", backgroundColor: "#1877F2", display: "inline-flex", alignItems: "center",
          justifyContent: "center", cursor: "pointer",
        }}>
          <button style={{ background: "none", border: "none", display: "flex", alignItems: "center" }}
            onClick={isEditingInstagram ? (e) => handleInstaSaveClick(e, 'instagram') : (e) => handleInstaEditClick(e,'instagram')}>
            <img src={isEditingInstagram ? "/images/save_link.svg"  : "/images/wedit.svg" } style={{ width: "12px", height: "12px", marginBottom: "2px", marginRight: "5px" }} alt="Edit Icon" />
          </button>
        </div>
      </div>
    </div>

                  </div>
                </div>
              </div>
             
              </Box>




  <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between", marginTop: "30px", marginLeft: "10px", width: "100%",marginRight:"20px" }}>
  <div style={{
    width: "360px",
    minHeight: "80px",
    height: "80px",
    display: "flex",
    flexDirection: "row",
    marginRight:"10px",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/roadmap.svg" alt="Roadmap" style={{ marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap",
        
      }}>
        View Roadmap
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}}/>
  </div>

  <div style={{
    display: "flex",
    width: "360px",
    minHeight: "80px",
    height: "80px",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    marginRight:"10px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    border: "1px solid #E4E4E4",
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/report.svg" alt="Report" style={{  marginRight: "30px",marginLeft:"10px"}} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Access My Report
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}}/>
  </div>
  <Link to="/my-watchlist" style={{ textDecoration: 'none' }}>
  <div style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "5px 14px",
    borderRadius: "10px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    alignItems: "center",
    width: "340px",
    minHeight: "80px",
    height: "80px",
    marginRight:"20px",
    border: "1px solid #E4E4E4"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src="/images/watchlist.svg" alt="Watchlist" style={{  marginRight: "30px",marginLeft:"10px" }} />
      <Typography style={{
        color: "black",
        fontSize: "18px",
        whiteSpace: "nowrap"
      }}>
        Go To Watchlist
      </Typography>
    </div>
    <img src="/images/go.svg" alt="Go" style={{  marginRight: "15px"}} />
  </div>
  </Link>
</div>


              <div style={{
                marginLeft:"20px",
                marginTop:"20px",
                width:"100%",
                marginRight:"20px"
              }}>
                <Typography style={{
                  marginBottom:"10px",
                  fontSize:"20px",
                  fontWeight:"700"
                }}>Milestone</Typography>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "15px", justifyContent: "space-between" }}>
  <div class="milestone1" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
   <div style={{ display: "flex", alignItems: "center" }}>

<div style={{ marginRight: "10px", position: "relative" }}>
    <img src="/images/milestone1.svg" alt="Milestone1" style={{
      
      width: "76px",  // Adjust width as necessary
      height: "76px", // Adjust height as necessary
      clipPath: "circle(33% at 50% 50%)",
    }}></img>
    <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
  </div>
  
  <Typography style={{
    borderRadius: "10px",
    padding: "10px 26px",
    background: "rgba(226.08, 236, 112, 0.14)",
    color: "#A87727",
    fontSize: "12px",
    whiteSpace: "nowrap"
  }}>
    Yet to complete
  </Typography>
</div>
</div>

  <div class="milestone2" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone2.svg" alt="Milestone2" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone3" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    background: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone3.svg" alt="Milestone3" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>

  <div class="milestone4" style={{
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "5px 14px",
    borderRadius: "12px",
    backgroundColor: "white",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "14px",
    alignItems: "center",
    flex: "1 1 calc(25% - 20px)"
  }}>
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ marginRight: "10px", position: "relative" }}>
        <img src="/images/milestone4.svg" alt="Milestone4" style={{ display: "block" }}></img>
        <img src="/images/lock.svg" alt="Lock" style={{ position: "absolute", top: 20, left: 25 }}></img>
      </div>
      <Typography style={{
        borderRadius: "10px",
        padding: "10px 26px",
        background: "rgba(226.08, 236, 112, 0.14)",
        color: "#A87727",
        fontSize: "12px",
        whiteSpace: "nowrap"
      }}>
        Yet to complete
      </Typography>
    </div>
  </div>
</div>

                </div>
              

              <Grid item xs={12} style={{marginLeft:"5px",marginBottom:"60px"}}>
      <Typography style={{ fontSize: "20px", fontWeight: "700", marginBottom:"10px" }}>Transaction History</Typography>
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Total Received in Farming</Typography>
          </CardContent>
          <img src="/images/handcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: "24px",
          boxShadow: "0px 4px 20px -10px rgba(0, 0, 0, 0.10)",
          marginRight: "10px"
        }}>
          <CardContent style={{ flex: "1", textAlign: 'left' }}>
            <Typography style={{
              fontSize: "32px",
              fontWeight: "700",
              marginBottom:"20px"
            }}>0</Typography>
            <Typography variant="body2" color="textSecondary">Buy Back History</Typography>
          </CardContent>
          <img src="/images/clockcoin.png" alt="Hand with coins" width="149px" height="130px" style={{ alignSelf: 'center', marginRight: '0px' }} />
        </Card>
      </Grid>
      </Grid>
    </Grid>

            </Grid>
          </Box>
        </div>
        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{snackbarmessage}</Alert>
        </Snackbar>
      </Box>
      <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >

      <BottomNavigation showLabels>
      <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label={
        <span style={{ letterSpacing: '0.1em' }}>
        Profile
      </span>
       }
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}} />}
/>
    </BottomNavigation>
        </div>
    </Box>

    

)}
</>


  );
  
 
}
  
export default Account;
