import "./portfolioFaqs.css";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import json2mq from "json2mq";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { ButtonGroup,BottomNavigation,Paper,Chip } from "@mui/material";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { getContent } from "../../apihelper/content";
import { useLocation } from "react-router-dom";

import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
const drawerWidth = 292;

function PortfolioFaqs(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [aboutdata, setAboutData] = useState("");
  const [loader, setLoader] = useState(false);
  const location = useLocation(); 
  const loc = location.pathname;
  const [userData,setUserData]=useState(undefined)
  React.useEffect(() => {
    const localUserData=localStorage.getItem("cw_portfolio_user")
    setUserData(localUserData);
    getContent("about").then((data) => {
      if (data.code === "200") {
        setAboutData(data.content);
       // console.log(aboutdata);
        setLoader(true);
      } else {
        console.log("Api Call Error");
        // Todo pop up the error
      }
    });
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
       <SidebarMenuList/>
    </div>
  );

  const navigate = useNavigate();

    const mediaQueryVar = useMediaQuery(
        json2mq({
          minWidth: 900,
        })
      );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }
  

  return (
    <Box sx={{ paddingBottom:"100px",display: "flex", flexWrap: "wrap" }}>
<Helmet>
        <title>FAQs</title>
</Helmet>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        
        <Box sx={{ flexGrow: 1, margin: "2px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} align="left">
            <div style={{ 
  display: "flex", 
  alignItems: "center", 
  justifyContent: "space-between", 
  marginTop: "0px", 
  marginBottom: "10px" ,
  marginLeft:"5px"
}}>

                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  style={{
                    fontFamily: "'Satoshi', Arial, sans-serif",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "600",
                  }}
                >
                  FAQs
                </Typography>
              
              <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{ display: { sm: "none" } }}
  >
   <img src= '/images/menubar.svg'
 alt="Menu Icon" style={{ width: "24px", height: "24px",marginRight:"10px",marginTop:"-20px" }} />
  </IconButton></div>

            </Grid>

            <Grid item xs={12} align="left">
            <div>

            <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
            Do I need to connect my wallet to track my portfolio?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          No, you don't need to connect your wallet to your portfolio. That is the beauty of our system, we don't ask for your wallet or account address for you to track your crypto investment.
          </Typography>
        </AccordionDetails>
      </Accordion>

      
              {/* 1st faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          What is Crowdwisdom360 Portfolio, and how can it help me build a winning crypto portfolio?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Crowdwisdom360 Portfolio is a unique, expert-backed crypto portfolio designed to empower you in optimizing your investment approach. Driven by a blend of expert insights, crowd intelligence, and advanced algorithms, it offers a comprehensive strategy to navigate the dynamic crypto landscape. With Crowdwisdom360, you can invest with confidence, knowing you have the collective wisdom of a community and cutting-edge technology by your side. Embrace the journey of investment with a tool that truly understands your aspirations.          </Typography>
        </AccordionDetails>
      </Accordion>
{/* 2nd faq */}
<Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          How do I sign up to create and manage a portfolio on Crowdwisdom360?
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          style={{
            fontFamily: "'Satoshi', Arial, sans-serif",
            lineHeight: "1.6",
            marginBottom: "10px",
          }}
        >
          <strong>To sign up: </strong><br />
          1. Click on the Login/Signup Button <br />
          a. For Mobile users, Click on the icon on the top right corner
          <br />
          <img
            src="/images/faq_image1.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Step 1"
          />
          2. In the next step, Click on “Continue with Google” and sign up with
          your Gmail account. <br />
          3. On successful completion of the Sign Up process, you will be
          directed to the “Best Crypto Portfolios” page. <br />
          4. Users can either view the already registered portfolios or create
          their own portfolio by Clicking on “Add Your Portfolio”
          <br />
          <img
            src="/images/faq_image2.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Add Portfolio"
          />
          <br />
          <strong>To Add Portfolio:</strong>
          <br />
          1. Click on the Tab on the Right Top Corner and then Click on
          Portfolio Gallery.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>

          <img
            src="/images/faq_image3.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Portfolio Gallery"
          />
          <img
            src="/images/faq_image4.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Portfolio Gallery"
          /></div>
          <br />
          2. Click on Add Portfolio. On the Screener Page, you can either search
          for the crypto or filter the crypto using the filter option. Click on
          the Add to Portfolio tab next to the name of crypto.
          <br />
          <img
            src="/images/faq_image5.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Add to Portfolio"
          />
          <br />
          3. On the Next Screen, Click on the Arrow-Like Tab and then Click on
          the Create New Portfolio.
          <br />
          <img
            src="/images/faq_image6.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Create New Portfolio"
          />
          <br />
          4. Type in the desired name for the Portfolio and click on Submit.
          <br />
          <img
            src="/images/faq_image7.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Submit Portfolio"
          />
          <br />
          5. Once the Portfolio is created, you can add the desired number of
          Cryptos to be added to the portfolio. Once a Crypto is added, you can
          add more cryptos by clicking on Add More.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>

          <img
            src="/images/faq_image8.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Add More Cryptos"
          />
          <img
            src="/images/faq_image9.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Add More Cryptos"
          /></div>
          <br />
          <strong>To manage your portfolio:</strong>
          <br />
          1. Once you have created a portfolio, Click on My Portfolios tab on
          the Right Side.
          <br />
          a. For Mobile Users, Click on the Tab on the Top Right Corner and then
          Click on My Portfolios.
          <br />
          <img
            src="/images/faq_image10.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="My Portfolios"
          />
          <br />
          2. On the My Portfolio Page, users can manage their portfolio by
          simply clicking on the “Edit” tab, which gives the option to change
          their portfolio name and image or view their portfolio and manage it.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>

          <img
            src="/images/faq_image11.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Edit Portfolio"
          />
          <img
            src="/images/faq_image12.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Edit Portfolio"
          /></div>
          <br />
          <strong>Copy Portfolio:</strong>
          <br />
          Copy Portfolio is a unique feature of our platform. You can copy any
          high-return portfolio.
          <br />
          <strong>To Copy Portfolio:</strong>
          <br />
          1. Visit the Portfolio that you wish to copy. Click on the Portfolio
          Gallery and then on the name of the Portfolio that you wish to copy.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>
          <img
            src="/images/faq_image13.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Copy Portfolio"
          />
          <img
            src="/images/faq_image14.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Copy Portfolio"
          /></div>
          <br />
          2. On the Portfolio Page, Click on the Copy Portfolio button. You can
          copy the Cryptos of the Portfolio to your existing portfolio or Create
          a new Portfolio.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>

          <img
            src="/images/faq_image15.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Copy Cryptos"
          />
          <img
            src="/images/faq_image16.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Copy Cryptos"
          />
          <br /></div>
          <strong>Order Book:</strong>
          <br />
          In the Order Book, you can track which cryptocurrencies you have
          purchased and sold and when the transaction was carried out.
          <br />
          <img
            src="/images/faq_image17.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Order Book"
          />
          <br />
          <strong>My Watchlist:</strong>
          <br />
          In My Watchlist, you can track the portfolios you may consider
          tracking. To add a portfolio to My Watchlist, click on the Star icon
          located on the Portfolio. The Portfolios will be added to your
          watchlist.
          <br />
          <div style={{display : "flex", flexDirection:mediaQueryVar ? "row":"column", alignItems:"center"}}>
          <img
            src="/images/faq_image18.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Watchlist"
          />
          <img
            src="/images/faq_image19.jpeg"
            style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}
            alt="Watchlist"
          /></div>
        </Typography>
      </AccordionDetails>
    </Accordion>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>Is the buy/sell price live?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          The Buy sell Prices are updated every 6 hours</Typography>
        </AccordionDetails>
      </Accordion>

{/* 3rd faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          How does Crowdwisdom360 provide portfolio analysis and recommendations?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Crowdwisdom360 offers portfolio analysis and recommendations by leveraging algorithm-based insights to evaluate the performance of different cryptocurrency categories. <br />
          <img src="/images/faq_image20.jpeg" style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}></img>
          The analysis incorporates factors such as crowd sentiment, influencer activity, token history, the distribution of crypto holdings, and the concentration among the top 100 holders. With this data-driven approach, Crowdwisdom360 identifies underperforming assets in your portfolio and provides customized recommendations to help optimize your investment strategy. <br />

          <img src="/images/faq_image21.jpeg" style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}></img>

          </Typography>
        </AccordionDetails>
      </Accordion>

{/* 4th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          How do Influencer Profiles and Rankings work on Crowdwisdom360?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          The cryptocurrencies in the influencer's shadow portfolio are added on the date their recommendations are made. Our algorithm begins tracking the price movements of these cryptocurrencies from that day. Based on the return on investment (ROI), our algorithm then determines the rankings.           </Typography>
        </AccordionDetails>
      </Accordion>

{/* 5th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          What are the Crowdwisdom360 indices, and how do index subscriptions work?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Crowdwisdom360 indices are a meticulously curated collection of cryptocurrencies based on thorough research. Organized by category, each cryptocurrency in the index is evaluated according to performance, number of holders, trading volume, and other key criteria before being included in the Crowdwisdom360 indices.
Index subscription will give you the access to the high quality Crowdwisdom360 indexes created by the team of experts. 
          </Typography>
        </AccordionDetails>
      </Accordion>

{/* 6th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Do I have to pay for accessing other registered users' portfolios, and can I keep my own portfolio private?

</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          No, there is no charge for accessing other registered users portfolios. <br />

Yes, you can keep your own portfolio private by clicking on the lock icon next to your portfolio. <br />
<img src="/images/faq_image22.jpeg" style={{
              display: "block",
              margin: "10px auto",
              maxWidth: "100%",
            }}></img>

          </Typography>
        </AccordionDetails>
      </Accordion>

{/* 7th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          What is the "Predict and Win" feature, and how do I participate?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Predict and Win is a gaming feature on the Crowdwisdom360 platform designed to assess the crowd's intelligence. You can earn rewards based on the accuracy of your predictions made within a specified timeframe.          </Typography>
        </AccordionDetails>
      </Accordion>

{/* 8th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          What is the Wisecoin token, and how is it used on the platform?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography
      style={{
        fontFamily: "'Satoshi', Arial, sans-serif",
        lineHeight: "1.6",
        marginBottom: "20px",
      }}
    >
      <strong>Wisecoin</strong> token is the native token of Crowdwisdom360 platform.
      The total token in circulation is 1.02 Billion. Here is how Wisecoin is distributed:
      <br /><br />
      <strong>Airdrops:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Allocation: 25%</li>
      </ul>

      <strong>Farming:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Allocation: 17%</li>
      </ul>

      <strong>Initial Sign-up:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Allocation: 3%</li>
      </ul>

      <strong>Exchange Listing:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Strategic allocation: 25% of tokens are reserved for exchange listing.</li>
      </ul>

      <strong>Team Rewards:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Allocation: 2%</li>
      </ul>

      <strong>Company Reserves:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Strategic allocation: 25% of tokens are reserved for the company's account.</li>
      </ul>

      <strong>Founders' Share:</strong>
      <ul style={{ marginLeft: "20px" }}>
        <li>Recognition for leadership: Founders will receive a combined 3% allocation.</li>
      </ul>

      <br />
      <strong>Roadmap</strong>
      <br />
      <img
        src="/images/faq_image23.jpeg"
        style={{
          display: "block",
          margin: "10px auto",
          maxWidth: "100%",
        }}
        alt="Roadmap"
      />
      <br />
      Wisecoin will be used to access all the features on the platform.
    </Typography>
        </AccordionDetails>
      </Accordion>

{/* 9th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          How are Wisecoins distributed to users, and what actions earn rewards?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Wisecoins are distributed to users in multiple ways. The easiest way to earn Wisecoin is by signing up to the platform and creating your portfolio. 
Apart form this, you can earn Wisecoin by referring your friends to the platform. Also Wisecoins will be used as reward token on the platform. 
          </Typography>
        </AccordionDetails>
      </Accordion>

{/* 10th faq */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography  style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Is Wisecoin listed on any exchanges?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography style={{fontFamily: "'Satoshi', Arial, sans-serif",}}>
          Currently Wisecoin is in its presale stage. It will be listed on prominent exchanges once the presale stage ends. However, Wisecoin can be redeemed time to time.          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
             
            </Grid>
          </Grid>
        </Box>
      </Box>
      {mediaQueryVar === false && (


<div
  style={{
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
    width: "105%",
    zIndex: "100",
    marginBottom:"0px",
    marginLeft:"-10px"
  }}
>
<BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
</div>
)}
    </Box>
  );
}

export default PortfolioFaqs;
