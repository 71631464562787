import "./portfolio.css";
import * as React from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "@mui/material/Table";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Dialog from "@mui/material/Dialog";

import {
  getCryporCurrencyListHome,
  getPortfolioForGallery,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import { ButtonGroup } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Helmet } from 'react-helmet';
import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuList from "../../includes/menulist";
import MenuListHome from "../../includes/menuListHome";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Button, Grid, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import { getCryporCurrencyList } from "../../apihelper/cryptocurrencylist";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import CloseIcon from "@mui/icons-material/Close";
import {
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";
import { getPortfolioData } from "../../apihelper/portfolio";
import { getPerformanceSummaryShare, getPerformanceSummaryShareWithoutLogin } from "../../apihelper/portfolio";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components

import { addToPortfolio } from "../../apihelper/portfolio";
import { useNavigate } from "react-router-dom";
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { BottomNavigation,Paper,Chip } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import Portfolioadd from "../addPage/portfolioadd";
import SidebarMenuList from "../../includes/sidebarMenuList";
import {Link, useLocation } from "react-router-dom";
import { Tabs, Tab } from '@mui/material';
import Footer from '../footer';

import LoginPopUp from "../loginpopup.js";
// const ASSET_PATH = process.env.PUBLIC_URL;
const useStyles = styled((theme) => ({
  tableContainer: {
    maxWidth: 600,
    margin: "0 auto",
  },
  table: {
    display: "none", // Hide the table on all screens
    [theme.breakpoints.down("sm")]: {
      display: "table", // Show the table on small screens only (mobile view)
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide the card container on small screens (mobile view)
    },
  },
  card: {
    width: "100%",
    margin: "8px",
    padding: "16px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px", // Adjust the card width as needed
    },
  },
}));
const drawerWidth = 292;

// Define the keyframes for the left-right motion animation
const leftRightMotion = `
  @keyframes leftRightMotion {
    0% {
      transform: translateX(-30px); /* Start 30px left of center */
    }
    50% {
      transform: translateX(30px);  /* Move 30px right of center */
    }
    100% {
      transform: translateX(-30px); /* Return to 30px left of center */
    }
  }
`;

function PerformanceSummaryShare(props) {
    
    const { portfolioName } = useParams();
    const mPortfolioName = portfolioName.replace(/-/g, ' ');

    const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleLoginClick = () => {
    // Mask the URL by adding /login at the end without navigating
    window.history.pushState({}, "", "/login");
    localStorage.setItem("isPopupManuallyOpened", "true");

    // Open the login modal
    setLoginModalOpen(true);
  };

  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 420,
    })
  );
  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};
   
    // Now, userId contains the value of the "id" parameter from the URL
   // 
    //console.log("portfolio Name$:", portfolioName);
  const navigate = useNavigate();
  const { window1 } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newOptionValue, setNewOptionValue] = useState("");
  const [dataslug, setdataslug] = useState("");

  const handleClose = () => setOpen(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState("left");
  const [loader, setLoader] = useState(false);
  const [slugdata, setSlugdata] = useState([]);
  const [slugdataP, setSlugdataP] = useState([]);

  const [category, setCategory] = useState([]);
  const [index, setIndex] = useState(0);
  const [portfolioId, setportfolioid] = useState("");
  const [totalUnits, setTotalUnits] = useState(0);
  const [Usablewallet, setUsablewallet] = useState("");
  const [portfolioTypeId, setPortfolioTypeId] = useState("");

  const [user_role, setuser_role] = useState("");
  const [addMoreLoader, setAddMoreLoader] = useState(false);
  const [filter, setFilter] = useState("");
  const [profilename, setprofilename] = useState("...");
  const [loaderbutton, setLoaderButton] = useState(true);
  const [roivalue, setRoivalue] = useState("-");
  const [portfoliovalue, setPortfoliovalue] = useState("-");
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("");
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [msgAfterLoading, setMsgAfterLoading] = useState("");
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");

  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const [slugdataNew, setSlugdataNew] = useState([]);
  const [slugpopupdataNew, setSlugpopupdataNew] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [initialPage, setInitialPage] = useState(false);
  const [localUserData,setLocalUserData]=useState(undefined)
  const [loaderForSymbolLink,setLoaderForSymbolLink]=useState(false);
  const [PortfolioRoivalue,setPortfolioRoivalue]=useState('-');
  const [nodatafoundmsgtype,setnodatafoundmsgtype]=useState('');
  const [currentwallet,setcurrentwallet]=useState('-');
  const [sharpratio,setSharpRatio]=useState('--');
  const [portfolio_7days,setPortfolio_7days]=useState('');
  const [portfolio_30days,setPortfolio_30days]=useState('');
  const location = useLocation(); 
  const loc = location.pathname;
  const [sortedData, setSortedData] = useState([]);
  const [rating,setRating]=useState('');
  const [selectedButton, setSelectedButton] = useState("PortfolioGallery");
 
  const formatToNonZero = (number) => {
    number = parseFloat(number);
    
    if (number >= 1) {
      return number.toFixed(2);
    } else if (number >= 0.1) {
      return number.toFixed(1);
    } else if (number >= 0.01) {
      return number.toFixed(4);
    } else if (number >= 0.001) {
      return number.toFixed(5);
    } else if (number >= 0.0001) {
      return number.toFixed(6);
    } else if (number >= 0.00001) {
      return number.toFixed(7);
    } else if (number >= 0.000001) {
      return number.toFixed(8);
    } else if (number >= 0.0000001) {
      return number.toFixed(9);
    } else if (number >= 0.00000001) {
      return number.toFixed(10);
    } else if (number >= 0.000000001) {
      return number.toFixed(11);
    }
    return number.toFixed(1);
  };

  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes('-')) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };

  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        console.log("Get total Units: ",res);
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
  

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleSymbolLink=(symbolName)=>{
    console.log(symbolName)
    setLoaderForSymbolLink(true);
    getsymbollink(symbolName).then(res=>{
      if(res.code==='200')
      window.open(res.article_link, '_blank', 'noreferrer');
      // window.location.replace(res.article_link);
      // setLoaderForSymbolLink(false);
      setLoaderForSymbolLink(false);
    })
  }

  const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    } else {

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
      setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: slug, // Assuming value contains the slug
        portfolio_type_id: selectedOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          console.log("Get Total Unit: ",res);
          setTotalUnits(res.total_uint);
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  };
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal

  

  const handleCreate = async (inputValue) => {
    setTotalUnits(0);
    setUsablewallet("");

    if (options.find((option) => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(
      (option) => option.value == inputValue
    );
   // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
  };



  

  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );

  const isSmallScreen = useMediaQuery(
    json2mq({
      maxWidth: 1300,
    })
  );
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleCreateNew = () => {
    // router.push(`/${path}`);
    navigate("/cryptocurrencylist", { replace: true });
  };

  const handleeyeclick = (portfolioName) => {
    // Replace spaces with hyphens
    const formattedName = portfolioName.replace(/ /g, '-');
  
    // Navigate to the route '/portfolio' with the formattedName parameter
    navigate(`/portfolio/${formattedName}/recommendations`);
  };
  
  const [isTableView, setIsTableView] = useState(true);
  const [loading, setLoading] = useState(true);

  const handleButtonClick = (buttonName) => {
    // If the same button is clicked again, don't toggle the view
    if (selectedButton === buttonName) {
      return;
    }

    // Toggle the view based on the clicked button
    setSelectedButton(buttonName);
    setIsTableView(buttonName === "CoinPrices" ? false : true); // Show table view only if 'CoinPrices' is selected
  };

  const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

  useEffect(() => {
    setIndex(0);
    setLoader(false);
    if ((filter.length < 1) & (filter.length >= 1)) {
      setLoader(true);
      setAddMoreLoader(false);
    }
    else{
      const user_idValue = userData ? userData.user_id : 0;
      const user_token = userData ? userData.token : 0;
      if (userData) {
        // User is logged in
        const userId  = userData['portfolio_name'];
      getPerformanceSummaryShare({
        slug: filter,
        user_id: user_idValue,
        portfolio_id:portfolioName,
        start_index: 0,
        limit: `${10}`,
        Token: user_token,
      }).then((res) => {
        if (res.code === "200") {
          //console.log("data got successfully");
          console.log("Performace summary data - portfolio Id",res.portfolio_Id);
          setSlugdata(res.data);
          setportfolioid(res.portfolio_Id);
          console.log("slug dataa: ",res.data)
          if (res.data.length < 10) {
            //console.log("change Loaderbutton");
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
          //console.log("Data fething error");
        }
        //console.log(slugdata)
      });
    } else {
      // User is not logged in
      getPerformanceSummaryShareWithoutLogin({
        portfolio_id: portfolioName, 
        slug: filter,
        start_index: 0,
        limit: `${10}`,
      }).then((res) => {
        if (res.code === "200") {
          setSlugdata(res.data);
          setportfolioid(res.portfolio_Id);
          console.log(res);
          setLoading(false);
          if (res.data.length < 10) {
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          setMsgAfterLoading(res.message);
        }
      });
    }
    }
  }, [filter]);

  useEffect(() => {
    //console.log(100)
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    setLocalUserData(userData)
    const user_idValue = userData ? userData.user_id : 0;
    //console.log("User_Idvalue"+user_idValue);
    const user_token = userData ? userData.token : 0;

    getPerformanceSummaryShare({
      slug: filter,
      user_id: user_idValue,
      portfolio_id:portfolioName,
      start_index: `${index}`,
      limit: `${10}`,
      Token: user_token,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully1", res.data);
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setSlugdata(res.data);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setcurrentwallet(res.current_wallet);
        setLastUpdatedTiem(res.updated_on);
        setprofilename(res.portfolio_name);
        setSharpRatio(res.sharpeRatio);
        setportfolioid(res.portfolio_Id);
        setPortfolio_7days(res.portfolio_7days);
        setPortfolio_30days(res.portfolio_30days);
        setRating(res.rating);
        // console.log(res.data)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });


    getPerformanceSummaryShareWithoutLogin({
      slug: filter,
      portfolio_id:portfolioName,
      start_index: `${index}`,
      limit: `${10}`,
      Token: user_token,
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully1",res.data);
        setRoivalue(res.total_profit);
        setPortfoliovalue(res.total_portfolio_value);
        setPortfolioRoivalue(res.roi_average);
        setSlugdata(res.data);
        setportfolioid(res.portfolio_Id);
        setnodatafoundmsgtype(res.no_data_found_msgtype);
        setcurrentwallet(res.current_wallet);
        setLastUpdatedTiem(res.updated_on);
        setprofilename(res.portfolio_name);
        setSharpRatio(res.sharpeRatio);
        setPortfolio_7days(res.portfolio_7days);
        setPortfolio_30days(res.portfolio_30days);
        setRating(res.rating);
        // console.log(res.data)
        if (res.data.length === 0) {
          setInitialPage(true);
        }
        if (res.data.length < 10) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        //console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }, []);

  useEffect(() => {
    if (open || showModal) {
      fetchDropdownOptions();
    }
  }, [open, showModal]);

  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

    getFetchdropdown({
      user_id: userData.user_id,

      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        const newOptions = res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(newOptions);
        setuser_role(res.user_role);
        console.log(res);
      } else {
        console.log("Data fething error");
      }
    });
  };




  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  const handleClick = (buySell) => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    setIsFormSubmitted(false);
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const portfolioId =
      selectedOption && !isNaN(parseInt(selectedOption.value))
        ? parseInt(selectedOption.value)
        : 0;
    const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

    addToPortfolio({
      portfolio_type_id: portfolioId,
      portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

      existing_portfolio: existingPortfolio,
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        // setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        setPortfolioTypeId(res.portfolio_type_id);
        setIsFormSubmitted(true);
        // navigate(
        //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
        //     selectedOption.label
        //   )}`
        // );
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window1 !== undefined ? () => window().document.body : undefined;

  // useEffect(() => {
  //   // Perform localStorage action
  //   const item = localStorage.getItem('userdata')
  //   if (item ==null){
  //      setredirectpage(true);
  //   }
  //   //console.log("tapan"+item);
  // }, [])
  // if (redirectpage) {

  //   return redirect('/');
  // }

  const drawer = (
    <div className="d-flex flex-column h-100" style={{overflowY:'hidden'}}>
      <SidebarMenuList/>
    </div>
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
   
    "& td, & th": {
      border: 0,

    },
  }));

  const formatDateTime = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    
    // Format the date
    const [year, month, day] = datePart.split("-");
    const monthNames = [
      "January", "February", "March", "April", "May", "June", 
      "July", "August", "September", "October", "November", "December"
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
  
    let daySuffix = "th";
    if (day % 10 === 1 && day !== "11") {
      daySuffix = "st";
    } else if (day % 10 === 2 && day !== "12") {
      daySuffix = "nd";
    } else if (day % 10 === 3 && day !== "13") {
      daySuffix = "rd";
    }
  
    const formattedDate = `${monthName} ${parseInt(day, 10)}${daySuffix}`;
  
    // Format the time
    const [hour, minute] = timePart.split(":");
    const hourInt = parseInt(hour, 10);
    const ampm = hourInt >= 12 ? "PM" : "AM";
    const formattedHour = hourInt % 12 || 12;
  
    const formattedTime = `${formattedHour}:${minute} ${ampm}`;
  
    return `${formattedDate}, ${formattedTime}`;
  };

  useEffect(() => {
    if (slugdata) {
      const sortedData = slugdata.sort((a, b) => {
        const order = {
          "Outperformer": 1,
          "Emerging Outperformer": 2,
          "Moderate Performer": 3,
          "Underperformer": 4
        };
        
        // First sort by buy_percent
        const buyPercentComparison = order[a.buy_percent] - order[b.buy_percent];
        
        if (buyPercentComparison !== 0) {
          return buyPercentComparison;
        }
  
        // If buy_percent is the same, sort by average performance
        return b.avg_change - a.avg_change;
      });
  
      // Update the state with the sorted data
      setSortedData(sortedData);
      const sortedDataSlugs = sortedData.map((item) => item.slug);
      const sortedSlugdataNew = slugdataNew.slice().sort((a, b) => {
        return sortedDataSlugs.indexOf(a.slug) - sortedDataSlugs.indexOf(b.slug);
      });
  
      // Update the state for slugdataNew with the sorted version
      setSlugdataNew(sortedSlugdataNew);
      



    }
  }, [slugdata]); // Run this effect only when slugdata changes

  const url = `/portfolio/${portfolioName}/recommendations`;


const handleAddToPortfolio = (idx) => {
  console.log("IDX value is : ", idx);

  getCryporCurrencyListHome({
    slug: idx,
  }).then((res) => {
    if (res.code === "200") {
      console.log("data got successfully", res.data);
      
      // Update slugdata
  
      setuser_role(res.user_role);

      // Use res.data directly here
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      if (userData) {
        console.log("User DAta is there");
        setSlugpopupdata({
          slugname: res.data[0].slug,
          slug: res.data[0].name,
          units: res.data[0].total_unit,
          pricetoday: res.data[0].price_today,
          logo: res.data[0].logo,
        });
        console.log("Slug Popup Data : ", res.data);
        setOpen(true);
      } else {
        window.history.pushState({}, "", "/login");
        setLoginModalOpen(true);
      }
    } else {
      console.log("Data fetching error");
    }
  });
};


  return (
    <>

<Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
           onClick={() =>
            navigate(
              `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                selectedOption.label.replace(/\s+/g, '-')
              )}`
            )
          }
          
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            marginLeft: mediaQueryVar ? "" : "0px",
            marginRight: mediaQueryVar ? "" : "0px",
            marginTop: '1%',
            maxWidth: '100%',
            minHeight: mediaQueryVar? '60%':'', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            marginBottom:mediaQueryVar?"":"0px",
            padding: mediaQueryVar?"10px":"1px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "100px" }} gutterBottom>
              Add/Reduce Units
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '10px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                  {slugpopupdata.slug}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "350px", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "345px", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 85px",
                  marginLeft: "-10px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Buy
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "10px",
                  padding: "15px 85px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Sell
              </Button>
             
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>

    
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>


    {mediaQueryVar === true ? (
    <Box
    sx={{
      flexDirection: mediaQueryVar === false ? "column" : "",
   
      display: 'flex',  height: '100vh' 
      
    }}
    className="maindiv"
  >
    <Helmet>
        <title>Portfolio Analysis</title>
</Helmet>
      <CssBaseline />
      <div className="windowheader">
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              src={window.constants.asset_path  +'/images/logo_with_bg.png'}
              width={220}
              height={50}
              alt="CWLOGO"
              style={{ margin: "10px", borderRadius: "5px" }}
            />
          </Toolbar>
        </AppBar>
      </div>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
              borderRadius: "0px 10px 10px 0px",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          padding: "2vw",
          backgroundColor: "#EDF1F6",
        }}
      >
       {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
            <div className="windowheader">
              <Toolbar />
            </div>
            <a href={`./`}>
            <Button size="small" color="primary" edge="start"  aria-label="menu"
            style={{
              borderRadius: "42px",
              padding:"10px 14px",
              backgroundColor:"#FFF",
              border:"1px solid #F0F0F0",
              width:"85px",
              height:"38px",
              marginLeft:"15px",
              marginBottom:"10px"
            }}>
            <img src={window.constants.asset_path + '/images/backarrow.svg'} alt="" /> 
            <span style={{fontSize:"14px",fontWeight:"500",color:"#727272",marginLeft:"8px",textTransform:"none"}}>Back</span>
            </Button>
            </a>
            <Box sx={{ flexGrow: 1, marginBottom: "15px" }} >
            <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: "20px",
                    marginTop: "5px",
                    
                  }}
                >

<Box
      sx={{
        backgroundColor: "#1877F2", // Background color for the container
        paddingTop: {xs:'10px', sm:'4px'},// Padding inside the container
        paddingLeft: {xs:'0px', sm:'0px'},
        paddingRight:{xs:'0px', sm:'0px'},
        paddingBottom: {xs:'0px', sm:'4px'},
        borderRadius: "50px", // Border radius for the container
        display: 'flex', // Make sure the box is flexible
        justifyContent: 'flex-start', // Center the content
        alignItems: 'center', // Center the content
        width: "408px",
        height:{xs:'44px', sm:'48px'},
        border:'none',
        marginLeft:"10px",
        marginTop:"10px"
      }}
    >
                  
                  <Tabs
  value={selectedButton}
  onChange={(event, newValue) => handleButtonClick(newValue)}
  aria-label="basic tabs example"
  sx={{
    alignSelf: mediaQueryVar === true ? "flex-start" : "",
    marginLeft: mediaQueryVar === true ? "2px" : "2px",
    borderRadius: "50px", // Adjust border radius for more rounded corners
    border: "none",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    display: 'flex', // Make sure the tabs are displayed in a row
    overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
  }}
>
  <Tab
    label="Portfolio Analysis"
    value="PortfolioGallery"
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"1.1px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"2px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'28px', sm:'36px'},
      borderRadius: selectedButton === "PortfolioGallery" ? "20px" : "20px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: {xs:'13px', sm:'17px'},
      fontStyle: "normal",
      fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
      
      backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
  <Tab
    label="Recommendations"
    value="CoinPrices"
    component={Link}
        to={url}
    sx={{
      whiteSpace: "nowrap",
      bottom: "0px",
      marginTop:"0px",
      marginBottom:"0px",
      marginLeft:"3px",
      marginRight:"3px",
      paddingBottom:"0px",
      paddingTop: "0px",
      paddingLeft:"20px",
      paddingRight: "20px",
     minHeight:"20px",
     lineHeight:{xs:'36px', sm:'36px'},
      borderRadius: selectedButton === "CoinPrices" ? "50px" : "50px", // Rounded corners for selected tab
      textTransform: "none",
      fontSize: "17px",
      fontStyle: "normal",
      fontWeight: selectedButton === "CoinPrices" ? "700" : "500", 
      
      backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
      color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
      border: 'none', // Remove border for tabs
      background: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // Blue background for unselected tab
    }}
  />
</Tabs>

</Box>

                </Grid>
            </Box>  
             
{initialPage &&   <Box
        component="main"
        sx={{
         
          flexGrow: 1,
          p: 3,
         
        }}
      >

<Grid container spacing={2}  sx={{marginTop:'0px',marginBottom:"9px", marginLeft: mediaQueryVar === false ? "-5px" : "",}}>

</Grid>
        <div style={{borderRadius:"10px",background:"white",padding:"10px"}}>
        <div>
          <h3>Portfolio</h3>
          
        </div>
        <div className="frameParent8">
          <div className="frameParent9" style={{ padding: "10px" }}>
            <img className="frameIcon6" alt="" src={window.constants.asset_path +'/images/frame20.svg'} />
            <div className="youDontHaveContainer">
            {nodatafoundmsgtype === 'zeroholding' ? (
      <strong>
        <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
        You have  0 Holdings at the Moment
        </h3>
        {/* <h3 style={{ fontWeight: "400", color: "black" }}>0 Holdings at the Moment </h3> */}
      </strong>
    ) : (
      <strong>
      <h3 className="youDontHave" style={{ fontWeight: "400", color: "black" }}>
      Create New 

      </h3>
      <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio </h3>
    </strong>
    )}
              <button
                style={{
                  backgroundColor: "#4460EF",
                  width: "200px",
                  height: "50px",
                  borderRadius: "5px",
                  marginTop: "20px",
                  border: "2px solid #4460EF",
                  color: "white",
                }}
                onClick={handleCreateNew}
              >
             Add Cryptos to this Portfolio
              </button>
            </div>
          </div>
        </div>
        </div>
      </Box>}
        {!initialPage && (
          <>
            <div style={{ border: "1px solid white",  borderRadius: "8px", padding:"32px", margin:isSmallScreen ? "0px 15px 10px 10px" :"0px 25px 10px 10px", boxShadow: "0 2px 4px rgba(0,0,0,0)", backgroundColor: "white" }}>
            
  <TableContainer style={{
  minHeight: loader ? "" : "60vh", 
  backgroundImage: loader ? "none" : "none",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  overflowX: "hidden",
  border: "1px solid #DADEDF",
  borderRadius: "5px",
  padding: 0,
  position: "relative",
}}>
  {!loader && (
    <>
      <div style={{
        backgroundImage: `url(${window.constants.asset_path}/images/default-dpa.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        filter: "blur(4px)",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
      }}></div>
      
      <div style={{ 
                    position: "absolute", 
                    top: "50%", 
                    left: "50%", 
                    transform: "translate(-50%, -50%)", 
                    borderRadius: "8px", 
                    textAlign: "center", 
                    zIndex: 1000 ,
                    background: "rgba(255, 255, 255, 0.5)", // Optional: to slightly stand out from the blurred background
      backdropFilter: "none", // Ensures the content isn't affected by the parent's blur
      padding: "20px" // Optional: to add some spacing inside the div
                  }}>
                     <style>
                       {leftRightMotion}
                     </style>
                     <div style={{ position: "relative", display: "inline-block", width: "64px", height: "64px" }}>
                       <img 
                         src={window.constants.asset_path + '/images/lens.png'}
                         alt="Searching Lens"
                         style={{
                           width: "64px", 
                           height: "64px", 
                           position: "absolute",
                           animation: `leftRightMotion 1.5s infinite ease-in-out`,
                         }}
                       />
                     </div>
                     <p style={{ marginTop: "10px", fontSize: "18px", color: "black" }}>
                     <b>Analyzing Your Portfolio</b>
                     </p>
                   </div>
     </>
    
  )}
              {loader && (
                <Table
                  className="non_responsiveTable" aria-label="customized table" 
                  style={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                    <TableRow>
                        
                    <StyledTableCell className="cryptotableviewHeader" style={{ borderRadius: "8px 0px 0px 8px", border: 0, padding: 0, width:"26%" }}>
  <button disabled  style={{ textAlign: "left", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: "12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    &nbsp;
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"12%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 5px" :"12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    7 Days
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"12%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 5px" :"12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    30 Days
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"12%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 5px" :"12px 12px", margin: "0px 0px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
    1 Year
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"15%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 5px" :"12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
  Grade
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"9%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 5px" :"12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
  Add/Remove
  </button>
</StyledTableCell>

<StyledTableCell className="cryptotableviewHeader" style={{ padding: 0, width:"7%" }}>
  <button disabled style={{ textAlign: "center", color: "#727376", fontSize: mediaQueryVar === false ? "11px" : "15px", width:"100%", height: "100%", border: "none", background: "white", padding: isSmallScreen ? "12px 8px" :"12px 12px", margin: "0px 2px 10px 2px", boxShadow: "0px 4px 10px 0.5px rgba(45, 54, 67, 0.20)" }}>
   Portfolios
  </button>
</StyledTableCell>



                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    <StyledTableRow style={{ backgroundColor: "white"}}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            color: "#1877F2",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "12px",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                         { mPortfolioName }
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px",
                            textAlign:"center"
                          }}
                        >
                          {portfolio_7days}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {portfolio_30days}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          NA
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          NA
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          NA
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "12px",
                            paddingBottom: "14px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          NA
                        </StyledTableCell>

                      </StyledTableRow>
                    {sortedData.map((obj, idx) => (
                      <StyledTableRow key={idx} style={{ backgroundColor: idx % 2 === 0 ? "#F0F0F0" : "white"}}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingLeft: "16px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "240px"
                          }}
                        >
                          <div style={{ display: "flex", flexDirection: "row" , justifyContent:"space-between"}}>
                            <div>
                              <img width={38.77} height={37} style={{borderRadius: "19px", border:"2px solid black"}} alt="" src={obj.logo} />
                            </div>
                            <div className="btcParent">
                              {obj["article_link"] && (
                                <a href={obj.article_link}>
                                  <div>{obj.slug}</div>
                                </a>
                              )}
                              {!obj["article_link"] && 
     <div className="article-link" 
     style={{
       display: "flex",
       justifyContent: "space-between",
       gap: "10px", // Gap between the items
       alignItems: "center" // Centers items vertically (optional)
     }}>
      <div>
    <a href={`../../crypto/${obj.crypto_symbol}`} style={{ color: "black" }}>
      {obj.slug}
    </a></div>
</div>
}
                          
    
     </div>
     <div>
  {obj.category && ( // Check if obj.category is not null or empty
    <Link style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
          borderRadius: "15px",
          padding: "2px 4px",
          fontSize: "12px",
          fontWeight: "500",
          color: "#000",
          marginTop :"5px",
          marginBottom: "0px",
          width:isSmallScreen?"90px":"110px",
          textAlign:"center",
          wordWrap: "break-word",
         overflow: "hidden",
          whiteSpace: "normal",

        }}
      >
        {obj.category}
      </Box>
    </Link>
  )}
</div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px",
                            textAlign:"center"
                          }}
                        >
                          {obj.sevend_change}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.thirtyd_change}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.yeard_change}
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: isSmallScreen? "10px":"12px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                       
                          <div>
                             <div style={{ display: "flex", flexDirection: "column", alignItems:"center" }}>
                             {
    obj.buy_percent === 'Outperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding: isSmallScreen ? "3px " : "5px", borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "90%" }}>Outperformer</button>
  ) : obj.buy_percent === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: isSmallScreen ? "3px" : "5px", borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "90%",fontSize: isSmallScreen ? "10px" : "12px"}}>Outperformer</button>
) :
  obj.buy_percent === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: isSmallScreen ? "2px" : "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "90%",fontSize: isSmallScreen ? "10px" : "12px"}}>Moderate Performer</button>
  )  : obj.buy_percent === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding: isSmallScreen ? "3px " : "5px", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "90%" }}>Underperformer</button>
) : null}

                             </div>
                         </div>
                         
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          <button 
                          onClick={() =>  handleAddToPortfolio(obj.slug)}
                          style={{
                            border:"2px solid #1877F2",
                            borderRadius:"17px",
                            padding:"2px 8px",
                            color : "#1877F2",
                            backgroundColor :"white",
                            fontSize:"12px"
                          }}>
                         Add/Remove
                         </button>
                        </StyledTableCell>
                        <StyledTableCell
                          className="one"
                          style={{
                            borderBottom: "none",
                            color: "#000",
                            fontFamily: "",
                            fontSize: "15px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign:"center",
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            borderRight: "1px solid #DADEDF",
                            width: "239px"
                          }}
                        >
                          {obj.holdings}
                        </StyledTableCell>

                      </StyledTableRow>
                    ))}
                  </TableBody>
                  
                  
                </Table>
                )}
                {slugdata.length === 0 && loader === true && (
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h4>No Data Found</h4>
                  </div>
                )}
              </TableContainer>
              
            </div>
            <Typography
    variant="outlined"
    style={{
      
      color: "#1877F2",

      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontWeight: "700",
      fontSize: "18px",
      padding: "5px 20px",
     
      marginLeft:"-15px",
      marginTop:"4%",
      marginBottom:"10%",
    }}

  >
    Sharpe Ratio: {sharpratio} ({rating})
  </Typography>
            </>
        )
      }
      
        {loaderForSymbolLink===true &&
         <div >
              <CircularProgress style={{position:"fixed",top:"40vh",left:"45vw"}}/>
          </div> 
          }
      </Box>
    </Box>
    ) : (
      <Box
      sx={{
        display: "flex",
        height: '100vh' 
      }}
      className="maindiv"
    >  
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "white",
              color: "#727376",
             
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        
        component="main"
        sx={{
      flexGrow: 1,
      p: 2,
      backgroundColor: "white",
      paddingBottom: "2vw",
      minHeight: "100vh",
      overflowY:"auto"
        }}
      >
     {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
           
     <div style={{ 
  display: "flex", 
  flexDirection: "row", // Change to row to keep tabs and icon in the same line
  alignItems: "center", // Align items vertically in the center
  marginTop: "0px", 
  marginBottom: "10px",
  marginLeft: "5px",
}}>
  <Box
    sx={{
      backgroundColor: "#1877F2", // Background color for the container
      padding: "3.105px", // Padding inside the container
      borderRadius: "38.042px", // Border radius for the container
      display: 'flex', // Ensure that the box is flexible
      alignItems: 'center', // Center the content vertically
      width: mediaQueryVar2?"300px":"330px", // Full width on extra small screens
      height: "36.63px",
      border: 'none',
      marginLeft: "-12px",
      marginTop: "3%",
      marginBottom:"2%"
    }}
  >
    <Tabs
      value={selectedButton}
      onChange={(event, newValue) => handleButtonClick(newValue)}
      aria-label="basic tabs example"
      sx={{
        flexGrow: 1, // Make the tabs take up remaining space
        "& .MuiTabs-indicator": {
          display: "none",
        },
        display: 'flex', // Ensure that the tabs are displayed in a row
        overflow: 'hidden', // Prevent the tabs from spilling out of the border radius
      }}
    >
      <Tab
        label="Portfolio Analysis"
        value="PortfolioGallery"
        
        sx={{
          whiteSpace: "nowrap",
          borderRadius: "20.962px",
          minHeight: "31.5px",
          height: "31.5px",
          fontWeight: selectedButton === "PortfolioGallery" ? "700" : "500",
          marginLeft: "1px",
          paddingLeft: "-15px",
          marginTop: "8px",
          border: "1px solid black",
          marginBottom: "-5px",
          fontSize: mediaQueryVar2?"13px":"15px",
          marginRight: "-15px",
          backgroundColor: selectedButton === "PortfolioGallery" ? "#FFFFFF" : "transparent", // White background for selected tab
          color: selectedButton === "PortfolioGallery" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
          textTransform: "none",
          border: "none"
        }}
      />
      <Tab
        label="Recommendations"
        value="CoinPrices"
        component={Link}
        to={url}
        sx={{
          whiteSpace: "nowrap",
          borderRadius: "20.962px",
          minHeight: "31px",
          height: "31px",
          marginLeft: "5px",
          marginTop: "8px",
          border: "1px solid black",
          fontWeight: selectedButton === "CoinPrices" ? "700" : "500",
          marginRight: "0px",
          fontSize: mediaQueryVar2?"13px":"15px",
          paddingRight: "-8px",
          textTransform: "none",
          backgroundColor: selectedButton === "CoinPrices" ? "#FFFFFF" : "transparent", // White background for selected tab
          color: selectedButton === "CoinPrices" ? "#000000 !important" : "#FFFFFF", // Black text for selected tab
          border: 'none', // Remove border for tabs
        }}
      />
    </Tabs>
  </Box>
  
  <IconButton
    color="inherit"
    aria-label="open drawer"
    edge="end"
    onClick={handleDrawerToggle}
    sx={{
      marginLeft: "auto", // Push the icon button to the far right
      marginRight: "10px",
      marginBottom: "10px",
      display: { sm: "block" },
      marginTop:"2%" // Show on small screens
    }}
  >
    <img 
      src={window.constants.asset_path + '/images/menubar.svg'} 
      alt="Menu Icon" 
      style={{ width: "24px", height: "24px",marginTop:"5px" }} 
    />
  </IconButton>
</div>
   

    {initialPage && (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          overflowY:"auto"
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: "0px",
            marginBottom: "9px",
            marginLeft: mediaQueryVar === false ? "-5px" : "",
          }}
        ></Grid>
        <div style={{ borderRadius: "10px", background: "white", padding: "10px" }}>
          <div>
            <h3>Portfolio</h3>
          </div>
          <div className="frameParent8">
            <div className="frameParent9" style={{ padding: "10px" }}>
              <img
                className="frameIcon6"
                alt=""
                src={window.constants.asset_path + "/images/frame20.svg"}
              />
              <div className="youDontHaveContainer">
                {nodatafoundmsgtype === "zeroholding" ? (
                  <strong>
                    <h3
                      className="youDontHave"
                      style={{ fontWeight: "400", color: "black" }}
                    >
                      You have 0 Holdings at the Moment
                    </h3>
                    {/* <h3 style={{ fontWeight: "400", color: "black" }}>0 Holdings at the Moment </h3> */}
                  </strong>
                ) : (
                  <strong>
                    <h3
                      className="youDontHave"
                      style={{ fontWeight: "400", color: "black" }}
                    >
                      Create New
                    </h3>
                    <h3 style={{ fontWeight: "400", color: "black" }}>Portfolio</h3>
                  </strong>
                )}
                <button
                  style={{
                    backgroundColor: "#4460EF",
                    width: "200px",
                    height: "50px",
                    borderRadius: "5px",
                    marginTop: "20px",
                    border: "2px solid #4460EF",
                    color: "white",
                  }}
                  onClick={handleCreateNew}
                >
                  Add Cryptos to this Portfolio
                </button>
              </div>
            </div>
          </div>
        </div>
      </Box>
      
    )}
    {!initialPage && (
      <>
        {loader === false && (
          <>
          <div style={{
            backgroundImage: `url(${window.constants.asset_path}/images/default-dpa-mobile.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            filter: "blur(4px)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 0,
            margin:"2%"
          }}></div>
          
          <div style={{ 
                        position: "absolute", 
                        top: "50%", 
                        left: "50%", 
                        transform: "translate(-50%, -50%)", 
                        borderRadius: "8px", 
                        textAlign: "center", 
                        zIndex: 1000 ,
                        background: "rgba(255, 255, 255, 0.5)", // Optional: to slightly stand out from the blurred background
          backdropFilter: "none", // Ensures the content isn't affected by the parent's blur
          padding: "20px" // Optional: to add some spacing inside the div
                      }}>
                         <style>
                           {leftRightMotion}
                         </style>
                         <div style={{ position: "relative", display: "inline-block", width: "64px", height: "64px" }}>
                           <img 
                             src={window.constants.asset_path + '/images/lens.png'}
                             alt="Searching Lens"
                             style={{
                               width: "64px", 
                               height: "64px", 
                               position: "absolute",
                               animation: `leftRightMotion 1.5s infinite ease-in-out`,
                             }}
                           />
                         </div>
                         <p style={{ marginTop: "10px", fontSize: "18px", color: "black" }}>
                         <b>Analyzing Your Portfolio</b>
                         </p>
                       </div>
         </>
        )}
        {loader && (
          <Box style={{marginBottom:"70px",
            paddingBottom:"12%",
            overflowY:"scroll",
            scrollBehavior: "smooth",
            "&::-webkit-scrollbar": {
      display: "none",
    },
    // Hides scrollbar for Firefox
    scrollbarWidth: "none", }}>
      <Box
   
   style={{
     padding: "0",
     backgroundColor: "white",
     borderRadius: "6px",
     marginTop:"15px",
     marginBotton: "10px",
     border: "1px solid #DADEDF",
     marginLeft:"3px",
     marginRight:"5px"
   }}
 >
<Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
<Typography style={{marginRight: "16px",color:"#1877F2",  margin: "12px", fontWeight:"700" }}>
{ mPortfolioName }
</Typography>
</Box>

<Box sx={{background:"#EBE9EB", borderRadius:"6px", padding: "10px"}}>

<Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" ,borderBottom:"2px #DFDFDF solid"}}>
<Box sx={{ flex: 1, marginRight: "8px" }}>
<div style={{ display: "flex", justifyContent: "", margin: "4px",marginTop:"-5px" }}>
<span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Last 7 Days:</span>
<span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio_7days}</span>
</div>
</Box>
<Box sx={{ flex: 1 }}>
<div style={{ display: "flex", justifyContent: "space-between", margin: "4px",marginTop:"-5px" }}>
<span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"20px" }}>Last 30 Days:</span>
<span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{portfolio_30days}</span>
</div>
</Box>
</Box>

<Box sx={{ display: "flex", justifyContent: "space-between",paddingBottom:"10px", marginBottom: "0px" ,borderBottom:"2px #DFDFDF solid"}}>
<Box sx={{ flex: 1, marginRight: "8px" }}>
<div style={{ display: "flex", justifyContent: "", margin: "4px",marginBottom:"-5px" }}>
<span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Last 1 Year:</span>
<span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>NA</span>
</div>
</Box>
<Box sx={{ flex: 1 }}>
<div style={{ display: "flex", justifyContent: "space-between", margin: "4px" ,marginBottom:"-5px"}}>
<span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"10px", marginLeft:"20px" }}>Portfolios:</span>
<span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>NA</span>
</div>
</Box>
</Box>

<Box sx={{ display: "flex", justifyContent: "space-between" }}>
<Box sx={{ flex: 1, marginRight: "8px" }}>
<div style={{ display: "flex", justifyContent: "", margin: "4px",marginBottom:"-5px" }}>
<span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Grade:</span>
<span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>NA</span>
</div>
</Box>

</Box>

</Box>

 </Box>
            {slugdata.map((obj, idx) => (
               <React.Fragment key={idx}>
              <Box
               
                style={{
                  padding: "0",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  marginTop:"15px",
                  marginBotton: "10px",
                  border: "1px solid #DADEDF",
                  marginLeft:"3px",
                  marginRight:"5px",
                  filter: !userData && idx >= 2 ? "blur(4px)" : "none",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
  <img
    src={obj.logo}
    alt={obj.slug}
    width={40} // You can keep this for consistency
    height={40}
    style={{ borderRadius: "19px", marginRight: "16px", border: "2px solid #000", margin: "8px" }}
  />
  
  <Typography variant="h6" style={{ fontSize: "18px", fontWeight: "500" }}>
    <a href={`../../crypto/${obj.crypto_symbol}`} style={{ color: "black" }}>
      {obj.slug}
    </a>
  </Typography>

  <button 
                          onClick={() => handleAddToPortfolio(obj.slug)}
                          style={{
                            border:"2px solid #1877F2",
                            borderRadius:"17px",
                            padding:"2px 8px",
                            color : "#1877F2",
                            backgroundColor :"white",
                            fontSize:"12px",
                            marginLeft:"auto",
                            marginRight:"5px"
                        
                          }}>
                         Add/Remove
                         </button>

</Box>


<Box sx={{background:"#EBE9EB", borderRadius:"6px", padding: "10px"}}>

  <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0px" ,borderBottom:"2px #DFDFDF solid"}}>
    <Box sx={{ flex: 1, marginRight: "8px" }}>
      <div style={{ display: "flex", justifyContent: "", margin: "4px",marginTop:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Last 7 Days:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.sevend_change}</span>
      </div>
    </Box>
    <Box sx={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px",marginTop:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"20px" }}>Last 30 Days:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.thirtyd_change}</span>
      </div>
    </Box>
  </Box>

  <Box sx={{ display: "flex", justifyContent: "space-between",paddingBottom:"10px", marginBottom: "0px" ,borderBottom:"2px #DFDFDF solid"}}>
    <Box sx={{ flex: 1, marginRight: "8px" }}>
      <div style={{ display: "flex", justifyContent: "", margin: "4px",marginBottom:"-5px" , marginTop:"10px"}}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px" }}>Last 1 Year:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.yeard_change}</span>
      </div>
    </Box>
    <Box sx={{ flex: 1 }}>
      <div style={{ display: "flex", justifyContent: "space-between", margin: "4px",marginTop:"10px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginLeft:"20px" }}>Portfolios:</span>
        <span style={{ textAlign: "right", fontWeight: "500", fontSize: "14px" }}>{obj.holdings}</span>
      </div>
    </Box>
  </Box>

  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Box sx={{ flex: 1, marginRight: "8px" }}>
      <div style={{ display: "flex", justifyContent: "", margin: "4px",marginBottom:"-5px" }}>
        <span style={{ textAlign: "left", fontSize: "13px", color: "#76787A", fontWeight: "500",marginRight:"10px", marginTop:"2px" }}>Grade:</span>
        <div style={{ display: "flex", flexDirection: "column", alignItems:"center", fontSize:"14px" }}>
                             {obj.buy_percent === 'Outperformer' ? (
    <button disabled style={{ marginLeft:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%" }}>Outperformer</button>
) : obj.buy_percent === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: isSmallScreen ? "3px" : "5px", borderRadius: "5px",backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize: isSmallScreen ? "15px" : ""}}>Outperformer</button>
) : 


obj.buy_percent === 'Moderate Performer' ? (
    <button disabled style={{ marginLeft:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "100%" }}>Moderate Performer</button>
) : obj.buy_percent === 'Underperformer' ? (
    <button disabled style={{ marginLeft:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "100%" }}>Underperformer</button>
) : null}

                             </div>
                             {obj.category && ( // Check if obj.category is not null or empty
  <Link
    style={{ textDecoration: 'none', marginLeft: "auto", marginRight: "-10px" }} // Ensures the link stays at the rightmost
  >
    <Box
      sx={{
        background: "var(--linek, linear-gradient(91deg, #9AFFF9 18.86%, #FFF06D 100.02%))",
        borderRadius: "15px",
        padding: "2px 11px",
        fontSize: "12px",
        fontWeight: "500",
        color: "#000",
        marginBottom: "0px",
        minWidth:isSmallScreen?"80px":"80px",
        marginTop:"5px",
        textAlign : "center"
      }}
    >
      {obj.category}
    </Box>
  </Link>
)}
                       
      </div>
    </Box>
    
  </Box>

</Box>

              </Box>
              {!userData && idx === 2 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px", // Add spacing between the button and the next box
            }}
          >
            <Button
              onClick={() => {
                const originalUrl = window.location.pathname + window.location.search;
                sessionStorage.setItem("originalUrl", originalUrl);
                console.log("original url: " + originalUrl);
                handleLoginClick();
              }}
              style={{
                color: "white",
                backgroundColor: "#1877F2",
                textTransform: "none",
                width: mediaQueryVar ? "auto" : "200px",
                height: mediaQueryVar ? "auto" : "45px",
                fontWeight: "600",
                fontSize: "18px",
                borderRadius: "30px",
                padding: "8px 24px",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                border: "none",
                cursor: "pointer",
                marginTop: "-100px",
              }}
            >
              Login/Signup
            </Button>
          </div>
        )}
        </React.Fragment>
            ))}
            <Typography
    variant="outlined"
    style={{
      
      color: "#1877F2",

      textTransform: "none",
      fontFamily: "Satoshi, sans-serif",
      fontWeight: "700",
      fontSize: "16px",
      padding: "5px 20px",
     
      marginLeft:"-15px",
      marginTop:"8%",
      marginBottom:"10%",
    }}

  >
    Sharpe Ratio: {sharpratio} ({rating})
  </Typography>
          </Box>
        )}
      </>
    )}
  </Box>
  {mediaQueryVar === false && (
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "105%",
            zIndex: "100",
            marginLeft:"-10px"
          }}
        >
          <BottomNavigation showLabels>
  <BottomNavigationAction
        label="Gallery"
        onClick={() =>
          navigate("/portfolio-gallery")
        }
        icon={<img src={loc === '/portfolio-gallery' ? "/images/dashboardS.svg" : "/images/dashboardU.svg"} />}
        
      />
      <BottomNavigationAction
        label="My Portfolios"
        onClick={() => 
          navigate("/my-portfolio")
        }
        icon={<img src={loc === '/my-portfolio' ? "/images/portfolioS.svg" : "/images/portfolioU.svg"}  />}
      />
     <BottomNavigationAction
       label="Profile"
       onClick={() => 
      navigate("/account")
  }
  icon={<img src={loc==='/account'? "/images/dollarS.svg":"/images/dollarU.svg"} width={22} height={22} alt="Icon" style={{marginBottom : "-3px"}}/>}
/>
</BottomNavigation>
        </div>
      )}
</Box>

    )}
  </>
  );
}

export default PerformanceSummaryShare;