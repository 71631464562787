import React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import LoginPopUp from "../loginpopup.js";

import CreatableSelect from "react-select/creatable";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  ButtonGroup,
  BottomNavigation,
  Paper,
  Chip,
  CardHeader,
  CardMedia,
  CardContent,
  Popover,
  Autocomplete,
  FormGroup
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { Helmet } from 'react-helmet';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import TableRow from "@mui/material/TableRow";
import { Button, Grid, TextField, Card, Checkbox } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ListSubheader from "@mui/material/ListSubheader";
import ClearIcon from "@mui/icons-material/Clear"; // Import ClearIcon
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
 
} from "@mui/material";
import {
  getCryporCurrencyListHome,
  getPortfolioForGallery,
  getSymbolbyPortfolioid,
} from "../../apihelper/homepage";
import "./home.css";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import BarChartIcon from "@mui/icons-material/BarChart";
// import LOGO from './images/logo.png'
import CloseIcon from "@mui/icons-material/Close";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material"; // Import Material-UI components

import { completeGoogleLogin } from "../../apihelper/login";
import CircularProgress from "@mui/material/CircularProgress";
import SidebarMenuList from "../../includes/sidebarMenuList.jsx";
import { getsymbollink } from "../../apihelper/getsymbollink.js";
import { addToPortfolio, copyToPortfolio } from "../../apihelper/portfolio";
import {
  getTotalunit,
  getFetchdropdown,
} from "../../apihelper/cryptocurrencylist";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Modal from "@mui/material/Modal";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Dialog from "@mui/material/Dialog";

const drawerWidth = 292;
// import { useParams } from "react-router-dom";
export default function Home(props) {
  const { loading = true } = props;
  const { filter } = props; // Destructure filter from props
  const location = useLocation(); 
  const loc = location.pathname;
  const { onButtonClick } = props;
  const [selectedmenu, setselectedmenu] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [portfolioTypeId, setPortfolioTypeId] = useState("");
  const [options, setOptions] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [newOptionValue, setNewOptionValue] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [Usablewallet, setUsablewallet] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [user_role, setuser_role] = useState("");
  const [showModal, setShowModal] = useState(false); // Change the state name to showModal

  const [value, setValue] = React.useState(0);
  const [errorMessage, setErrorMessage] = useState('');


  const handleChange = async (selectedOption, slug) => {
    if (selectedOption && selectedOption._isNew_) {
      setIsDialogOpen(true);
      console.log("Creating new option:", selectedOption.value);
      setSelectedOption(null);
      setdataslug(slug);
    } else if (!selectedOption) {
      // Handle the case when the selection is cleared
      setSelectedOption(null);
      setShowAlert(false);
      // Perform any other necessary actions
      return;
    } else {

      const selectedValue = selectedOption.value;
      if (selectedValue.includes('-')) {
        setErrorMessage('Hyphens are not allowed in the option.'); // Set error message
        setShowAlert(true); // Show the alert
        return; // Abort further processing
      }
      setShowAlert(false);
      setSelectedOption(selectedOption);
      const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
      getTotalunit({
        user_id: userData.user_id,
        slug: slug, // Assuming value contains the slug
        portfolio_type_id: selectedOption.value, // Adjust as needed
        Token: userData.token,
      }).then((res) => {
        if (res.code === "200") {
          setTotalUnits(res.total_uint);
          setUsablewallet(res.wallet_amount);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  };
  const CustomDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiBackdrop-root": {
      // Customize backdrop styles here
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity
    },
  }));

  const fetchDropdownOptions = async () => {
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));

    getFetchdropdown({
      user_id: userData.user_id,

      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        const newOptions = res.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(newOptions);
        setuser_role(res.user_role);
        //console.log(res.user_role);
      } else {
        console.log("Data fething error");
      }
    });
  };

  const handleCreate = async (inputValue) => {
    setTotalUnits(0);
    setUsablewallet("");

    if (options.find((option) => option.value === inputValue)) {
      return; // Exit early if the option already exists
    }
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    //console.log(inputValue);
    const newOption = { value: inputValue, label: inputValue };
    const newOptions = [...options, newOption]; // Create a new array with the existing options plus the new one
    setOptions(newOptions); // Update the options state
    const createdOption = newOptions.find(
      (option) => option.value == inputValue
    );
   // console.log(createdOption);
    if (createdOption) {
      handleChange(createdOption);
    }
  };

  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const { window1 } = props;
  const [alignment, setAlignment] = React.useState("left");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showloginpage, setShowloginpage] = useState(false);

  const [slugdata, setSlugdata] = useState([]);

  const [index, setIndex] = useState(0);
  const [addMoreLoader, setAddMoreLoader] = useState(false);

  const [loaderbutton, setLoaderButton] = useState(true);
  const [dataslug, setdataslug] = useState("");
  const [loadingForLogin, setLoadingForLogin] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTiem] = useState("--");
  const [loaderForSymbolLink, setLoaderForSymbolLink] = useState(false);
  const [unit, setUnit] = useState("");
  const [buySell, setbuySell] = React.useState("");
  const [price, setPrice] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedDate, handleDateChange] = useState("");
  const [msgforUpdatePortfolio, setMsgforUpdatePortfolio] =
    useState("loading...");
  const [severity, setSeverity] = useState("info");
  const handleClose = () => setOpen(false);
  const [slugpopupdata, setSlugpopupdata] = useState({
    slugname: "",
    slug: "",
    units: 0,
  });
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [hashtagData, setHashtagData] = useState('');

  const [snackbaropen, setSnackbaropen] = React.useState(false);
  const closesnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbaropen(false);
  };
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const mediaQueryVar = useMediaQuery(
    json2mq({
      minWidth: 900,
    })
  );
  const mediaQueryVar2 = useMediaQuery(
    json2mq({
      maxWidth: 400,
    })
  );
  const mediaQueryVar5 = useMediaQuery(
    json2mq({
      minWidth: 1900,
    })
  );
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className="d-flex flex-column h-100" style={{ overflowY: "hidden" }}>
      <SidebarMenuList />
    </div>
  );

  const handleButtonClick = (buttonName) => {
    // Pass the buttonName to the parent component's function
    onButtonClick(buttonName);
  };

  const handleClick = (buySell) => {
    setSnackbaropen(true);
    setOpen(true);
    setSeverity("info");
    setIsFormSubmitted(false);
    // setSnackbarcolor("success");
    // setSnackbarmessage("Data saved successfully");
    setMsgforUpdatePortfolio("loading...");
    //console.log(price, selectedDate, buySell, unit);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    const portfolioId =
      selectedOption && !isNaN(parseInt(selectedOption.value))
        ? parseInt(selectedOption.value)
        : 0;
    const existingPortfolio = portfolioId > 0 ? "yes" : "no"; // Determine existing_portfolio based on portfolioId

    addToPortfolio({
      portfolio_type_id: portfolioId,
      portfolio_name: selectedOption ? selectedOption.label : "", // Ensure selectedOption is defined

      existing_portfolio: existingPortfolio,
      user_id: userData.user_id,
      slug: slugpopupdata.slugname,
      unit: unit,
      acquisition_price: price,
      date_time: selectedDate,
      type: buySell,
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        // setOpen(false);
        setSeverity("info");
        //console.log(res.message);
        setMsgforUpdatePortfolio(res.message);
        setPortfolioTypeId(res.portfolio_type_id);
        setIsFormSubmitted(true);
        // navigate(
        //   `/my-portfolio/${res.portfolio_type_id}/${encodeURIComponent(
        //     selectedOption.label
        //   )}`
        // );
      } else {
        setSeverity("error");
        // setOpen(true)
        setMsgforUpdatePortfolio(res.message);
      }
    });
  };

  useEffect(() => {
    setShowloginpage(true);
    const search = window.location.search;
    const params = new URLSearchParams(search);
   // console.log(params);
    if (params) {
      const googleLoginCode = params.get("code");
      if (googleLoginCode) {
        setLoadingForLogin(true);
        completeGoogleLogin(googleLoginCode).then((data) => {
          //console.log(data);
          if (data.code == "200") {
            //console.log(data.message);
            localStorage.setItem(
              "cw_portfolio_user",
              JSON.stringify(data.data)
            );
            const portfolio_userId = localStorage.getItem("portfolio_userId");
            if (portfolio_userId) {
              //  navigate(portfolio/${portfolio_userId}, { replace: true });
              window.location.replace(
                `${process.env.REACT_APP_HOME_URL}portfolio/${portfolio_userId}`
              );
              localStorage.removeItem("portfolio_userId");
            } else {
              if (data.data.portfolio_form_redirect === "yes") {
                //navigate("/profile", { replace: true });
                window.location.replace(
                  `${process.env.REACT_APP_HOME_URL}profile`
                );
              } else {
                window.location.replace(
                 `${process.env.REACT_APP_HOME_URL}cryptocurrencylist`
                );
                //navigate("/cryptocurrencylist", { replace: true });
              }
            }
          } else {
            console.log("GOOGLE LOGIN FAILED");
            // TODO
            // pop up the error
          }
        });
      } else {
        setShowloginpage(false);
      }
    }
  });

  useEffect(() => {
    setIndex(0);
    setLoader(false);
    if ((filter.length < 1) & (filter.length >= 1)) {
      setLoader(true);
      setAddMoreLoader(false);
    } else {
      getCryporCurrencyListHome({
        slug: filter,
        start_index: 0,
        limit: `${20}`,
      }).then((res) => {
        if (res.code === "200") {
          console.log("data got successfully");
         // console.log(res);
          setSlugdata(res.data);
          setuser_role(res.user_role);
          setLastUpdatedTiem(res.current_price_updated_on);
          const updatedSlugData = res.data.map((item) => ({
            ...item,
            backgroundColor: getBackgroundColor(item), // Calculate background color dynamically
          }));

          if (res.data.length < 20) {
            //console.log("change Loaderbutton");
            setLoaderButton(false);
          } else {
            setLoaderButton(true);
          }
          setLoader(true);
          setAddMoreLoader(false);
        } else {
          console.log("Data fething error");
        }
        // console.log(slugdata)
      });
    }
  }, [filter]);
  const getBackgroundColor = (item) => {
    const textColor = item.outlook_for_today[0].textcolor;
    if (textColor === "#ff6900") {
      return "rgba(236, 82, 29, 0.16)";
    } else if (textColor === "#00d084") {
      return "rgba(0, 185, 174, 0.16)";
    } else if (textColor === "#cf2e2e") {
      return "rgba(206, 45, 79, 0.16)";
    }
    // Add more conditions for other text colors if needed
    // If the color doesn't match any specific condition, return a default color
    return "defaultBackgroundColor";
  };

  useEffect(() => {
    if (open || showModal) {
      fetchDropdownOptions();
    }
  }, [open, showModal]);
  console.log(hashtagData);
  console.log("index:"+ index);
  

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const open1 = Boolean(anchorEl);
  const [loading1, setLoading1] = useState(true);
  const [error, setError] = useState(null);

  const fetchDistinctCategories = async () => {
    try {
      const response = await fetch('https://crowdwisdom.live/wp-json/api/v1/getDistinctCategories', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      console.log(result);
      if (result.code === '200') {
        setCategories(result.data); // Set categories in state
      } else {
        setError(result.message);
      }
    } catch (err) {
      setError('An error occurred while fetching categories.');
    } finally {
      setLoading1(false);
    }
  };

  // Separate useEffect for fetching categories
  useEffect(() => {
    fetchDistinctCategories();
  }, []); // This runs only once after the component mounts




  const [showAlert, setShowAlert] = useState(false);
  const handleChange2 = (e) => {
    const value = e.target.value;
    // Check if the input contains a hyphen
    if (value.includes('-')) {
      setShowAlert(true); // Show the alert if there's a hyphen
    } else {
      setShowAlert(false); // Hide the alert if there's no hyphen
      setNewOptionValue(value); // Update the state with the new value
    }
  };
  const loadMore = () => {
    // setLoader(false);
    setAddMoreLoader(true);
    setIndex(index + 20);
   // console.log(index);
   console.log("hashtag:"+hashtagData);
    getCryporCurrencyListHome({
      slug: filter,
      start_index: index + 20,
      limit: `${20}`,
      category: selectedCategory,
      hashtagdata: hashtagData,
      selected_filters: selectedFilters
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setSlugdata([...slugdata, ...res.data]);
        const updatedSlugData = res.data.map((item) => ({
          ...item,
          backgroundColor: getBackgroundColor(item), // Calculate background color dynamically
        }));
        if (res.data.length < 20) {
          //console.log("change Loaderbutton");
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const handleAddToPortfolio = (idx) => {
    console.log("IDX in crypto screenr:",idx);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    if (userData) {
      setSlugpopupdata({
        slugname: slugdata[idx].slug,
        slug: slugdata[idx].name,
        units: slugdata[idx].total_unit,
        pricetoday: slugdata[idx].price_today,
        logo: slugdata[idx].logo,
      });
      setOpen(true);
    } else {
    window.history.pushState({}, "", "/login");
    
    // Open the login modal
    setLoginModalOpen(true);
    }
  };

  const handleModalClose = () => {
    // Close the modal and reset the URL to the current page
    setLoginModalOpen(false);
    navigate(location.pathname, { replace: true });
};

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  var rows = [],
    i = 0,
    len = 15;
  while (++i <= len) rows.push(i);

  const container =
    window1 !== undefined ? () => window1().document.body : undefined;

  const handleDialogSubmit = () => {
    // Handle submitting the dialog (e.g., creating the new option)
    console.log("Creating new option:", newOptionValue);

    // Update options state with the new option
    const newOption = { value: newOptionValue, label: newOptionValue };
    setOptions([...options, newOption]);
    setSelectedOption(newOption);
    // Close the dialog
    setIsDialogOpen(false);

    // setSelectedOption(selectedOption);
    const userData = JSON.parse(localStorage.getItem("cw_portfolio_user"));
    getTotalunit({
      user_id: userData.user_id,
      slug: dataslug, // Assuming value contains the slug
      portfolio_type_id: newOption.value, // Adjust as needed
      Token: userData.token,
    }).then((res) => {
      if (res.code === "200") {
        setTotalUnits(res.total_uint);
        setNewOptionValue("");
        setUsablewallet(res.wallet_amount);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  };
 // Step 1: Create a shallow copy of slugdata
const slugdata1 = slugdata.slice();

// Step 2: Sort slugdata1 based on total_roi_value
const sortedSlugdata = slugdata1;

const formatToNonZero = (number) => {
  number = parseFloat(number);
  
  if (number >= 1) {
    return number.toFixed(2);
  } else if (number >= 0.1) {
    return number.toFixed(1);
  } else if (number >= 0.01) {
    return number.toFixed(4);
  } else if (number >= 0.001) {
    return number.toFixed(5);
  } else if (number >= 0.0001) {
    return number.toFixed(6);
  } else if (number >= 0.00001) {
    return number.toFixed(7);
  } else if (number >= 0.000001) {
    return number.toFixed(8);
  } else if (number >= 0.0000001) {
    return number.toFixed(9);
  } else if (number >= 0.00000001) {
    return number.toFixed(10);
  } else if (number >= 0.000000001) {
    return number.toFixed(11);
  }
  return number.toFixed(1);
};

const filterData = [
  { categories: "RSI", subOptions: ["Bullish", "Bearish"] },
  { categories: "MACD", subOptions: ["Bullish", "Bearish"] },
  { categories: "Bollinger Bands", subOptions: ["Bullish", "Bearish"] },
  { categories: "Fibonacci Retracement", subOptions: ["Bullish", "Bearish"] },
  { categories: "On Balance Volume", subOptions: ["Bullish", "Bearish"] },
  { categories: "Heads and Shoulders", subOptions: ["Bullish", "Bearish"] },
  { categories: "Triangles", subOptions: ["Bullish", "Bearish"] },
  { categories: "Influencers", subOptions: ["Bullish", "Bearish"] },

];

const [filterAnchor, setFilterAnchor] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]); // Store selected filters as objects

  const handleFilterOpen = (event) => setFilterAnchor(event.currentTarget);
  const handleFilterClose = () => setFilterAnchor(null);

  const isFilterOpen = Boolean(filterAnchor);

  const handleCheckboxChange = (categories, subOption) => {
    const filterKey = `${categories} ${subOption}`; // Create unique key like 'Category SubOption'
    
    setSelectedFilters((prev) => {
      // Check if the current filter key is already selected
      const isCurrentlySelected = prev.includes(filterKey);
  
      // If the current option is already selected, deselect it
      if (isCurrentlySelected) {
        return prev.filter((item) => item !== filterKey);
      }
  
      // Create a new array that deselects any other option in the same category
      const updatedFilters = prev.filter(item => !item.startsWith(categories));
  
      // Add the newly selected option
      return [...updatedFilters, filterKey];
    });
  };
  

  const clearAllFilters = () => {
    setSelectedFilters([]); // Clear all selected filters
    handleFilterClose(); // Close the popover
  };
  // Debug: Log selected filters to check the values
  console.log("Selected Filters:", selectedFilters);


  useEffect(() => {
    console.log(selectedCategory);
    console.log("hashtag:"+hashtagData);
    getCryporCurrencyListHome({
      slug: filter,
      start_index: `${index}`,
      limit: `${20}`,
      category: selectedCategory,
      hashtagdata: hashtagData,
      selected_filters: selectedFilters
    }).then((res) => {
      if (res.code === "200") {
        console.log("data got successfully");
        setSlugdata(res.data);
        console.log(res.data);
        setuser_role(res.user_role);
        const updatedSlugData = res.data.map((item) => ({
          ...item,
          backgroundColor: getBackgroundColor(item), // Calculate background color dynamically
        }));

        if (res.data.length < 20) {
          setLoaderButton(false);
        } else {
          setLoaderButton(true);
        }
        setLoader(true);
        setAddMoreLoader(false);
      } else {
        console.log("Data fething error");
      }
      // console.log(slugdata)
    });
  }, [selectedCategory, hashtagData, selectedFilters]);

  return (
    <>{mediaQueryVar === true? (
    <Box
      sx={{
        flexDirection: mediaQueryVar === false ? "column" : "",

        display: "flex",
        height: "100vh",
      }}
      className="maindiv"
    >
      <Helmet>
        <title>Crypto Screener</title>
</Helmet>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "white",
        }}
        className="maindiv"
      >
        {isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      
      <div style={{ fontFamily:"Satoshi, sans-serif",display: 'flex', justifyContent: 'flex-end', marginRight:"40px", marginTop:"-70px" }}>
      <Button
        startIcon={<img src="images/filter_main.svg" />}
        endIcon={<img src="images/arrowdown.svg" />}
        variant="outlined"
        onClick={handleFilterOpen}
        sx={{
          background: "rgba(24, 119, 242, 0.04)",
          height: "36.86px",
          color: "#1877F2",
          padding: "6px 12px",
          textTransform: "none",
          borderRadius: "6px",
          border: "1px #1877F2 solid",
          marginRight:mediaQueryVar5?"5%":"2%",
          fontSize:"16px"
        }}
      >
        {selectedFilters!='' && (
                <IconButton size="small">
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                </IconButton>
              )}
        Signals
      </Button>

      <Popover
        open={isFilterOpen}
        anchorEl={filterAnchor}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          "& .MuiPopover-paper": {
            maxHeight: "400px",
            overflowY: "auto",
            width: "300px",
          },
        }}
      >
        
        <Box sx={{ padding: "16px" }}>
        <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
      <Button
        size="small"
        onClick={clearAllFilters}
        sx={{ textTransform: "capitalize", marginBottom: "8px" }}
        startIcon={<ClearIcon />}
      >
        Clear All
      </Button>
    </Box>

          <Divider />

          {filterData.map((filter) => (
            <Box key={filter.categories} sx={{ marginTop: "12px" }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "600", fontSize:"14px" }}>
                {filter.categories}
              </Typography>
              <FormGroup sx={{fontSize:"14px"}}>
                {filter.subOptions.map((subOption) => (
                  <FormControlLabel
                    key={subOption}
                    control={
                      <Checkbox
                        checked={selectedFilters.includes(`${filter.categories} ${subOption}`)}
                        onChange={() => handleCheckboxChange(filter.categories, subOption)}
                      />
                    }
                    label={subOption}
                  />
                ))}
              </FormGroup>
            </Box>
          ))}
        </Box>
      </Popover>
  <Button
    startIcon={<img src="images/filter_main.svg" />}
    endIcon={<img src="images/arrowdown.svg" />}
    variant="outlined"
    sx={{
      background: "rgba(24, 119, 242, 0.04)",
      height: "36.86px",
      color: "#1877F2",
      padding: "6px 12px",
      textTransform: "none",
      borderRadius: "6px",
      border: "1px #1877F2 solid",
      marginBottom: "18px",
    }}
    onClick={handlePopoverOpen}
  >
    <span
      style={{
        fontFamily: "Satoshi, sans-serif",
        fontSize: "16px",
        fontWeight: "400",
        color: "#1877F2",
      }}
    >
      {selectedCategory && (
                <IconButton size="small">
                  <CheckCircleOutlineIcon style={{ color: "green" }} />
                </IconButton>
              )}
      Categories
    </span>
  </Button>

  <Popover
    open={open1}
    anchorEl={anchorEl}
    onClose={handlePopoverClose}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    sx={{
      "& .MuiPopover-paper": {
        maxHeight: "300px", // Adjust this value as needed
        overflowY: "auto",  // Add scroll if content exceeds the maxHeight
      },
    }}
  >
    <Box sx={{ display: "flex", padding: "8px" }}>
      <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
      <Button
        size="small"
        onClick={clearAllFilters}
        sx={{ textTransform: "capitalize", marginBottom: "8px" }}
        startIcon={<ClearIcon />}
      >
        Clear All
      </Button>
    </Box>
    </Box>
    <Divider />
    <Autocomplete
          id="searchable-category-select"
          options={categories}
          getOptionLabel={(option) => option}
          value={selectedCategory}
          onChange={(event, newValue) => {
            setSelectedCategory(newValue); // Store the selected category
            handlePopoverClose();          // Close the popover after selection
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search categories" variant="outlined" />
          )}
          sx={{ margin: "10px", width: "300px" }}
        />
    
  

    
  </Popover>
</div>




        <div style={{ marginTop:"1%",marginLeft:"10px",width:"95%",border: "1px #EEEEEE solid",marginBottom:"20px"}}></div>
        <div style={{ display: "flex", flexDirection: "column",marginRight:"20px",marginLeft:"0px" }}>
        
          
          {loader === false && (
            <>
              {rows.map((idx) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between"}}
                >
                  <div
                    style={{
                      flex: "0 0 auto",
                      width: mediaQueryVar === true ? "50%" : "100%",
                    }}
                  >
                    <Card sx={{ maxWidth:545, m: 2}}>
                      <CardHeader
                        avatar={
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        }
                        title={
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={
                          <Skeleton animation="wave" height={10} width="40%" />
                        }
                      />

                      <CardContent>
                        <React.Fragment>
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 6 }}
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </CardContent>
                    </Card>
                  </div>
                  {mediaQueryVar === true && (
                    <div style={{ flex: "0 0 auto", width: "50%"
                     
                     }}>
                      <Card sx={{ maxWidth: 545, m: 2 }}>
                        <CardHeader
                          avatar={
                            <Skeleton
                              animation="wave"
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          }
                          title={
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="80%"
                              style={{ marginBottom: 6 }}
                            />
                          }
                          subheader={
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="40%"
                            />
                          }
                        />

                        {/* <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" /> */}
                        <CardContent>
                          <React.Fragment>
                            <Skeleton
                              animation="wave"
                              height={10}
                              style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                              animation="wave"
                              height={10}
                              style={{ marginBottom: 6 }}
                            />
                          </React.Fragment>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}

          {loader === true && (
            <Grid container spacing={0}>
              {sortedSlugdata.map((obj, idx) => (
                <Grid item xs={12} md={6} key={idx}>
                  <div
                    key={idx}
                    style={{
                     
                      marginLeft: "5px", 
                      borderRadius : "10px",
                      backgroundColor: "white",
                      border:" 1px #ECECEC solid",
                      padding : "24px",
                      marginBottom: "20px",
                      marginRight: "10px"
              }}
                  >
                    {/* First column data */}
                    <div>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "5px",
                          
                          borderBottom: "1px #E2E2E4 solid",
                          flexDirection: "row",
                          marginBottom: "10px", // Add margin bottom to create a gap
                          fontSize:
                            mediaQueryVar === false ? "13px" : "",
}}
                      >
                        <div>
                          <img
                            className="b57ac673f06a4b0338a596817eb0a5Icon"
                            alt=""
                            src={obj.logo}
                            style={{ width: "38px", height: "38px", border:"2px solid black", borderRadius:"20px", fontFamily:"Gilroy", marginRight:"8px" }}
                          />
                        </div>
                        <div className="btcParent">
                          {obj["article_link"] && (
                            <a href={obj.article_link} style={{color:"black"}}>
                              <div
                                style={{
                                  fontSize: '18px',
                                  fontWeight:'600',
                                  fontFamily: 'Satoshi, sans-serif',
                                  paddingBottom:"5px",
                                  color: "black !important",

                                }}
                              >
                               <span style={{ color: "black !important" }}>{obj.slug}</span>
                              </div>
                            </a>
                          )}
  {!obj["article_link"] && (
  <div
    style={{
      fontSize: '18px',
      fontWeight: '600',
      fontFamily: 'Satoshi, sans-serif',
      paddingBottom: "2px",
      color: "black !important",
    }}
    className="article-link"
  >
    <span style={{ color: "black !important" }}>{obj.slug}</span>
  </div>
)}


                          {/* <div>{obj.name}</div> */}
                        </div>

                        <Button
                          className="btn"
                          variant="outlined"
                          onClick={() => handleAddToPortfolio(idx)}
                          style={{
                            backgroundColor: "white",
                            marginBottom:"10px",
                            paddingTop:"6px",
                            paddingBottom:"4px",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                            fontFamily: "Satoshi, sans-serif",
                            borderRadius:"48px",
                            border:"1px solid #1877F2",
                            textTransform:"none",
                            fontSize : "14px",
                            fontWeight: "500",
                            color: "#1877F2",
                            whiteSpace: "nowrap",
                            float: "inline-end",
                            marginTop:"5px",
                            width:"120px",
                             height:"27px",
                                    fontSize:
                                      mediaQueryVar === false ? "10px" : "11px",
   }}
                        >
                          Add to Portfolio{" "}
                        </Button></div>
                      
                      
                      
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ flex: "0 0 auto", width: "17%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500
                            }}
                          >
                            Price
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", width: "35%", // Add border to separate sections
          paddingRight: '0px',marginRight:"5px" }}>
                          <span
                            style={{
                              color: 'black', 
                              fontSize: '14px', 
                              overflow: 'hidden',
                              fontFamily: "Satoshi, sans-serif", 
                              textOverflow: 'ellipsis',
                              fontWeight:'500',
                              
                            }}
                          >
                            {obj.price_today}
                          </span>
                        </div>

                        <div style={{ width: '2px', backgroundColor: '#E2E2E4', height: '42px',
                          marginTop:"10px",
                          marginLeft:"-30px",
                          marginRight:"25px",
                          marginBottom:"-30px"
                         }}></div>
                        
                        <div style={{ flex: "0 0 auto", width: "23%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500
                            }}
                          >
                            Market Cap
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                          <span
                            style={{
                              color: 'black', 
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif", 
                              textOverflow: 'ellipsis', 
                              overflow: 'hidden'
                            }}
                          >
                            {obj.buy_percent}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ flex: "0 0 auto", width: "17%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500
                            }}
                          >
                            30 Days
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", width: "35%" }}>
                          <span
                            style={{
                               overflow: 'hidden', 
                               textOverflow: 'ellipsis',
                               fontFamily: "Satoshi, sans-serif", 
                               fontSize: '14px', 
                               color: 'black'
                            }}
                          >
                            {obj.thirtyd_change}
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", width: "23%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500
                            }}
                          >
                            Forecast 2025
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                          <span
                            style={{
                              color: 'black', 
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif", 
                              textOverflow: 'ellipsis', 
                              overflow: 'hidden'
                            }}
                          >
                            {obj.price_prediction_2025}
                          </span>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={{ flex: "0 0 auto", width: "17%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500,
                              whiteSpace : "nowrap"
                            }}
                          >
                            Top 100 Holders
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", width: "35%" }}>
                          <span
                            style={{
                               overflow: 'hidden', 
                               textOverflow: 'ellipsis',
                               fontFamily: "Satoshi, sans-serif", 
                               fontSize: '14px', 
                               color: 'black',
                               marginLeft:"50px"
                            }}
                          >
                            {obj.holdings || 'NA'}
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", width: "23%" }}>
                          <span
                            style={{
                              color: '#7F7F80', 
                              fontSize: '14px', 
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif", 
                              fontWeight: 500
                            }}
                          >
                            Grade
                          </span>
                        </div>
                        <div style={{ flex: "0 0 auto", marginLeft: "auto" }}>
                          <span
                            style={{
                              color: 'black', 
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif", 
                              textOverflow: 'ellipsis', 
                              overflow: 'hidden'
                            }}
                          >
                             {
    obj.grade === 'Outperformer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "12px" }}>Outperformer</button>
  ) : obj.grade === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px", backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "12px"}}>Outperformer</button>
) :
  obj.grade === 'Moderate Performer' ? (
    <button disabled style={{ fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "100%",fontSize: "12px"}}>Moderate Performer</button>
  )  : obj.grade === 'Underperformer' ? (
    <button disabled style={{ alignItems: 'center', fontWeight: "bold", padding:"3px ", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "100%" }}>Underperformer</button>
) : null}
                          </span>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          )}
        </div>
        {slugdata.length === 0 && loader === true && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h4>No Data Found</h4>
          </div>
        )}
        {loader && loaderbutton  && (
          <div className="loadingButton">
            <LoadingButton
              loading={addMoreLoader}
              loadingPosition="center"
              variant="outlined"
              style={{
                maxWidth: "360px",

                marginBottom: mediaQueryVar === false ? "10px" : "20px",
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                width: mediaQueryVar === false ? "80vw" : "140px",
              }}
              onClick={() => {
                loadMore();
              }}
            >
              <span style={{ color: "#4361ee" }}>Load More</span>
            </LoadingButton>
          </div>
        )}

        

        {loaderForSymbolLink === true && (
          <div>
            <CircularProgress
              style={{ position: "fixed", top: "40vh", left: "45vw" }}
            />
          </div>
        )}
      </Box>
      {/* } */}

      <Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
           onClick={() =>
            navigate(
              `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                selectedOption.label.replace(/\s+/g, '-')
              )}`
            )
          }
          
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            marginTop: '1%',
            maxWidth: '100%',
            minHeight: '60%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "100px" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '10px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
              
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '23%', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0',marginLeft:"15px" }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "350px", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "345px", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 85px",
                  marginLeft: "-10px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Buy
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "159px",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "10px",
                  padding: "15px 85px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Sell
              </Button>
             
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>

    
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>

    </Box>
      ):(



<Box
      sx={{
        flexDirection: mediaQueryVar === false ? "column" : "",

        display: "flex",
        height: "100vh",
      }}
      className="maindiv"
    >
      <Helmet>
        <title>Create Portflio</title>
</Helmet>
{isLoginModalOpen && (
        <LoginPopUp 
          isLoginModalOpen={isLoginModalOpen}
          setLoginModalOpen={setLoginModalOpen} // Pass the state setter down
          onClose={handleModalClose}
        />
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: "100vh",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "white",
        }}
        className="maindiv"
      >
        
          



        
        <div style={{ display: "flex", 
          
          flexDirection: "column",marginRight:"0px",marginLeft:"0px" }}>
          {loader === false && (
            <>
              {rows.map((idx) => (
                <div
                  style={{ display: "flex", justifyContent: "space-between",flexGrow:1}}
                >
                  <div
                    style={{
                      flex: "0 0 auto",
                      width: mediaQueryVar === true ? "50%" : "100%",
                    }}
                  >
                    <Card sx={{ maxWidth:540, m: 2}}>
                      <CardHeader
                        avatar={
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        }
                        title={
                          <Skeleton
                            animation="wave"
                            height={10}
                            width="80%"
                            style={{ marginBottom: 6 }}
                          />
                        }
                        subheader={
                          <Skeleton animation="wave" height={10} width="40%" />
                        }
                      />

                      <CardContent>
                        <React.Fragment>
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 6 }}
                          />
                          <Skeleton
                            animation="wave"
                            height={10}
                            style={{ marginBottom: 6 }}
                          />
                        </React.Fragment>
                      </CardContent>
                    </Card>
                  </div>
                  {mediaQueryVar === true && (
                    <div style={{ flex: "0 0 auto", width: "50%"
                     
                     }}>
                      <Card sx={{ maxWidth: 545, m: 2 }}>
                        <CardHeader
                          avatar={
                            <Skeleton
                              animation="wave"
                              variant="circular"
                              width={40}
                              height={40}
                            />
                          }
                          title={
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="80%"
                              style={{ marginBottom: 6 }}
                            />
                          }
                          subheader={
                            <Skeleton
                              animation="wave"
                              height={10}
                              width="40%"
                            />
                          }
                        />

                        {/* <Skeleton sx={{ height: 100 }} animation="wave" variant="rectangular" /> */}
                        <CardContent>
                          <React.Fragment>
                            <Skeleton
                              animation="wave"
                              height={10}
                              style={{ marginBottom: 6 }}
                            />
                            <Skeleton
                              animation="wave"
                              height={10}
                              style={{ marginBottom: 6 }}
                            />
                          </React.Fragment>
                        </CardContent>
                      </Card>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
          <div style={{ 
  fontFamily: "Satoshi, sans-serif", 
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: 'center', 
  margin: '10px 19px 10px 6px', 
  marginTop: '27px' 
}}>
  {/* Technicals Button on the Left */}
  <Button
    startIcon={<img src="images/filter_main.svg" />}
    endIcon={<img src="images/arrowdown.svg" />}
    variant="outlined"
    onClick={handleFilterOpen}
    sx={{
      background: "rgba(24, 119, 242, 0.04)",
      height: "36.86px",
      color: "#1877F2",
      padding: "6px 12px",
      textTransform: "none",
      borderRadius: "6px",
      border: "1px #1877F2 solid",
      fontSize: "16px",
    }}
  >
    {selectedFilters.length!=0 && (
      <IconButton size="small">
        <CheckCircleOutlineIcon style={{ color: "green" }} />
      </IconButton>
    )}
    Signals
  </Button>

  {/* Categories Button on the Right */}
  <Button
    startIcon={<img src="images/filter_main.svg" />}
    endIcon={<img src="images/arrowdown.svg" />}
    variant="outlined"
    onClick={handlePopoverOpen}
    sx={{
      background: "rgba(24, 119, 242, 0.04)",
      height: "36.86px",
      color: "#1877F2",
      padding: "6px 12px",
      textTransform: "none",
      borderRadius: "6px",
      border: "1px #1877F2 solid",
      fontSize: "16px",
    }}
  >
    <span style={{
      fontFamily: "Satoshi, sans-serif",
      fontSize: "16px",
      fontWeight: "400",
      color: "#1877F2",
    }}>
      {selectedCategory && (
        <IconButton size="small">
          <CheckCircleOutlineIcon style={{ color: "green" }} />
        </IconButton>
      )}
      Categories
    </span>
  </Button>

  {/* Popover for Technicals */}
  <Popover
    open={isFilterOpen}
    anchorEl={filterAnchor}
    onClose={handleFilterClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    sx={{
      "& .MuiPopover-paper": {
        maxHeight: "400px",
        overflowY: "auto",
        width: "300px",
      },
    }}
  >
    <Box sx={{ padding: "16px" }}>
      <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
        <Button
          size="small"
          onClick={clearAllFilters}
          sx={{ textTransform: "capitalize", marginBottom: "8px" }}
          startIcon={<ClearIcon />}
        >
          Clear All
        </Button>
      </Box>
      <Divider />
      {filterData.map((filter) => (
        <Box key={filter.categories} sx={{ marginTop: "12px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "600", fontSize: "14px" }}>
            {filter.categories}
          </Typography>
          <FormGroup sx={{ fontSize: "14px" }}>
            {filter.subOptions.map((subOption) => (
              <FormControlLabel
                key={subOption}
                control={
                  <Checkbox
                    checked={selectedFilters.includes(`${filter.categories} ${subOption}`)}
                    onChange={() => handleCheckboxChange(filter.categories, subOption)}
                  />
                }
                label={subOption}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
    </Box>
  </Popover>

  {/* Popover for Categories */}
  <Popover
    open={open1}
    anchorEl={anchorEl}
    onClose={handlePopoverClose}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    transformOrigin={{ vertical: "top", horizontal: "center" }}
    sx={{
      "& .MuiPopover-paper": {
        maxHeight: "300px",
        overflowY: "auto",
      },
    }}
  >
    <Box sx={{ padding: "8px" }}>
      <Box sx={{ position: 'sticky', top: 0, background: 'white', zIndex: 1 }}>
        <Button
          size="small"
          onClick={clearAllFilters}
          sx={{ textTransform: "capitalize", marginBottom: "8px" }}
          startIcon={<ClearIcon />}
        >
          Clear All
        </Button>
      </Box>
    </Box>
    <Divider />
    <Autocomplete
      id="searchable-category-select"
      options={categories}
      getOptionLabel={(option) => option}
      value={selectedCategory}
      onChange={(event, newValue) => {
        setSelectedCategory(newValue);
        handlePopoverClose();
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search categories" variant="outlined" />
      )}
      sx={{ margin: "10px", width: "300px" }}
    />
  </Popover>
</div>

          {loader === true && (
            <div
            style={{
              maxHeight: "100vh", 
              paddingBottom:"30%",
  overflowX: "hidden",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  WebkitOverflowScrolling: "touch",
  // Hides scrollbar for Firefox
  scrollbarWidth: "none",
            }}
          >
           

            <Grid container spacing={0} sx={{paddingBottom:"20%", marginBottom:"20%"}}>
              {sortedSlugdata.map((obj, idx) => (
                <Grid item xs={12} md={6} key={idx}>
                  <div
                    key={idx}
                    style={{
                     width:"95%",
                      marginLeft: "4px", 
                      borderRadius : "10px",
                      backgroundColor: "white",
                      border:" 1px #E2E2E4 solid",
                      padding : "15px",
                      marginBottom: "25px",
                      marginRight: "-15px"
              }}
                  >
                    {/* First column data */}
                    <div>
                      <div
                        style={{
                          display: "flex",
                          paddingBottom: "5px",
                          
                          borderBottom: "1px #E2E2E4 solid",
                          flexDirection: "row",
                          marginBottom: "10px", // Add margin bottom to create a gap
                          fontSize:
                            mediaQueryVar === false ? "13px" : "",
}}
                      >
                        <div>
                          <img
                            className="b57ac673f06a4b0338a596817eb0a5Icon"
                            alt=""
                            src={obj.logo}
                            style={{ width: "38px", height: "38px", border:"2px solid black", borderRadius:"20px", fontFamily:"Gilroy", marginRight:"8px" }}
                          />
                        </div>
                        <div className="btcParent">
                          {obj["article_link"] && (
                            <a href={obj.article_link}>
                              <div
                                style={{
                                  fontSize: '18px',
                                  fontWeight:'600',
                                  fontFamily: 'Satoshi, sans-serif',
                                  paddingBottom:"5px"

                                }}
                              >
                                {obj.slug}
                              </div>
                            </a>
                          )}
                          {!obj["article_link"] && (
                            <div
                              style={{
                                fontSize: '18px',
                                fontWeight:'600',
                                fontFamily: 'Satoshi,sans-serif',
                                paddingBottom:"2px"
                              }}
                              className="article-link"
                            >
                              {obj.slug}
                            </div>
                          )}

                          {/* <div>{obj.name}</div> */}
                        </div>

                        <Button
                          className="btn"
                          variant="outlined"
                          onClick={() => handleAddToPortfolio(idx)}
                          style={{
                            backgroundColor: "white",
                            marginBottom:"10px",
                            paddingTop:"6px",
                            paddingBottom:"4px",
                            paddingLeft:"10px",
                            paddingRight:"10px",
                            fontFamily: "Satoshi, sans-serif",
                            borderRadius:"48px",
                            border:"1px solid #1877F2",
                            textTransform:"none",
                            fontSize : "14px",
                            fontWeight: "500",
                            color: "#1877F2",
                            whiteSpace: "nowrap",
                            float: "inline-end",
                            marginTop:"5px",
                            width:"120px",
                             height:"27px",
                                    
   }}
                        >
                          Add to Portfolio{" "}
                        </Button>





                      </div>
                      
                        <div style={{ width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center" }}>
                          <span
                            style={{
                              color: '#7F7F80',
                              fontSize: '14px',
                              letterSpacing: '0.46px',
                              fontFamily: "Satoshi, sans-serif",
                              fontWeight: 500,
                              marginBottom: "5px"
                            }}
                          >
                            Price
                          </span>
                          <span
                             style={{
                              color: 'black',
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif",
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              marginBottom: "5px"
,
                              whiteSpace: 'nowrap', // Ensures the text does not wrap
                              maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                            }}
                          >
                            {obj.price_today}
                          </span>
                        
                        </div>
                        
                        <div style={{ width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"}}>
                          <span
                           style={{
                            color: '#7F7F80',
                            fontSize: '14px',
                            letterSpacing: '0.46px',
                            fontFamily: "Satoshi, sans-serif",
                            fontWeight: 500,
                            marginBottom: "5px"

                          }}
                          >
                            30 Days
                          </span>
                          <span
                             style={{
                              color: 'black',
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif",
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              marginBottom: "5px",

                              whiteSpace: 'nowrap', // Ensures the text does not wrap
                              maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                            }}
                          >
                            {obj.thirtyd_change}
                          </span>
                        </div>


                        <div style={{ width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"}}>
                          <span
                           style={{
                            color: '#7F7F80',
                            fontSize: '14px',
                            letterSpacing: '0.46px',
                            fontFamily: "Satoshi, sans-serif",
                            fontWeight: 500,
                            marginBottom: "5px"

                          }}
                          >
                          Market Cap
                          </span>
                          <span
                             style={{
                              color: 'black',
                              fontSize: '14px',
                              fontFamily: "Satoshi, sans-serif",
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              marginBottom: "5px",

                              whiteSpace: 'nowrap', // Ensures the text does not wrap
                              maxWidth: '60%' // Adjust this percentage as needed to fit within the container
                            }}
                          >
                            {obj.buy_percent}
                          </span>
                        </div>
                        
                       
                      

                     
                        
                         
                        
                        <div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center" // This ensures vertical alignment is centered
  }}
>
  <span
    style={{
      color: '#7F7F80',
      fontSize: '14px',
      letterSpacing: '0.46px',
      fontFamily: "Satoshi, sans-serif",
      fontWeight: 500
    }}
  >
    Forecast 2025
  </span>
  <span
    style={{
      color: 'black',
      fontSize: '14px',
      fontFamily: "Satoshi, sans-serif",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap', // Ensures the text does not wrap
      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
    }}
  >
    {obj.price_prediction_2025}
  </span>
</div>

<div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginTop:"5px",
    alignItems: "center" // This ensures vertical alignment is centered
  }}
>
  <span
    style={{
      color: '#7F7F80',
      fontSize: '14px',
      letterSpacing: '0.46px',
      fontFamily: "Satoshi, sans-serif",
      fontWeight: 500
    }}
  >
   Top 100 Holders
  </span>
  <span
    style={{
      color: 'black',
      fontSize: '14px',
      fontFamily: "Satoshi, sans-serif",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap', // Ensures the text does not wrap
      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
    }}
  >
    {obj.holdings || 'NA'}
  </span>
</div>
<div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center" // This ensures vertical alignment is centered
  }}
>
  <span
    style={{
      color: '#7F7F80',
      fontSize: '14px',
      letterSpacing: '0.46px',
      fontFamily: "Satoshi, sans-serif",
      fontWeight: 500
    }}
  >
    Grade
  </span>
  <span
    style={{
      
      color: 'black',
      fontSize: '14px',
      fontFamily: "Satoshi, sans-serif",
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap', // Ensures the text does not wrap
      maxWidth: '60%' // Adjust this percentage as needed to fit within the container
    }}
  >
    {obj.grade === 'Outperformer' ? (
    <button disabled style={{fontWeight: "bold", padding: "3px" , borderRadius: "5px",backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize: "14px" }}>Outperformer</button>
) : obj.grade === 'Emerging Outperformer' ? (
  <button disabled style={{ fontWeight: "bold", padding: "3px" , borderRadius: "5px",backgroundColor: '#DFFFF0', color: '#199F5F', border: '2px solid #199F5F', width: "100%",fontSize:  "14px"}}>Outperformer</button>
) : 


obj.grade === 'Moderate Performer' ? (
    <button disabled style={{ marginRight:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#F9F4E7', color: '#D4AF37', border: '2px solid #D4AF37', width: "100%" }}>Moderate Performer</button>
) : obj.grade === 'Underperformer' ? (
    <button disabled style={{ marginRight:"2px",alignItems: 'center', fontWeight: "bold", padding: "3px", borderRadius: "5px", backgroundColor: '#FFE3E3', color: '#FF4141', border: '2px solid #FF4141', width: "100%" }}>Underperformer</button>
) : null}

  </span>
</div>
    
                    </div>
                  </div>
      
                </Grid>
              ))}
              {loader && loaderbutton && (
          <div className="loadingButton">
            <LoadingButton
              loading={addMoreLoader}
              loadingPosition="center"
              variant="outlined"
              style={{
                maxWidth: "60%",
                marginTop:"0px",
                marginBottom:"70px",
                backgroundColor: "rgba(67, 97, 238, 0.15)",
                borderRadius: "6px",
                border: "none",
                textTransform: "none",
                borderStyle: "solid",
                borderColor: "rgba(67, 97, 238, 0.15)",
                borderWidth: "1px",
                color: "#4361ee",
                width: mediaQueryVar === false ? "80vw" : "140px",
                marginLeft:"10px"
              }}
              onClick={() => {
                loadMore();
              }}
            >
              <span style={{ color: "#4361ee" }}>Load More</span>
            </LoadingButton>
          </div>
        )}
            </Grid>
            </div>
          )}
          
        </div>
        {slugdata.length === 0 && loader === true && (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h4>No Data Found</h4>
          </div>
        )}
        

        

        {loaderForSymbolLink === true && (
          <div>
            <CircularProgress
              style={{ position: "fixed", top: "40vh", left: "45vw" }}
            />
          </div>
        )}
      </Box>
      {/* } */}

      <Modal
  sx={{ overflow: "hidden" }}
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <div className="CardOpener" style={{ overflow: "hidden" }}>
    {isFormSubmitted ? (
      <Card className="card__container">
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleOutlineIcon sx={{ fontSize: 50, mr: 1 }} />
          </div>
          <Typography
            severity="success"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Portfolio added successfully!
          </Typography>
        </>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            onClick={() => {
              setIsFormSubmitted(false);
              setOpen(false);
              setUnit("");
              setSelectedOption("");
              setPrice("");
              setTotalUnits(0);
              handleDateChange("");
              setUsablewallet("");
            }}
            color="primary"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "150px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
          >
            Add More Cryptos
          </Button>
          <Button
            onClick={() =>
              navigate(
                `/my-portfolio/${portfolioTypeId}/${encodeURIComponent(
                  selectedOption.label
                )}`
              )
            }
            variant="outlined"
            style={{
              backgroundColor: "rgba(67, 97, 238, 0.15)",
              borderRadius: "6px",
              border: "none",
              textTransform: "none",
              borderStyle: "solid",
              borderColor: "rgba(67, 97, 238, 0.15)",
              borderWidth: "1px",
              color: "#4361ee",
              whiteSpace: "nowrap",
              float: "inline-end",
              width: mediaQueryVar === true ? "100px" : "100px",
              height: mediaQueryVar === false ? "30px" : "",
              fontSize: mediaQueryVar === false ? "10px" : "11px",
            }}
            color="primary"
          >
            View Portfolio
          </Button>
        </div>
      </Card>
    ) : (
      <Dialog
        PaperProps={{
          sx: {
            position: mediaQueryVar ? 'static' : 'fixed',
            bottom: mediaQueryVar ? '' : 0,
            left: mediaQueryVar ? '' : 0,
            right: mediaQueryVar ? '' : 0,
            margin: '0',
            maxWidth: '100%',
            minHeight: '50%', // Adjust max height if needed
            borderRadius: mediaQueryVar ? "24px" : "24px 24px 0 0",
            padding: "10px",
            alignItems: "center",
            overflow: "scroll", 
            '&::-webkit-scrollbar': {
              display: 'none',
              },
               '-ms-overflow-style': 'none',  
               'scrollbar-width': 'none'// Ensure no scrollbar
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: '20px', width: mediaQueryVar===false?'100%': '400px', margin: '0' ,marginTop:"-5px",}}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', borderBottom:"1px #E3E3E3 solid" }}>
            <Typography style={{ fontWeight: '500', fontSize: '20.767px', paddingLeft: "25%" }} gutterBottom>
              Add To Portfolio
            </Typography>
            

            <div className="primaryButton">
              <img
                className="frameIcon"
                alt=""
                onClick={handleClose}
                src={window.constants.asset_path + '/images/frame17.svg'}
                style={{ background: "#F3F3F3", width: "25px", height: "25px", 
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' ,marginLeft: mediaQueryVar === false ? '5px' : ''}}>
            <Typography style={{ fontSize: "13.845px", fontWeight: '500', marginBottom: '1px', color: 'black',marginTop:"-8px" }}>
              Select Portfolio
            </Typography>
            <div style={{ marginTop: '5px' }}>
              <CreatableSelect
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '1px solid #1877F2',
                    boxShadow: 'none',
                    minHeight: '40px',
                    '&:hover': {
                      borderRadius: '12px',
                      border: '2px solid #1877F2',
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '8px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#1877F2',
                    border: "1px solid #1877F2",
                    padding: "5px",
                    margin: "8px",
                    borderRadius: "3.786px",
                    '&:hover': {
                      color: '#1877F2',
                    },
                  }),
                  indicatorSeparator: (provided) => ({
                    display: 'none',
                  }),
                }}
                placeholder=""
                onChange={(options) => handleChange(options, slugpopupdata.slugname)}
                onCreateOption={handleCreate}
                options={[
                  { value: '', label: 'Create new portfolio', _isNew_: true }, // Dynamic "create new option" item
                  ...options // Existing options
                ]}
                value={selectedOption}
              />
            </div>
            {showAlert && (
        <Alert severity="error" sx={{ marginBottom: '10px' }}>
          {errorMessage}
        </Alert>
        
      )}
          </div>
          <Typography style={{ fontSize: '13.845px', fontWeight: '500', color: '#000', marginTop: '10px', marginBottom: '5px' }}>
          Usable Wallet Value: {Usablewallet}
          </Typography>
          <div style={{ padding: "8px", background: '#F3F3F3', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
            <div style={{ display: 'flex', alignItems: 'center', flex: 0, minWidth: 0 }}>
              <img
                style={{ marginRight: '10px', border: "2px solid #000", borderRadius: "19px", height: "27.69px", width: "27.69px" }}
                alt=""
                src={slugpopupdata.logo}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "14px", color: "#7F7F80" }}>
                  {slugpopupdata.slugname}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: 'black', margin: '0' }}>
                  {slugpopupdata.slug}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px', flex: 1 }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Price</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {slugpopupdata.pricetoday}
              </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '20px' }}>
              <Typography sx={{ fontWeight: '500', marginBottom: '4px', fontSize: "13px", color: "#7F7F80" }}> Units</Typography>
              <Typography sx={{ fontSize: '12.114px', fontWeight: '500', color: 'black', margin: '0' }}>
                {totalUnits}
              </Typography>
            </div>
          </div>
          <div className="card__container__row3">
            <div style={{marginLeft:"-5px"}}>
              <p style={{ fontSize: "14px", fontWeight: "500", }}>Enter number of Units</p>
              <input
                style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px",margin:0 }}
                min="1"
                max="10"
                type="number"
                placeholder=""
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              ></input>
            </div>
            <div>
            ${formatToNonZero(
  unit * parseFloat((slugpopupdata.pricetoday || '').replace(/^\$/, ''))
)}

    </div>
            {user_role === "superuser" && (
              <>
                <div className="card__container__row3__r2">
                  <div style={{marginLeft:"-5px"}}>
                  <p  style={{ fontSize: "14px", fontWeight: "500" }}>
                    Buy/Sell Price($)
                  </p>
                  <input
                  style={{ backgroundColor:"white",color:"black",padding: "20px", border: "1px solid #1877F2", borderRadius: "10.384px", width: "100%", height: "45px" }}
                    className="card__container__row3__r2__input"
                    min="1"
                    max="10"
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></input></div>
                </div>
                <div style={{marginBottom:"10px",marginLeft:"-5px"}}>
                      <p style={{fontSize:"14px", fontWeight:"500"}}>Date</p>
                      <TextField
                        type="date"
                        value={selectedDate}
                        onChange={(e) => handleDateChange(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10.384px',
                          border:"1px solid #1877F2",
                          boxShadow:"none",
                          width:"190%",
                          height:"45px",
                          
                        },
                        }}
                        />
                    </div>
              </>
            )}
            <div className="card__container__row3__r4" style={{ marginTop: "10px" }}>
              <Button
                className={
                  buySell === "buy"
                    ? "buttonToggleButActive"
                    : "buttonToggleBuy"
                }
                value="Buy"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  padding: "15px 8px",
                  marginLeft: "5px",
                  backgroundColor: buySell === "buy" ? "" : "#1877F2",
                  color: buySell === "buy" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("buy")}
              >
                Buy
              </Button>
              <Button
                className={
                  buySell === "sell"
                    ? "buttonToggleSellActive"
                    : "buttonToggleSell"
                }
                value="sell"
                style={{
                  lineHeight: "20px",
                  width: "45%",
                  borderRadius: "27px",
                  border: "1px solid #1877F2",
                  marginLeft: "5px",
                  marginRight:"10px",
                  padding: "15px 8px",
                  backgroundColor: buySell === "sell" ? "" : "#1877F2",
                  color: buySell === "sell" ? "" : "white",
                  textTransform: "none",
                  fontSize: "18px",
                  fontWeight: "700"
                }}
                onClick={() => handleClick("sell")}
              >
                Sell
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    )}
  </div>
</Modal>

        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>
    Create New Portfolio
    <IconButton aria-label="close"onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }}  sx={{ position: 'absolute', right: 13, top: 8 }}>
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <div>
      <TextField
        autoFocus
        label="Enter Name"
        value={newOptionValue}
        onChange={handleChange2}
        fullWidth
        margin="dense"
      />
      {showAlert && (
        <Alert severity="error" sx={{ marginTop: '8px' }}>
          Hyphens are not allowed in the name.
        </Alert>
      )}
    </div>
  </DialogContent>
  <DialogActions sx={{marginRight:'14px'}}>
  <Button onClick={() => { setIsDialogOpen(false); setNewOptionValue(''); }} sx={{fontWeight:'bold'}}  variant="outlined" color="primary">
      Cancel
    </Button>
    <Button onClick={handleDialogSubmit} variant="contained" color="primary" sx={{fontWeight:'bold'}}disabled={!newOptionValue.trim()}>
      Submit
    </Button>
  
  </DialogActions>
</Dialog>

        <Snackbar
          open={snackbaropen}
          autoHideDuration={5000}
          onClose={closesnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={severity}>{msgforUpdatePortfolio}</Alert>
        </Snackbar>

    </Box>
     )}
     </>
     
     
       );
      
     }
     